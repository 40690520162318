// This file was autogenerated. Please do not change.
/* MFA verify factor payload */
export interface IIssueFactorPayload {
    action: ('PasswordForgot' | 'PasswordChange' | 'MFADisable' | 'Transfer' | 'EmailVerification' | 'Withdraw' | 'ShareAccountAccess' | 'ChangeAccountAccessPermission' | 'GroupDelete' | 'DefaultActionConfirmation' | 'SaveBankRequisites');
    emailTemplateData?: Record<string, string>;
    mfaType: ('Email' | 'EmailActivation' | 'TOTP');
    sessionId: string;
}

export default class IssueFactorPayload {
    readonly _action: ('PasswordForgot' | 'PasswordChange' | 'MFADisable' | 'Transfer' | 'EmailVerification' | 'Withdraw' | 'ShareAccountAccess' | 'ChangeAccountAccessPermission' | 'GroupDelete' | 'DefaultActionConfirmation' | 'SaveBankRequisites');

    /** Description: Action */
    get action(): ('PasswordForgot' | 'PasswordChange' | 'MFADisable' | 'Transfer' | 'EmailVerification' | 'Withdraw' | 'ShareAccountAccess' | 'ChangeAccountAccessPermission' | 'GroupDelete' | 'DefaultActionConfirmation' | 'SaveBankRequisites') {
        return this._action;
    }

    readonly _emailTemplateData: Record<string, string> | undefined;

    /** Description: additional data to add to email */
    get emailTemplateData(): Record<string, string> | undefined {
        return this._emailTemplateData;
    }

    readonly _mfaType: ('Email' | 'EmailActivation' | 'TOTP');

    /** Description: MFA Type */
    get mfaType(): ('Email' | 'EmailActivation' | 'TOTP') {
        return this._mfaType;
    }

    readonly _sessionId: string;

    /** Description: Current user's session Id */
    get sessionId(): string {
        return this._sessionId;
    }

    constructor(props: IIssueFactorPayload) {
        this._action = props.action;
        if (props.emailTemplateData) {
            this._emailTemplateData = props.emailTemplateData;
        }
        this._mfaType = props.mfaType;
        this._sessionId = props.sessionId.trim();
    }

    serialize(): IIssueFactorPayload {
        const data: IIssueFactorPayload = {
            action: this._action,
            mfaType: this._mfaType,
            sessionId: this._sessionId,
        };
        if (typeof this._emailTemplateData !== 'undefined') {
            data.emailTemplateData = this._emailTemplateData;
        }
        return data;
    }

    toJSON(): IIssueFactorPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            sessionId: typeof this._sessionId === 'string' && !!this._sessionId.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
