<template>
    <div class="module">
        <BlockHeader class="draggable" title="P&L History" />
        <div :class="s.h100">
            <div :class="s.legend">
                <div
                    :class="[s.dot, { [s.disabled]: isItemFiltered(CHART_TYPES['SUMMARY']) }]"
                    @click="toggleFilter(CHART_TYPES['SUMMARY'])"
                >
                    <div :class="s.dotCircle" style="background-color: #656FDB"></div>
                    Cumulative P&L
                </div>

                <div
                    :class="[s.dot, { [s.disabled]: isItemFiltered(CHART_TYPES['DAILY']) }]"
                    @click="toggleFilter(CHART_TYPES['DAILY'])"
                >
                    <div :class="s.dotCircle" style="background-color: #C92C3E"></div>
                    <div :class="s.dotCircle" style="background-color: #15B075"></div>
                    Daily P&L
                </div>
            </div>
            <Preloader
                v-if="isLoading"
                :class="s.mAuto"
            />
            <bar-chart
                v-else
                :chart-data="chartData"
                :styles="styles"
                :tooltip-title-callback="tooltipTitleCallback"
                :tooltip-label-callback="tooltipLabelCallback"
                :tooltip-label-color-callback="tooltipLabelColorCallback"
                :tooltip-text-color-callback="tooltipTextColorCallback"
                :y-axe-label-callback="yAxeLabelCallback"
                :ref="barChartRefField"
                :min-x="chartData.labels[0]"
                :max-x="chartData.labels[chartData.labels.length - 1]"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { timeConstants } from 'Config/timeConstants';
import BlockHeader from 'UI/BlockHeader.vue';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';

import PNLChartData from './PNLChart.Data.vue';

const CHART_TYPES = {
    DAILY: 'Daily',
    SUMMARY: 'Summary',
};

export default {
    name: 'PNLChart',
    components: {
        BlockHeader,
    },
    mixins: [PNLChartData],
    props: {
        range: {
            type: Object,
            default: () => ({
                start: new Date(Date.now() - timeConstants.MONTH),
                end: new Date(),
            }),
        },
        module: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            CHART_TYPES,
            styles: {
                height: '530px',
                width: '100%',
                padding: '20px 0 0',
            },

            filteredCharts: [],

            barChartRefField: 'periodPnlHistory',
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        quotationAssetPrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },

        portfolioType() {
            return this.$store.state.Portfolio.portfolioType;
        },

        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        labels() {
            const result = this.pnlStatistics.map(({ timestamp }) => timestamp);

            // result.unshift(result[0] - (timeConstants.HOUR * 12));
            result.push(result[result.length - 1] + (timeConstants.HOUR * 12));

            return result;
        },
        dailyData() {
            return [...this.pnlStatistics.map(({ quantity }) => quantity)];
        },
        summaryData() {
            const result = [];

            this.pnlStatistics.forEach(({ quantity }, index) => {
                if (index > 0) {
                    result.push(quantity || quantity === 0 ? +quantity + +result[index - 1] : 0);
                } else {
                    result.push(quantity || quantity === 0 ? +quantity : 0);
                }
            });

            return result;
        },
        chartDatasets() {
            return [
                {
                    label: 'PNL history',
                    backgroundColor: (context) => (context.dataset.data[context.dataIndex] > 0 ? this.gradient('green') : this.gradient('red')),
                    barPercentage: 1,
                    config: {
                        type: CHART_TYPES.DAILY,
                    },
                    data: this.dailyData,
                },
                {
                    label: 'Summary pnl',
                    type: 'line',
                    borderColor: '#656FDB',
                    backgroundColor: 'rgba(35, 36, 77, 0)',
                    pointRadius: 0,
                    pointHitRadius: 0,
                    pointBorderColor: 'rgba(0, 0, 0, 0)',
                    pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                    config: {
                        type: CHART_TYPES.SUMMARY,
                    },
                    data: this.summaryData,
                },
            ];
        },
        chartData() {
            return {
                labels: this.labels,
                datasets: this.chartDatasets,
            };
        },
    },
    methods: {
        gradient(pallete) {
            const palletes = {
                green: {
                    first: '#15B075',
                    first_step: 0,
                    second: 'rgba(21, 176, 117, 0.3)',
                    second_step: 1,
                },
                red: {
                    first: 'rgba(201, 44, 62, 0.4)',
                    first_step: 0,
                    second: 'rgba(201, 44, 62, 0.7)',
                    second_step: 1,
                },
            };

            const canvas = document.createElement('canvas');
            const gradient = canvas.getContext('2d').createLinearGradient(0, 565, 0, 0);

            gradient.addColorStop(palletes[pallete].first_step, palletes[pallete].first);
            gradient.addColorStop(palletes[pallete].second_step, palletes[pallete].second);

            return gradient;
        },
        tooltipTitleCallback(ctx) {
            const dateString = ctx[0].label;
            const start = this.$store.getters.getTimeDateString({ timestamp: Date.parse(dateString.replace('a.m.', 'AM').replace('p.m.', 'PM')) });
            const end = this.$store.getters.getTimeDateString({
                timestamp: new Date(Date.parse(dateString.replace('a.m.', 'AM').replace('p.m.', 'PM'))).getTime() + timeConstants.DAY,
            });

            return `${start} - ${end}`;
        },
        tooltipLabelCallback(ctx) {
            const prefix = ctx.chart.data.datasets[ctx.datasetIndex].config.type;
            const value = parseFloat(ctx.dataset.data[ctx.dataIndex]) ? parseFloat(ctx.dataset.data[ctx.dataIndex]) : 0;

            return `${prefix}: ${+value >= 0 ? '+' : '-'} ${this.quotationAssetCharacter}${Math.abs(value)
                .toFixed(this.quotationAssetPrecision)
                .getSeparatedDigits()}`;
        },
        tooltipLabelColorCallback(ctx) {
            const currentColor = ctx.dataset.data[ctx.dataIndex] >= 0 ? '#15B075' : '#C92C3E';

            return ctx.dataset.config.type === CHART_TYPES.SUMMARY
                ? {
                    borderColor: '#656FDB',
                    backgroundColor: '#656FDB',
                }
                : {
                    borderColor: currentColor,
                    backgroundColor: currentColor,
                };
        },
        tooltipTextColorCallback(ctx) {
            return ctx.dataset.data[ctx.dataIndex] >= 0 ? '#15B075' : '#C92C3E';
        },
        yAxeLabelCallback(value) {
            // eslint-disable-next-line no-nested-ternary
            return `${+value < 0 ? '-' : value > 0 ? '+' : ''} ${this.quotationAssetCharacter}${Math.abs(+value)
                .toFixed(this.quotationAssetPrecision)
                .getSeparatedDigits()}`;
        },

        getDatasetIndexByType(type) {
            if (type === CHART_TYPES.DAILY) {
                return 0;
            } if (type === CHART_TYPES.SUMMARY) {
                return 1;
            }
            return -1;
        },

        toggleFilter(type) {
            const currentTypeIndex = this.filteredCharts.findIndex((filteredType) => filteredType === type);

            if (~currentTypeIndex) {
                this.filteredCharts.splice(currentTypeIndex, 1);

                this.$refs[this.barChartRefField].showDataset(this.getDatasetIndexByType(type));
            } else {
                this.filteredCharts.push(type);

                this.$refs[this.barChartRefField].hideDataset(this.getDatasetIndexByType(type));
            }
        },
        isItemFiltered(type) {
            return !!~this.filteredCharts.indexOf(type);
        },
    },
    mounted() {
        if (this.$store.getters['Accounts/activeAccount']) {
            this.getData(this.range, this.module);
        }
    },
    watch: {
        range() {
            if (!(this.activeAccount && this.range)) {
                return;
            }
            // mixin
            this.getData(this.range, this.module);
        },
        activeAccount() {
            if (!(this.activeAccount && this.range)) {
                return;
            }
            // mixin
            this.getData(this.range, this.module);
        },
        portfolioType() {
            if (!this.module && (this.activeAccount && this.range)) {
                this.getData(this.range, this.module);
            }
        },
        isThemeDark() {
            setTimeout(() => {
                const currentTypeIndexSummary = this.filteredCharts.findIndex((filteredType) => filteredType === CHART_TYPES.SUMMARY);
                const currentTypeIndexDaily = this.filteredCharts.findIndex((filteredType) => filteredType === CHART_TYPES.DAILY);

                if (~currentTypeIndexSummary) {
                    this.$refs[this.barChartRefField].hideDataset(this.getDatasetIndexByType(CHART_TYPES.SUMMARY));
                } else {
                    this.$refs[this.barChartRefField].showDataset(this.getDatasetIndexByType(CHART_TYPES.SUMMARY));
                }

                if (~currentTypeIndexDaily) {
                    this.$refs[this.barChartRefField].hideDataset(this.getDatasetIndexByType(CHART_TYPES.DAILY));
                } else {
                    this.$refs[this.barChartRefField].showDataset(this.getDatasetIndexByType(CHART_TYPES.DAILY));
                }
            }, 100);
        },
    },
};
</script>

<style lang="postcss" module="s">
.legend {
    display: flex;
    margin-left: var(--m-xxxl);
}
.dot {
    margin: var(--m-s);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: var(--m-s);
}
.disabled {
    opacity: 0.5;
}
.dotCircle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
}
.h100 {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}
.mAuto {
    margin: auto;
}
</style>
