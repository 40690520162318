<template>
    <div class="module doughnut-chart-module">
        <BlockHeader class="draggable" title="Exchange Trades">
            <ChartToggler v-model="showChart" />
        </BlockHeader>
        <div class="module-content">
            <div class="chart-container" v-show="showChart">
                <doughnut-chart
                    :chart-data="data"
                    :tooltip-label-callback="tooltipLabelCallback"
                    v-if="isDataAvailable"
                    :layout-paddings="layoutPaddings"
                    :style="styles"
                />
                <EmptyBarChart v-else/>
            </div>

            <div
                v-show="!showChart"
                :class="s.relative"
            >
                <DoughnutChartDatasetList
                    v-if="tradesPerExchangeArray.length > 0"
                    :items="tradesPerExchangeArray"
                >
                    <template #item-title="slotProps">
                        <div :class="s.row">
                            <PlacementIcon :placement="slotProps.itemData.exchange" />
                            <span>
                            {{ slotProps.itemData.exchange }}
                        </span>
                        </div>
                    </template>
                    <template #item-value="slotProps">
                        <span>{{ quotationAssetCharacter }}</span>{{ slotProps.itemData.quantity }}
                    </template>
                    <template #item-percentage="slotProps"> {{ slotProps.itemData.part }}%</template>
                </DoughnutChartDatasetList>
                <NoData v-else />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { timeConstants } from 'Config/timeConstants';
import { placementsStyle } from 'Config/placementsManifest';
import { showLabelThreshold } from 'Config/portfolio';
import BlockHeader from 'UI/BlockHeader.vue';
import ChartToggler from 'UI/ChartToggler.vue';
import { PORTFOLIO_TYPES } from 'Models/portfolio';
import NoData from 'UI/NoData.vue';

import DoughnutChartDatasetList from './components/DoughnutChartDatasetList.vue';
import TradesPerExchangeData from './TradesPerExchangeDonut.Data.vue';

export default {
    name: 'TradesPerExchange',
    components: {
        NoData,
        DoughnutChartDatasetList,
        BlockHeader,
        ChartToggler,
    },
    mixins: [TradesPerExchangeData],
    props: {
        range: {
            type: Object,
            default: () => ({
                start: new Date(Date.now() - timeConstants.MONTH),
                end: new Date(),
            }),
        },
        module: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            styles: {
                width: '300px',
            },
            layoutPaddings: {
                left: 90,
                right: 90,
                top: 0,
                bottom: 60,
            },

            showChart: true,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetPrecision: 'Assets/GET_QUOTATION_ASSET_PRECISION',
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),

        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        portfolioType() {
            return this.$store.state.Portfolio.portfolioType;
        },
        tradesPerExchangeArray() {
            return Object.keys(this.tradesPerExchange)
                .map((exchange) => {
                    const quantity = (+this.tradesPerExchange[exchange]).toFixed(this.quotationAssetPrecision);

                    return {
                        exchange,
                        quantity,
                        part: ((quantity / this.totalValue) * 100).toFixed(2),
                        style: placementsStyle[exchange.replace(' ', '').toUpperCase()],
                    };
                })
                .sort((a, b) => b.quantity - a.quantity);
        },

        isDataAvailable() {
            return this.tradesPerExchangeArray.length > 0;
        },

        labels() {
            return this.tradesPerExchangeArray.map(({ exchange }) => exchange);
        },
        datasetData() {
            return this.tradesPerExchangeArray.map(({ quantity }) => quantity);
        },
        backgroundColor() {
            return this.tradesPerExchangeArray.map(({ style }) => style.theme_colors.main);
        },
        totalValue() {
            return Object.values(this.tradesPerExchange)
                .reduce((sum, current) => sum + +current, 0)
                .round(this.quotationAssetPrecision);
        },
        data() {
            const isDisplay = this.datasetData.map((_, index) => index === 0);
            let currentPart = 0;

            for (let i = 1; i < isDisplay.length; i++) {
                if (+this.tradesPerExchangeArray[i].part > showLabelThreshold) {
                    isDisplay[i] = true;
                } else if (+this.tradesPerExchangeArray[i - 1].part > showLabelThreshold) {
                    isDisplay[i] = true;
                } else {
                    currentPart += +this.tradesPerExchangeArray[i].part;

                    if (currentPart >= showLabelThreshold) {
                        isDisplay[i] = true;
                        currentPart = 0;
                    }
                }
            }

            const summaryBalance = this.datasetData.reduce((prev, next) => prev + Number(next), 0);
            let valuableBalancesNumber = this.datasetData.reduce((prev, next) => {
                if ((next / summaryBalance) * 100 >= 5) {
                    return prev + 1;
                }
                return prev;
            }, 0);
            if (valuableBalancesNumber > 9) {
                valuableBalancesNumber = 9;
            }
            const truncatedLabels = this.labels.map((label, index) => {
                if (index !== valuableBalancesNumber) {
                    return label;
                }
                return 'Other';
            }).filter((_, index) => index <= valuableBalancesNumber);
            const truncatedDatasetData = [];
            this.datasetData.forEach((el, index) => {
                if (index < valuableBalancesNumber) {
                    truncatedDatasetData.push(Number(el));
                } else if (!truncatedDatasetData[valuableBalancesNumber]) {
                    truncatedDatasetData.push(Number(el));
                } else {
                    truncatedDatasetData[valuableBalancesNumber] += Number(el);
                }
            });

            return {
                labels: truncatedLabels,
                datasets: [
                    {
                        backgroundColor: this.backgroundColor,
                        borderWidth: 1,
                        borderColor: this.$store.getters.isThemeDark ? '#2C2C36' : 'white',
                        data: truncatedDatasetData,
                        totalValue: this.totalValue,
                        datalabels: {
                            display: true,
                            formatter(_, ctx) {
                                return `${ctx.chart.data.labels[ctx.dataIndex]}\n${((ctx.dataset.data[ctx.dataIndex] / ctx.dataset.totalValue) * 100).round(2)}%`;
                            },
                            backgroundColor(ctx) {
                                return ctx.dataset.backgroundColor[ctx.dataIndex];
                            },
                        },
                    },
                ],
            };
        },
    },
    methods: {
        tooltipLabelCallback({ index }, { datasets: [{ data }], labels }) {
            return `${labels[index]}: ${this.quotationAssetCharacter}${data[index].getSeparatedDigits()}`;
        },
    },
    created() {
        if (!(this.activeAccount && this.range)) {
            return;
        }
        // mixin
        this.getData(this.range, this.module);
    },
    watch: {
        range() {
            if (!(this.activeAccount && this.range)) {
                return;
            }
            // mixin
            this.getData(this.range, this.module);
        },
        activeAccount() {
            if (!(this.activeAccount && this.range)) {
                return;
            }
            // mixin
            this.getData(this.range, this.module);
        },
        portfolioType() {
            if (!this.module && (this.activeAccount && this.range)) {
                this.getData(this.range, this.portfolioType === PORTFOLIO_TYPES.CURRENT_ACCOUNT);
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.row {
    display: flex;
    align-items: center;
    column-gap: var(--m-xs);
}
.relative {
    position: relative;
}
</style>
