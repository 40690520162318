<template>
    <div class="module">
        <BlockHeader
            title="Balance History"
            :with-search="true"
            @on-search="onSearch"
            class="draggable"
        >
            <MultiplySelectBox :items="legendAssets" title="Assets" v-model="assetsToShow"/>
        </BlockHeader>
        <Preloader
            v-if="isLoading"
            :class="s.mAuto"
        />
        <div
            v-else
            ref="layout"
            :class="s.h100"
        >
            <line-chart
                :chart-data="chartData"
                :styles="styles"
                :tooltip-item-sort-callback="tooltipItemSortCallback"
                :tooltip-title-callback="tooltipTitleCallback"
                :tooltip-label-callback="tooltipLabelCallback"
                :tooltip-footer-callback="tooltipFooterCallback"
                :y-axe-label-callback="yAxeLabelCallback"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { timeConstants } from 'Config/timeConstants';
import BlockHeader from 'UI/BlockHeader.vue';
import MultiplySelectBox from 'Components/FormElements/MultiplySelectBox.vue';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';

import BalanceByPeriodData from './BalanceByPeriod.Data.vue';

export default {
    name: 'BalanceByPeriodChart',
    mixins: [BalanceByPeriodData],
    components: {
        BlockHeader,
        MultiplySelectBox,
    },
    props: {
        range: {
            type: Object,
            default: () => ({
                start: new Date(Date.now() - timeConstants.MONTH),
                end: new Date(),
            }),
        },
        module: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.activeAccountId && this.balancesLength > 0) {
            this.getData(this.range, this.module);
        }
    },
    data() {
        return {
            styles: {
                height: '540px',
                width: '100%',
                'margin-top': '20px',
            },

            assetsToShow: [],

            searchQuery: '',
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            quotationAssetPrecision: 'Assets/GET_QUOTATION_ASSET_PRECISION',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            activeAccount: 'Accounts/activeAccount',
            activeAccountId: 'Accounts/activeAccountID',
            isThemeDark: 'isThemeDark',
        }),

        labels() {
            const withNow = this.range.end > Date.now() - timeConstants.DAY;
            const labels = this.generateLabels(this.range.start, this.range.end, { withNow, hasAdditionalLabel: !withNow });
            return labels;
        },

        portfolioType() {
            return this.$store.state.Portfolio.portfolioType;
        },

        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        balancesStatistics() {
            if (!this.balancesStatisticsSource) {
                return [];
            }
            return this.balancesStatisticsSource
                .filter(
                    ({ assetSymbol }) => !this.searchQuery || assetSymbol.toUpperCase().indexOf(this.searchQuery.toUpperCase()) !== -1,
                )
                .filter(({ records }) => Object.values(records).some(({ quoted }) => quoted !== 0));
        },

        startToday() {
            return new Date(new Date().setHours(0, 0, 0, 0));
        },
        UTCStartToday() {
            return new Date(Date.UTC(this.startToday.getFullYear(), this.startToday.getMonth(), this.startToday.getDate()));
        },
        UTCStartTodayTimestamp() {
            return this.UTCStartToday.getTime();
        },

        legendAssets() {
            if (!this.balancesStatistics) {
                return [];
            }
            return this.balancesStatistics.map(({ assetSymbol }) => assetSymbol);
        },

        chartData() {
            return {
                labels: this.chartLabels,
                datasets: this.chartDatasets,
            };
        },
        chartLabels() {
            return this.labels;
        },
        chartDatasets() {
            const dataArray = [];
            const assetHasRecords = {};

            const balances = this.balancesStatistics;
            if (!balances) {
                return [];
            }

            return balances
                .filter(({ assetSymbol }) => !this.isAssetFiltered(assetSymbol))
                .sort((a, b) => a.total - b.total)
                .map(
                    (
                        {
                            assetSymbol, assetPrecision, records, chartColors: { line: lineColor, background: backgroundColor },
                        },
                        index,
                    ) => ({
                        backgroundColor,
                        borderWidth: 2,
                        borderColor: lineColor,
                        lineTension: 0,
                        pointRadius: 0,
                        fill: index > 0 ? '-1' : 'origin',
                        pointHoverRadius: 0,
                        pointHitRadius: 3,
                        config: {
                            assetSymbol,
                            assetPrecision,
                            baseBalance: Object.values(records).map(({ balance }) => balance),
                            index,
                        },
                        data: Object.values(records).map(({ quoted }, valueIndex, records) => {
                            if (index === 0) {
                                dataArray[valueIndex] = quoted;
                            } else {
                                dataArray[valueIndex] += quoted;
                            }

                            if (dataArray[valueIndex] > 0) {
                                assetHasRecords[assetSymbol] = true;

                                return dataArray[valueIndex];
                            } if (
                                assetHasRecords[assetSymbol]
                                || (
                                    valueIndex < records.length - 1
                                    && records[valueIndex + 1].quoted > 0
                                )
                            ) {
                                assetHasRecords[assetSymbol] = true;

                                return 0;
                            }
                            return 0;
                        }),
                        datalabels: {
                            labels: {
                                title: null,
                            },
                        },
                    }),
                );
        },
        balancesLength() {
            return this.$store.state.Balances.balances.length;
        },
        quotationAssetPrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
    },
    methods: {
        onSearch(e) {
            this.searchQuery = e;
        },
        tooltipTitleCallback(ctx) {
            const dateString = ctx[0].label;
            return this.$store.getters.getTimeDateString({ timestamp: Date.parse(dateString.replace('a.m.', 'AM').replace('p.m.', 'PM')) });
        },
        tooltipItemSortCallback(a, b, dataset) {
            const aValue = Number(a.datasetIndex === 0 ? a.dataset.data[a.dataIndex] : a.dataset.data[a.dataIndex] - dataset.datasets[a.datasetIndex - 1].data[a.dataIndex]);
            const bValue = Number(b.datasetIndex === 0 ? b.dataset.data[b.dataIndex] : b.dataset.data[b.dataIndex] - dataset.datasets[b.datasetIndex - 1].data[b.dataIndex]);
            return bValue - aValue;
        },
        tooltipLabelCallback(ctx) {
            const currentDataset = ctx.chart.data.datasets[ctx.datasetIndex];
            const currentValue = ctx.datasetIndex === 0 ? ctx.dataset.data[ctx.dataIndex] : ctx.dataset.data[ctx.dataIndex] - ctx.chart.data.datasets[ctx.datasetIndex - 1].data[ctx.dataIndex];

            if (+currentValue === 0) {
                return null;
            }
            try {
                return `${currentDataset.config.assetSymbol}: ${(Number(currentDataset.config.baseBalance[ctx.dataIndex]))
                    .floor(currentDataset.config.assetPrecision)
                    .noExponents()
                    .getSeparatedDigits()}; ${this.quotationAssetSymbol}: ${(+currentValue)
                    .toFixed(this.quotationAssetPrecision)
                    .getSeparatedDigits()}`;
            } catch (error) {
                // code crushed
            }
        },
        tooltipFooterCallback(ctx) {
            return `Total value in ${this.quotationAssetSymbol}: ${this.quotationAssetCharacter}${(ctx[0].raw)
                .toFixed(this.quotationAssetPrecision)
                .getSeparatedDigits()}`;
        },
        yAxeLabelCallback(value) {
            return `${this.quotationAssetCharacter}${value.toFixed(this.quotationAssetPrecision).getSeparatedDigits()}`;
        },

        isAssetFiltered(assetSymbol) {
            return this.assetsToShow.indexOf(assetSymbol) === -1;
        },
    },
    watch: {
        legendAssets(assets) {
            this.assetsToShow = assets;
        },
        range() {
            this.getData(this.range, this.module);
        },
        activeAccountId(value) {
            if (value && this.balancesLength > 0) {
                this.getData(this.range, this.module);
            }
        },
        balancesLength(value) {
            if (value > 0 && this.activeAccountId) {
                this.getData(this.range, this.module);
            }
        },
        portfolioType() {
            if (!this.module && (this.activeAccount && this.range)) {
                this.getData(this.range, this.module);
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.h100 {
    height: 100%;
}
.mAuto {
    margin: auto;
}
</style>
