<template>
    <div class="module doughnut-chart-module">
        <BlockHeader class="draggable" title="Current Balance">
            <ChartToggler v-model="showChart" />
        </BlockHeader>
        <div :class="['module-content', { 'is-overflowed': !showChart }]">
            <div class="chart-container" v-show="showChart">
                <doughnut-chart
                    :chart-data="data"
                    :tooltip-label-callback="tooltipLabelCallback"
                    v-if="isDataAvailable"
                    :layout-paddings="layoutPaddings"
                    :styles="styles"
                />
                <EmptyBarChart v-else/>
            </div>

            <div
                v-show="!showChart"
                :class="s.relative"
            >
                <DoughnutChartDatasetList
                    v-if="balances.length > 0"
                    :items="balances"
                >
                    <template #item-title="slotProps">
                        <div :class="s.row">
                            <CryptoIcon :symbol="slotProps.itemData.assetSymbol"/>
                            <span :style="`color: ${slotProps.itemData.manifest.color};`">
                            {{ slotProps.itemData.assetSymbol }}
                        </span>
                        </div>
                    </template>
                    <template #item-value="slotProps">
                        <span>{{ quotationAssetCharacter }}</span>{{ slotProps.itemData.totalEquivalent.round(quotationAssetPrecision).getSeparatedDigits() }}
                    </template>
                    <template #item-percentage="slotProps"> {{ slotProps.itemData.part }}%</template>
                </DoughnutChartDatasetList>
                <NoData v-else />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getManifest } from 'Models/assetsManifest';
import { defaultChartLineColor, showLabelThreshold } from 'Config/portfolio';
import BlockHeader from 'UI/BlockHeader.vue';
import ChartToggler from 'UI/ChartToggler.vue';
import { PORTFOLIO_TYPES } from 'Models/portfolio';
import NoData from 'UI/NoData.vue';

import DoughnutChartDatasetList from '../../Portfolios/components/DoughnutChartDatasetList.vue';

export default {
    name: 'CurrenciesChart',
    components: {
        NoData,
        DoughnutChartDatasetList,
        BlockHeader,
        ChartToggler,
    },
    data() {
        return {
            styles: {
                width: '300px',
            },
            layoutPaddings: {
                left: 90,
                right: 90,
                top: 0,
                bottom: 60,
            },

            showChart: true,
        };
    },
    computed: {
        ...mapGetters({
            aggregatedBalances: 'Balances/GET_AGGREGATED_BALANCES',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetPrecision: 'Assets/GET_QUOTATION_ASSET_PRECISION',
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        portfolioType() {
            return this.$store.state.Portfolio.portfolioType;
        },
        totalBalance() {
            if (this.portfolioType === PORTFOLIO_TYPES.CURRENT_ACCOUNT) {
                return this.$store.getters['Balances/GET_ACTIVE_ACCOUNT_TOTAL_SCORE'];
            }
            return this.$store.getters['Portfolio/requestAccountsData'].accountIds
                .reduce((accum, id) => accum + this.$store.getters['Balances/GET_TOTAL_ACCOUNT_SCORE'](id), 0);
        },
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        balances() {
            return new Array(...this.aggregatedBalances)
                .filter((balance) => balance.totalEquivalent > 0)
                .sort((a, b) => b.totalEquivalent - a.totalEquivalent)
                .map((balance) => Object.assign(balance, {
                    manifest: getManifest(balance.assetSymbol),
                    part: this.totalBalance !== 0
                        ? ((balance.totalEquivalent / this.totalBalance) * 100).toFixed(2)
                        : balance.totalEquivalent.toFixed(2),
                }));
        },
        isDataAvailable() {
            return this.balances.length > 0;
        },
        labels() {
            return this.balances.map((balance) => balance.assetSymbol);
        },
        backgroundColor() {
            return this.balances.map(({ manifest }) => (manifest ? manifest.color : defaultChartLineColor));
        },
        datasetData() {
            return this.balances.map(({ totalEquivalent }) => totalEquivalent);
        },
        data() {
            const isDisplay = this.datasetData.map((_, index) => index === 0);
            let currentPart = 0;

            for (let i = 1; i < isDisplay.length; i++) {
                if (+this.balances[i].part > showLabelThreshold) {
                    isDisplay[i] = true;
                } else if (+this.balances[i - 1].part > showLabelThreshold) {
                    isDisplay[i] = true;
                } else if (i !== isDisplay.length - 1) {
                    currentPart += +this.balances[i].part;

                    if (currentPart >= showLabelThreshold) {
                        isDisplay[i] = true;
                        currentPart = 0;
                    }
                }
            }

            const summaryBalance = this.datasetData.reduce((prev, next) => prev + Number(next), 0);
            let valuableBalancesNumber = this.datasetData.reduce((prev, next) => {
                if ((next / summaryBalance) * 100 >= 5) {
                    return prev + 1;
                }
                return prev;
            }, 0);
            if (valuableBalancesNumber > 9) {
                valuableBalancesNumber = 9;
            }
            const truncatedLabels = this.labels.map((label, index) => {
                if (index !== valuableBalancesNumber) {
                    return label;
                }
                return 'Other';
            }).filter((_, index) => index <= valuableBalancesNumber);
            const truncatedDatasetData = [];
            this.datasetData.forEach((el, index) => {
                if (index < valuableBalancesNumber) {
                    truncatedDatasetData.push(Number(el));
                } else if (!truncatedDatasetData[valuableBalancesNumber]) {
                    truncatedDatasetData.push(Number(el));
                } else {
                    truncatedDatasetData[valuableBalancesNumber] += Number(el);
                }
            });

            return {
                labels: truncatedLabels,
                datasets: [
                    {
                        backgroundColor: this.backgroundColor,
                        borderWidth: 1,
                        borderColor: this.$store.getters.isThemeDark ? '#2C2C36' : 'white',
                        data: truncatedDatasetData,
                        config: {
                            part: this.balances.map(({ part }) => part),
                        },
                        datalabels: {
                            display: true,
                            formatter(_, ctx) {
                                return `${ctx.chart.data.labels[ctx.dataIndex]}\n${ctx.dataset.config.part[ctx.dataIndex]}%`;
                            },
                            backgroundColor(ctx) {
                                return ctx.dataset.backgroundColor[ctx.dataIndex];
                            },
                        },
                    },
                ],
            };
        },
    },
    methods: {
        tooltipLabelCallback({ index }, { datasets: [{ data }], labels }) {
            return `${labels[index]}: ${this.quotationAssetCharacter}${(+data[index])
                .toFixed(this.quotationAssetPrecision)
                .getSeparatedDigits()}`;
        },
    },
};
</script>

<style lang="postcss" module="s">
.row {
    display: flex;
    align-items: center;
    column-gap: var(--m-xs);
}
.relative {
    position: relative;
}
</style>
