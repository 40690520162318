
import Vue from 'vue';
import { mapGetters } from 'vuex';

import numberFormater from 'Mixins/numberFormater';
import { PriceChangeStatuses } from 'Models/tradeHistory';
import { init, SET_ACTIVE_ASSET_PAIR, unsubscribeBeforeDestroy } from 'Store/v2/TradingData';
import { stringToFixedDigits } from 'Lib/utils/stringToFixedDigits';
import theme from 'Theme';
import Shift from 'UI/Shift.vue';
import Icon from 'UI/Icon.vue';
import { composedPath } from 'Lib/utils/eventPathChecker';

import ChooseExchange from './ChoosePlacement.vue';
import ChooseTool from './ChoosePair.vue';

export default Vue.extend<any, any, any, any>({
    name: 'StatusRow',
    mixins: [numberFormater],
    components: {
        ChooseExchange,
        ChooseTool,
        Shift,
        Icon,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        emitAutoSize: {
            type: Boolean,
            default: false,
        },
        hideAfterChoose: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            MINIMAL_PRICE: 0.00000001,
            updateInterval: null,
            updateDelay: 2000,
            stringToFixedDigits,
            PriceChangeStatuses,
            theme,
        };
    },
    computed: {
        ...mapGetters({
            activeTerminalPlacementName: 'Placements/activeTerminalPlacementName',
            activeTerminalAssetPair: 'AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR',
            activeTerminalAssetPairSymbol: 'AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_SYMBOL',
            activeAssetPairId: 'AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_ID',
            pairQuoteAssetSymbol: 'AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_QUOTE_ASSET_SYMBOL',
            quoteAssetFractionDeep: 'AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION_PRICE',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            tradingPriceChange: 'TradeHistory/tradingPriceChange',
        }),
        needMarginData() {
            const currentPlacementTag: string | undefined = this.$store.state.Placements.placementNamesToPlacementTags
                .get(this.activeTerminalPlacementName?.toUpperCase());
            if (!currentPlacementTag) {
                return;
            }

            return this.$store.state.Margin.totalBalances.some(({ placementTags }) => (
                placementTags.some((tag: string) => tag === currentPlacementTag)
            ));
        },
        currentMarginData() {
            if (!this.needMarginData) {
                return null;
            }

            const currentPlacementTag: string | undefined = this.$store.state.Placements.placementNamesToPlacementTags
                .get(this.activeTerminalPlacementName?.toUpperCase());
            return this.$store.state.Margin.totalBalances.find(({ placementTags }) => (
                placementTags.some((tag: string) => tag === currentPlacementTag)
            ));
        },
        imRate() {
            return Number(this.currentMarginData?.imRate ?? 0) * 100;
        },
        mmRate() {
            return Number(this.currentMarginData?.mmRate ?? 0) * 100;
        },
        imQuantity() {
            return Number(this.currentMarginData?.initialMarginQuantity ?? 0);
        },
        mmQuantity() {
            return Number(this.currentMarginData?.maintenanceMarginQuantity ?? 0);
        },
        pairTickers(): Record<string, never> {
            return {};
        },
        change24h() {
            return this.pairTickers?.percentChange ? this.pairTickers.percentChange : null;
        },
        high24h() {
            return this.pairTickers ? +this.pairTickers.high : null;
        },
        low24h() {
            return this.pairTickers ? +this.pairTickers.low : null;
        },
        volume24h() {
            return this.pairTickers ? +this.pairTickers.volume24h : null;
        },
        volume24hUSD() {
            return this.pairTickers ? +this.pairTickers.volume24h_usd : null;
        },
        currentPlacement() {
            const { state: { Placements } } = this.$store;
            return Placements.placements.find((placement) => placement.id === Placements.activeTerminalPlacementId)?.name.toUpperCase();
        },
        allPairs() {
            const { state: { AssetPairs }, getters } = this.$store;
            const { currentPage } = AssetPairs;

            const pairs = getters['AssetPairs/GET_ASSET_PAIRS'];
            let res = '';
            if (currentPage > 0) {
                for (let i = (currentPage - 1) * 300; i < pairs.length; i += 1) {
                    res += `${pairs[i].symbol};`;
                }
            }
            return res;
        },
        currentAssetPairData() {
            const res = this.allPairsData.find(({ id }) => id === this.activeAssetPairId);
            if (res) {
                this.$store.commit(SET_ACTIVE_ASSET_PAIR(res));
                return res;
            }
            return this.$store.state.TradingData.activeAssetPair;
        },
        allPairsData() {
            const { state: { TradingData } } = this.$store;
            return TradingData.snapshotData;
        },
        pricePrecision() {
            return this.$store.state.AssetPairs.workspaceSpotAssetPairs
                .get(this.activeTerminalPlacementName?.toUpperCase() ?? '')
                ?.get(this.activeTerminalAssetPair?.symbol.toUpperCase() ?? '')
                ?.placementPrecisionPrice
                ?? 8;
        },
        quantityPrecision() {
            return this.$store.state.AssetPairs.workspaceSpotAssetPairs
                .get(this.activeTerminalPlacementName?.toUpperCase() ?? '')
                ?.get(this.activeTerminalAssetPair?.symbol.toUpperCase() ?? '')
                ?.placementPrecisionQuantity
                ?? 8;
        },
    },
    methods: {
        getImElementBackground(index: number) {
            if (!this.needMarginData) {
                return 'gray';
            }
            if (index * 5 > this.imRate) {
                return 'gray';
            }
            if (this.imRate >= 60) {
                return 'red';
            }
            if (this.imRate >= 30) {
                return 'yellow';
            }
            return 'green';
        },
        getMmElementBackground(index: number) {
            if (!this.needMarginData) {
                return 'gray';
            }
            if (index * 5 > this.mmRate) {
                return 'gray';
            }
            if (this.mmRate >= 60) {
                return 'red';
            }
            if (this.mmRate >= 30) {
                return 'yellow';
            }
            return 'green';
        },
        toggleStatusBar() {
            this.$emit('toggle-status-row');
        },
        onChoosePair() {
            if (this.hideAfterChoose) {
                this.toggleStatusBar();
            }
        },
        async initData() {
            if (this.currentPlacement && this.activeTerminalAssetPairSymbol && this.allPairs) {
                await this.$store.dispatch(init({
                    placement: this.currentPlacement,
                    tag: this.$store.state.Placements.placementNamesToPlacementTags.get(this.currentPlacement.toUpperCase()),
                    pair: this.activeTerminalAssetPairSymbol,
                    allPairs: this.allPairs,
                    quotationSymbol: this.quotationAssetSymbol,
                }));
            }
        },
        closeMenuOnClickOutside(e) {
            let flag = true;
            const path = composedPath(e.target);
            if (path) {
                path.forEach((el) => {
                    if (el.id === 'statusRowContainer') {
                        flag = false;
                    }
                });
                if (flag && this.isOpen) {
                    this.$emit('toggle-status-row');
                }
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.closeMenuOnClickOutside);
    },
    async beforeDestroy() {
        await this.$store.dispatch(unsubscribeBeforeDestroy(undefined));
        document.removeEventListener('click', this.closeMenuOnClickOutside);
    },
    watch: {
        async currentPlacement() {
            await this.initData();
        },
        async allPairs() {
            await this.initData();
        },
        async quotationAssetSymbol() {
            await this.initData();
        },
    },
});
