// This file was autogenerated. Please do not change.
export interface ITradingDataRequest {
    assetPairSymbol?: string;
    assetPairSymbols?: string[];
    placementName: string;
    symbol?: string;
}

export default class TradingDataRequest {
    readonly _assetPairSymbol: string | undefined;

    /**
     * Description: Part of asset pair symbol, e.g. BTC
     * Example: BTC
     */
    get assetPairSymbol(): string | undefined {
        return this._assetPairSymbol;
    }

    readonly _assetPairSymbols: string[] | undefined;

    /**
     * Description: Asset pairs symbols, e.g.  ETH/BTC, BTC/USDT
     * Example: BTC/USDT,ETH/BTC
     */
    get assetPairSymbols(): string[] | undefined {
        return this._assetPairSymbols;
    }

    readonly _placementName: string;

    /**
     * Description: Placement (CEx) name, e.g. Binance, Bitfinex
     * Example: Binance
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _symbol: string | undefined;

    /**
     * Description: Part of base or quote asset or pair symbol
     * Example: OM
     */
    get symbol(): string | undefined {
        return this._symbol;
    }

    constructor(props: ITradingDataRequest) {
        if (typeof props.assetPairSymbol === 'string') {
            this._assetPairSymbol = props.assetPairSymbol.trim();
        }
        if (props.assetPairSymbols) {
            this._assetPairSymbols = props.assetPairSymbols;
        }
        this._placementName = props.placementName.trim();
        if (typeof props.symbol === 'string') {
            this._symbol = props.symbol.trim();
        }
    }

    serialize(): ITradingDataRequest {
        const data: ITradingDataRequest = {
            placementName: this._placementName,
        };
        if (typeof this._assetPairSymbol !== 'undefined') {
            data.assetPairSymbol = this._assetPairSymbol;
        }
        if (typeof this._assetPairSymbols !== 'undefined') {
            data.assetPairSymbols = this._assetPairSymbols;
        }
        if (typeof this._symbol !== 'undefined') {
            data.symbol = this._symbol;
        }
        return data;
    }

    toJSON(): ITradingDataRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetPairSymbol: !this._assetPairSymbol ? true : typeof this._assetPairSymbol === 'string',
            assetPairSymbols: !this._assetPairSymbols ? true : this._assetPairSymbols.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            symbol: !this._symbol ? true : typeof this._symbol === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
