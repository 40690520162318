
import Vue from 'vue';

import { timeConstants } from 'Config/timeConstants';
import PortfolioApi from 'Apis/Portfolio';
import PNLPayload from 'Entities/portfolioBalanceHistory/PNLPayload';

class PnlRecord {
    quantity: number | null;

    timestamp: number;

    constructor(timestamp: number, quantity?: string) {
        this.quantity = quantity ? Number(quantity) : null;
        this.timestamp = timestamp;
    }
}

interface Data {
    pnlStatistics: PnlRecord[],
}

export default Vue.extend<Data, any, any>({
    data() {
        return {
            pnlStatistics: [],
            isLoading: false,
        };
    },
    methods: {
        generateLabels(fromTimestamp: number, toTimestamp: number): number[] {
            const fromDate = new Date((new Date(fromTimestamp)).setHours(0, 0, 0, 0));
            const UTCFromDate = new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()));

            let currentLabelTimestamp = UTCFromDate.getTime();

            const labels: number[] = [];

            while (currentLabelTimestamp < toTimestamp) {
                labels.push(currentLabelTimestamp);
                currentLabelTimestamp += timeConstants.DAY;
            }

            return labels;
        },
        async getData(range, module) {
            const { start: from, end: to } = range;
            const { policies } = this.$store.getters['Accounts/activeAccount'];
            if (policies.includes('portfolio')) {
                try {
                    this.isLoading = true;
                    const { data: pnl } = await PortfolioApi.getPNLHistory(new PNLPayload({
                        UTCFromTs: from.valueOf(),
                        UTCToTs: to.valueOf(),
                        pnlResultType: 'Summary',
                        ...(module
                            ? { accountId: this.$store.getters['Accounts/activeAccountID'] }
                            : this.$store.getters['Portfolio/requestAccountsData']
                        ),
                        quotationAssetSymbol: this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL'],

                    }));
                    const labels = this.generateLabels(from.valueOf(), to.valueOf());
                    const result: PnlRecord[] = [];
                    let currentIndex = -1;

                    if (!pnl.quantityList || !pnl.UTCTimestampList) {
                        return;
                    }

                    labels.forEach((timestamp: number, index) => {
                        if (index === labels.length - 1) {
                            result.push(new PnlRecord(timestamp, pnl.quantityList![pnl.quantityList!.length - 1]));
                        } else {
                            currentIndex = pnl.UTCTimestampList!.indexOf(timestamp);

                            if (~currentIndex) {
                                result.push(new PnlRecord(timestamp, pnl.quantityList![currentIndex]));
                            } else {
                                result.push(new PnlRecord(timestamp, '0'));
                            }

                            currentIndex = -1;
                        }
                    });
                    this.pnlStatistics = result;
                } catch {
                    this.pnlStatistics = [];
                } finally {
                    this.isLoading = false;
                }
            }
        },
    },
});
