var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LegendBlock',{attrs:{"title":_vm.title,"from-account":_vm.account}},[(_vm.items && _vm.items.length)?_c('ul',_vm._l((_vm.items),function(item,index){return _c('li',{key:item.id,class:[
                _vm.s.row,
                _vm.s.text,
                _vm.s.pointer,
                {
                    [_vm.s.disabled]: item.disabled || item.isBlocked || item.id === _vm.disabledItemId || (!item.isEnabled && _vm.needCheckIsEnabled) || (!item.isPlacementLinked && _vm.needCheckIsEnabled),
                    [_vm.s.active]: _vm.isActiveItem(index),
                    [_vm.s.notAllowed]: (item.disabled || item.isBlocked || (!item.isEnabled && _vm.needCheckIsEnabled) || (!item.isPlacementLinked && _vm.needCheckIsEnabled)) && item.id !== _vm.disabledItemId,
                },
            ],on:{"click":function($event){item.disabled || item.isBlocked || (!item.isEnabled && _vm.needCheckIsEnabled) || ((!item.isPlacementLinked && _vm.needCheckIsEnabled)) ? false : item.id === _vm.disabledItemId ? _vm.switchActiveItems(item, index) :  _vm.setActiveItem(index, item)}}},[_vm._t("item",function(){return [_c('span',[_vm._v(" "+_vm._s(item)+" ")])]},{"itemData":item}),(item.isUnified)?_c('div',{class:[
                    _vm.s.unifiedWrapper,
                    {
                        [_vm.s.active]: _vm.isActiveItem(index),
                        [_vm.s.disabled]: item.disabled || item.isBlocked || item.id === _vm.disabledItemId || (!item.isEnabled && _vm.needCheckIsEnabled) || (!item.isPlacementLinked && _vm.needCheckIsEnabled),
                    },
                ]},[_c('span',[_vm._v(" Unified ")])]):_vm._e(),(_vm.isActiveItem(index))?_c('Icon',{class:[_vm.s.violet, { [_vm.s.marginTop]: _vm.isAccountsList }],attrs:{"icon":"check","small":""}}):(!item.isEnabled && _vm.needCheckIsEnabled)?_c('Icon',{class:[_vm.s.clock, _vm.s.icon],attrs:{"icon":"wrench"}}):(!item.isPlacementLinked && _vm.needCheckIsEnabled)?_c('Icon',{class:[_vm.s.link, _vm.s.icon],attrs:{"icon":"link"},on:{"clicked":function($event){return _vm.linkPlacement(item.name)}}}):(_vm.getIsPlacementPending(item.name) && _vm.needCheckIsEnabled)?_c('Icon',{class:[_vm.s.link, _vm.s.icon],attrs:{"icon":"clock"}}):_vm._e()],2)}),0):_c('NoData')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }