
import Vue from 'vue';
import 'chart.js/auto';
import { Line as LineChartComponent } from 'vue-chartjs';

export default Vue.extend<any, any, any, any>({
    components: {
        LineChartComponent,
    },
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        beginAtZero: {
            type: Boolean,
            default: false,
        },
        suggestedMax: {
            type: Number,
            default: null,
        },
        suggestedMin: {
            type: Number,
            default: null,
        },
    },
    computed: {
        data() {
            let { datasets } = this.chartData;

            datasets = datasets.map((dataset) => (dataset.datalabels
                ? dataset
                : {
                    ...dataset,
                    datalabels: {
                        labels: {
                            title: null,
                        },
                    },
                }));

            return {
                labels: this.chartData.labels,
                datasets,
            };
        },
        options() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        display: false,
                        ticks: {
                            display: false,
                            beginAtZero: this.beginAtZero,
                            suggestedMax: this.suggestedMax,
                            suggestedMin: this.suggestedMin,
                        },
                        grid: {
                            display: false,
                        },
                    },
                    x: {
                        display: false,
                        ticks: {
                            display: false,
                        },
                        grid: {
                            display: false,
                        },
                    },
                },
                plugins: {
                    tooltips: {
                        enabled: false,
                    },
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                    },
                },
            };
        },
    },
});
