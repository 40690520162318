// This file was autogenerated. Please do not change.
export interface IGetBorrowQuotaRequest {
    accountId?: string;
    assetPairSymbol?: string;
    leverage?: number;
    marketPrice?: number;
    placementTag?: string;
    side?: ('BUY' | 'SELL');
}

export default class GetBorrowQuotaRequest {
    readonly _accountId: string | undefined;

    /**
     * Example: AX5RL7F7P7C
     */
    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _assetPairSymbol: string | undefined;

    /**
     * Example: BTC/USDT
     */
    get assetPairSymbol(): string | undefined {
        return this._assetPairSymbol;
    }

    readonly _leverage: number | undefined;

    /**
     * Example: 5
     */
    get leverage(): number | undefined {
        return this._leverage;
    }

    readonly _marketPrice: number | undefined;

    /**
     * Example: 40000
     */
    get marketPrice(): number | undefined {
        return this._marketPrice;
    }

    readonly _placementTag: string | undefined;

    /**
     * Example: BYBIT
     */
    get placementTag(): string | undefined {
        return this._placementTag;
    }

    readonly _side: ('BUY' | 'SELL') | undefined;

    /**
     * Example: SELL
     */
    get side(): ('BUY' | 'SELL') | undefined {
        return this._side;
    }

    constructor(props: IGetBorrowQuotaRequest) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (typeof props.assetPairSymbol === 'string') {
            this._assetPairSymbol = props.assetPairSymbol.trim();
        }
        if (typeof props.leverage === 'number') {
            this._leverage = Number(props.leverage);
        }
        if (typeof props.marketPrice === 'number') {
            this._marketPrice = Number(props.marketPrice);
        }
        if (typeof props.placementTag === 'string') {
            this._placementTag = props.placementTag.trim();
        }
        if (props.side) {
            this._side = props.side;
        }
    }

    serialize(): IGetBorrowQuotaRequest {
        const data: IGetBorrowQuotaRequest = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._assetPairSymbol !== 'undefined') {
            data.assetPairSymbol = this._assetPairSymbol;
        }
        if (typeof this._leverage !== 'undefined') {
            data.leverage = Number(this._leverage);
        }
        if (typeof this._marketPrice !== 'undefined') {
            data.marketPrice = Number(this._marketPrice);
        }
        if (typeof this._placementTag !== 'undefined') {
            data.placementTag = this._placementTag;
        }
        if (typeof this._side !== 'undefined') {
            data.side = this._side;
        }
        return data;
    }

    toJSON(): IGetBorrowQuotaRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            assetPairSymbol: !this._assetPairSymbol ? true : typeof this._assetPairSymbol === 'string',
            leverage: true,
            marketPrice: true,
            placementTag: !this._placementTag ? true : typeof this._placementTag === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
