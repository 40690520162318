
import Vue from 'vue';

import theme from 'Theme';
import AssetValue from 'UI/AssetValue.vue';
import Borrowing from 'Entities/privatePresenter/Borrowing';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';

type Data = {
    theme: typeof theme;
};

type Computed = {
    currentUser: InternalUserResponse | undefined;
    isKycVerified: boolean;
};

type Props = {
    borrowing: Borrowing;
};

type NoData = Record<string, never>;

export default Vue.extend<Data, NoData, Computed, Props>({
    name: 'BorrowingsRow',
    components: {
        AssetValue,
    },
    props: {
        borrowing: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            theme,
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
    },
});
