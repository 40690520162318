<template>
    <div id="terminalPage" :class="s.relative">
        <div v-if="!isStatusRowVisible && isStatusRowOpen" :class="s.grayLayer"></div>
        <div
            id="statusRow"
            ref="statusRow"
            :class="[s.statusRowContainer, { [s.fixed]: !isStatusRowVisible, [s.paddingBottom]: !isStatusRowVisible, [s.shadow]: !isStatusRowVisible && !isStatusRowOpen }]"
        >
            <StatusRow
                :is-open="isStatusRowOpen"
                @toggle-status-row="isStatusRowOpen = !isStatusRowOpen"
            />
        </div>
        <PageLayout>
            <template #desktop-view>
                <grid-layout
                    :layout.sync="layout"
                    :col-num="4"
                    :row-height="94"
                    :responsive="false"
                    :breakpoints="vueGridLayoutBreakpoints"
                    :cols="vueGridLayoutCols"
                    :is-draggable="true"
                    :is-resizable="false"
                    :vertical-compact="true"
                    :margin="[14, 14]"
                    :use-css-transforms="false"
                    ref="mainGridLayout"
                    class="desktop-layout"
                    @breakpoint-changed="breakpointChangedEvent"
                >
                    <div id="terminalLayer">
                    <grid-item
                        :x="getLayoutElementByName(layout, 'PriceChart').x"
                        :y="getLayoutElementByName(layout, 'PriceChart').y"
                        :w="getLayoutElementByName(layout, 'PriceChart').w"
                        :h="getLayoutElementByName(layout, 'PriceChart').h"
                        :i="getLayoutElementByName(layout, 'PriceChart').i"
                        :is-resizable="true"
                        drag-allow-from=".draggable"
                        drag-ignore-from="p, a, button, select, label, input, span, .round-navbar"
                    >
                        <PriceChart id="terminalPriceChart" :pair="currentAssetPair" :placement="currentPlacement"/>
                    </grid-item>
                    <grid-item
                        :x="getLayoutElementByName(layout, 'PriceLadder').x"
                        :y="getLayoutElementByName(layout, 'PriceLadder').y"
                        :w="getLayoutElementByName(layout, 'PriceLadder').w"
                        :h="getLayoutElementByName(layout, 'PriceLadder').h"
                        :i="getLayoutElementByName(layout, 'PriceLadder').i"
                        drag-allow-from=".draggable"
                        drag-ignore-from="p, a, button, select, label, input, span"
                    >
                        <PriceLadder :pair="currentAssetPair" :placement="currentPlacement"/>
                    </grid-item>
                    <grid-item
                        :x="getLayoutElementByName(layout, 'TradeHistory').x"
                        :y="getLayoutElementByName(layout, 'TradeHistory').y"
                        :w="getLayoutElementByName(layout, 'TradeHistory').w"
                        :h="getLayoutElementByName(layout, 'TradeHistory').h"
                        :i="getLayoutElementByName(layout, 'TradeHistory').i"
                        drag-allow-from=".draggable"
                        drag-ignore-from="p, a, button, select, label, input, span"
                    >
                        <TradeHistory :pair="currentAssetPair" :placement="currentPlacement"/>
                    </grid-item>
                    <grid-item
                        :x="getLayoutElementByName(layout, 'Orders').x"
                        :y="getLayoutElementByName(layout, 'Orders').y"
                        :w="getLayoutElementByName(layout, 'Orders').w"
                        :h="getLayoutElementByName(layout, 'Orders').h"
                        :i="getLayoutElementByName(layout, 'Orders').i"
                        drag-allow-from=".draggable"
                        drag-ignore-from="p, a, button, select, label, input, span"
                    >
                        <Orders :pair="currentAssetPair" :placement="currentPlacement"/>
                    </grid-item>
                    <grid-item
                        :x="getLayoutElementByName(layout, 'Trading').x"
                        :y="getLayoutElementByName(layout, 'Trading').y"
                        :w="getLayoutElementByName(layout, 'Trading').w"
                        :h="getLayoutElementByName(layout, 'Trading').h"
                        :i="getLayoutElementByName(layout, 'Trading').i"
                        drag-allow-from=".draggable"
                        drag-ignore-from="p, a, button, select, label, input, span"
                        id="tradingModule"
                    >
                        <Trading v-if="currentAssetPair && currentPlacement" :pair="currentAssetPair" :placement="currentPlacement" :unique-id="uniqueId" />
                    </grid-item>
                    <grid-item
                        :x="getLayoutElementByName(layout, 'AccountPortfolio').x"
                        :y="getLayoutElementByName(layout, 'AccountPortfolio').y"
                        :w="getLayoutElementByName(layout, 'AccountPortfolio').w"
                        :h="getLayoutElementByName(layout, 'AccountPortfolio').h"
                        :i="getLayoutElementByName(layout, 'AccountPortfolio').i"
                        drag-allow-from=".draggable"
                        drag-ignore-from="p, a, button, select, label, input, span, .exchanges-list"
                    >
                        <AccountPortfolio />
                    </grid-item>
                    </div>
                </grid-layout>
            </template>
        </PageLayout>
        <Modal :pair="currentAssetPair" :placement="currentPlacement" :unique-id="uniqueId" />
        <TakeProfitStopLossModal />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { nanoid } from 'nanoid';

import PageLayout from 'Common/PageLayout.vue';
import { gridPages } from 'Models/interface';
import layoutNameResolver from 'Mixins/layoutNameResolver';
// moved
import AccountPortfolio from 'Modules/AccountPortfolio/AccountPortfolio.vue';
import TradeHistory from 'Modules/TradeHistory/TradeHistory.vue';
import Trading from 'Modules/Trading/Trading.vue';
import Modal from 'Modules/Trading/MultiaccountOrdersModal/Modal.vue';
import PriceLadder from 'Modules/OrderBook/OrderBook.vue';
import PriceChart from 'Modules/PriceChart/PriceChart.vue';
import TakeProfitStopLossModal from 'Modules/OrdersHistory/components/TakeProfitStopLossModal.vue';
// end moved
// mobile
import StatusRow from '@/components/Terminal/components/StatusRow.vue';
// hello
import Orders from 'Components/modules/OrdersHistory/OrdersHistory.vue';
import { getTotalBalances } from 'Store/v2/Margin';

export default {
    name: 'Main',
    mixins: [
        layoutNameResolver,
    ],
    components: {
        Modal,
        PageLayout,
        TakeProfitStopLossModal,
        AccountPortfolio,
        Trading,
        StatusRow,
        PriceChart,
        PriceLadder,
        Orders,
        TradeHistory,
    },
    data() {
        return {
            gridLayout: null,
            currentBreakpoint: 'lg',
            uniqueId: '',
            isStatusRowOpen: false,
            isStatusRowVisible: true,
        };
    },
    computed: {
        ...mapGetters({
            vueGridLayoutBreakpoints: 'vueGridLayoutBreakpoints',
            vueGridLayoutCols: 'vueGridLayoutCols',
        }),
        layout: {
            get() {
                return this.$store.getters.pageGrid(gridPages.TERMINAL, this.currentBreakpoint);
            },
            set(grid) {
                this.$store.dispatch('changePageGrid', {
                    page: gridPages.TERMINAL,
                    grid,
                    breakpoint: this.currentBreakpoint,
                });
            },
        },
        isTradingViewFullscreen() {
            return this.$store.getters.isTerminalTradingViewFullscreen;
        },
        currentPlacement() {
            const { state: { Placements } } = this.$store;
            const result = Placements.placements.find((placement) => {
                return placement.id === Placements.activeTerminalPlacementId;
            });
            return result?.name.toUpperCase();
        },
        currentAssetPair() {
            return this.$store.getters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR']?.symbol;
        },
    },
    methods: {
        breakpointChangedEvent(breakpoint) {
            this.currentBreakpoint = breakpoint;
            this.$store.commit('SET_PAGE_ACTIVE_BREAKPOINT', { page: gridPages.TERMINAL, breakpoint });
        },
        scrollListener() {
            this.isStatusRowVisible = document.querySelector('body').scrollTop < 40;
            if (!this.isStatusRowVisible) {
                this.$refs.statusRow.style.left = `${document.querySelector('aside').getBoundingClientRect().width + 14}px`;
                if (this.isStatusRowOpen) {
                    this.isStatusRowOpen = false;
                }
            }
        },
    },
    created() {
        this.$store.dispatch('downloadTerminalApiData');
    },
    async mounted() {
        this.uniqueId = nanoid(8);
        this.gridLayout = this.$refs.mainGridLayout;

        document.querySelector('body').addEventListener('scroll', this.scrollListener);

        await this.$store.dispatch(getTotalBalances());
    },
    beforeDestroy() {
        document.querySelector('body').removeEventListener('scroll', this.scrollListener);
    },
    updated() {
        this.gridLayout = this.$refs.mainGridLayout;
    },
    watch: {
        isTradingViewFullscreen() {
            if (this.gridLayout && this.gridLayout.resizeEvent && typeof this.gridLayout.resizeEvent === 'function') {
                this.gridLayout.resizeEvent();
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.statusRowContainer {
    padding: var(--m-l) 14px 0 14px;
    &.paddingBottom {
        padding-bottom: var(--m-l);
    }

    &.shadow {
        box-shadow: var(--popover-box-shadox);
    }

    &.fixed {
        position: fixed;
        top: 62px;
        right: 20px;
        z-index: 998;
        background-color: var(--cl-white-background);
        outline: 1px solid var(--cl-violet);
        border-radius: 16px;
    }
}
.grayLayer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--modal-background);
    opacity: 0.8;
    z-index: 997;
}
.relative {
    position: relative;
}
</style>
