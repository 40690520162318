var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[_vm.s.container, _vm.theme.placeorder.placeorder, _vm.s.relative]},[_c('div',{staticClass:"draggable",class:_vm.s.moduleHeader},[_c('div',{class:_vm.s.row},[_c('div',{class:_vm.s.select},_vm._l((_vm.isCurrentPlacementFutures ? _vm.futuresSides : _vm.sides),function(el,i){return _c('div',{key:el.value,class:[i === 0 && _vm.activeSideIndex === 0 && _vm.s.green, i === 1 && _vm.activeSideIndex === 1 && _vm.s.red],on:{"click":function($event){return _vm.setActiveSideIndex(i)}}},[_vm._v(" "+_vm._s(el.value)+" "+_vm._s(_vm.isCurrentPlacementFutures ? '' : _vm.baseAssetSymbol)+" ")])}),0),_c('div',{class:_vm.s.pairRow},[_c('PlacementIcon',{attrs:{"placement":_vm.placement,"size":12}}),_c('span',{class:_vm.s.text},[_vm._v(" "+_vm._s(_vm.pair)+" ")])],1)]),_c('AccountColorMarker',{attrs:{"color":_vm.activeAccountColor,"withName":{
                name: _vm.activeAccount ? _vm.activeAccount.name : '',
                description: _vm.activeAccount ? _vm.activeAccount.description : '',
            },"no-margin":"","need-outline":""}}),(_vm.isPlacementTradingBlocked)?_c('span',{staticClass:"tag is-color-error"},[_vm._v(" Sorry. The trading service is currently under maintenance ")]):_vm._e()],1),_c('div',{class:_vm.s.relative},[[_c('div',{class:[_vm.s.row, _vm.s.mbxxl]},[_c('div',{class:[_vm.s.col, _vm.s.w66]},[_c('div',{class:_vm.theme.placeorder.orderTypesRow},_vm._l((_vm.getOrdersTypes),function(el,index){return _c('span',{key:`orderType${index}`,class:[_vm.theme.placeorder.menuText, { [_vm.theme.placeorder.active]: index === _vm.activeOrderTypeIndex, [_vm.theme.placeorder.disabled]: el.disabled }],on:{"click":function($event){return _vm.setActiveOrderType({ el, index })}}},[_vm._v(" "+_vm._s(_vm.$store.getters.localization.Body.Terminal.Trading.OrderTypes[el.field])+" "),(_vm.maxLeverage && el.needLeverage && !el.disabled)?_c('span',{class:[_vm.s.leverageBadge, { [_vm.s.active]: index === _vm.activeOrderTypeIndex }]},[_vm._v(" x"+_vm._s(_vm.maxLeverage)+" ")]):_vm._e()])}),0)]),_c('div',{class:[{ [_vm.s.inactive]: _vm.activeOrderType.value !== _vm.OrdersTypes['LIMIT'].value && _vm.activeOrderType.value !== _vm.OrdersTypes['STOP_LIMIT'].value }, _vm.s.col, _vm.s.w33]},[_c('div',{class:_vm.theme.placeorder.expiryContainer},[_c('span',{class:[_vm.theme.placeorder.expiryText, _vm.s.mrxs]},[_vm._v("Expiry")]),_c('Dropdown',{attrs:{"items":_vm.getExpiries.map((el) => el.label),"disabled":_vm.activeOrderType.value !== _vm.OrdersTypes['LIMIT'].value && _vm.activeOrderType.value !== _vm.OrdersTypes['STOP_LIMIT'].value,"active-index":_vm.dropdownActiveIndex},on:{"change":function($event){return _vm.setExpiry($event)}}})],1)])]),(!_vm.isCurrentPlacementFutures)?[(_vm.activeOrderType.value === _vm.OrdersTypes['LIMIT'].value)?_c('CryptoLimit',{attrs:{"is-modal":_vm.isModal,"current-expiry":_vm.expiries[_vm.currentExpiryIndex].value,"placement":_vm.placement,"pair":_vm.pair,"preseted-quantity":_vm.presetedQuantity,"store-data":{
                        quantityBalanceFree: _vm.quantityBalanceFree,
                        totalBalanceFree: _vm.totalBalanceFree,
                        activeSide: _vm.activeSide,
                        commissionAsset: _vm.commissionAsset,
                        quantityRestriction: _vm.quantityRestriction,
                        priceRestriction: _vm.priceRestriction,
                        totalRestriction: _vm.totalRestriction,
                        bidPrice: _vm.bidPrice,
                        askPrice: _vm.askPrice,
                        marketPrice: _vm.marketPrice,
                        feesRate: _vm.feesRate,
                    },"unique-id":_vm.uniqueId},on:{"set-quantity":function($event){_vm.presetedQuantity = $event}}}):(_vm.activeOrderType.value === _vm.OrdersTypes['MARKET'].value)?_c('SpotMarket',{attrs:{"is-modal":_vm.isModal,"placement":_vm.placement,"pair":_vm.pair,"preseted-quantity":_vm.presetedQuantity,"store-data":{
                        commissionAsset: _vm.commissionAsset,
                        activeSide: _vm.activeSide,
                        price: _vm.marketPrice,
                        orderRestrictions: _vm.orderRestrictions,
                        feesRate: _vm.feesRate,
                        marketSellPrice: _vm.marketSellPrice,
                        marketBuyPrice: _vm.marketBuyPrice,
                    },"unique-id":_vm.uniqueId},on:{"set-quantity":function($event){_vm.presetedQuantity = $event}}}):(_vm.activeOrderType.value === _vm.OrdersTypes['STOP_LIMIT'].value)?_c('StopLimit',{attrs:{"is-modal":_vm.isModal,"current-expiry":_vm.expiries[_vm.currentExpiryIndex].value,"placement":_vm.placement,"pair":_vm.pair,"preseted-quantity":_vm.presetedQuantity,"store-data":{
                        commissionAsset: _vm.commissionAsset,
                        activeSide: _vm.activeSide,
                        priceRestriction: _vm.priceRestriction,
                        bidPrice: _vm.bidPrice,
                        askPrice: _vm.askPrice,
                        marketPrice: _vm.marketPrice,
                        feesRate: _vm.feesRate,
                    },"unique-id":_vm.uniqueId},on:{"set-quantity":function($event){_vm.presetedQuantity = $event}}}):(_vm.activeOrderType.value === _vm.OrdersTypes['STOP_MARKET'].value)?_c('StopMarket',{attrs:{"is-modal":_vm.isModal,"placement":_vm.placement,"pair":_vm.pair,"preseted-quantity":_vm.presetedQuantity,"store-data":{
                        commissionAsset: _vm.commissionAsset,
                        activeSide: _vm.activeSide,
                        bidPrice: _vm.bidPrice,
                        askPrice: _vm.askPrice,
                        marketPrice: _vm.marketPrice,
                        feesRate: _vm.feesRate,
                        marketSellPrice: _vm.marketSellPrice,
                        marketBuyPrice: _vm.marketBuyPrice,
                    },"unique-id":_vm.uniqueId},on:{"set-quantity":function($event){_vm.presetedQuantity = $event}}}):_vm._e(),(_vm.activeOrderType.value === _vm.OrdersTypes['MARGIN_LIMIT'].value)?_c('MarginLimit',{attrs:{"is-modal":_vm.isModal,"current-expiry":_vm.expiries[_vm.currentExpiryIndex].value,"placement":_vm.placement,"pair":_vm.pair,"preseted-quantity":_vm.presetedQuantity,"store-data":{
                        quantityBalanceFree: _vm.quantityBalanceFree,
                        totalBalanceFree: _vm.totalBalanceFree,
                        activeSide: _vm.activeSide,
                        commissionAsset: _vm.commissionAsset,
                        quantityRestriction: _vm.quantityRestriction,
                        priceRestriction: _vm.priceRestriction,
                        totalRestriction: _vm.totalRestriction,
                        bidPrice: _vm.bidPrice,
                        askPrice: _vm.askPrice,
                        marketPrice: _vm.marketPrice,
                        feesRate: _vm.feesRate,
                    },"unique-id":_vm.uniqueId},on:{"set-quantity":function($event){_vm.presetedQuantity = $event}}}):(_vm.activeOrderType.value === _vm.OrdersTypes['MARGIN_MARKET'].value)?_c('MarginMarket',{attrs:{"is-modal":_vm.isModal,"placement":_vm.placement,"pair":_vm.pair,"preseted-quantity":_vm.presetedQuantity,"store-data":{
                        commissionAsset: _vm.commissionAsset,
                        activeSide: _vm.activeSide,
                        price: _vm.marketPrice,
                        orderRestrictions: _vm.orderRestrictions,
                        feesRate: _vm.feesRate,
                        marketSellPrice: _vm.marketSellPrice,
                        marketBuyPrice: _vm.marketBuyPrice,
                    },"unique-id":_vm.uniqueId},on:{"set-quantity":function($event){_vm.presetedQuantity = $event}}}):_vm._e()]:[(_vm.activeOrderType.value === _vm.OrdersTypes['LIMIT'].value)?_c('FuturesCryptoLimit',{attrs:{"order-type":_vm.activeOrderType.value,"current-expiries":_vm.expiries[_vm.currentExpiryIndex].value,"placement":_vm.placement,"pair":_vm.pair,"unique-id":_vm.uniqueId,"preseted-leverage":_vm.presetedLeverage,"preseted-quantity":_vm.presetedQuantity,"store-data":{
                        totalBalanceFree: _vm.totalBalanceFree,
                        activeSide: _vm.activeSide,
                        commissionAsset: _vm.commissionAsset,
                        quantityRestriction: _vm.quantityRestriction,
                        priceRestriction: _vm.priceRestriction,
                        totalRestriction: _vm.totalRestriction,
                        bidPrice: _vm.bidPrice,
                        askPrice: _vm.askPrice,
                        marketPrice: _vm.marketPrice,
                        feesRate: _vm.feesRate,
                    }},on:{"select-leverage":function($event){_vm.presetedLeverage = $event},"set-quantity":function($event){_vm.presetedQuantity = $event}}}):_vm._e(),(_vm.activeOrderType.value === _vm.OrdersTypes['MARKET'].value)?_c('FuturesSpotMarket',{attrs:{"order-type":_vm.activeOrderType.value,"placement":_vm.placement,"pair":_vm.pair,"unique-id":_vm.uniqueId,"preseted-leverage":_vm.presetedLeverage,"preseted-quantity":_vm.presetedQuantity,"store-data":{
                        totalBalanceFree: _vm.totalBalanceFree,
                        activeSide: _vm.activeSide,
                        commissionAsset: _vm.commissionAsset,
                        quantityRestriction: _vm.quantityRestriction,
                        priceRestriction: _vm.priceRestriction,
                        totalRestriction: _vm.totalRestriction,
                        bidPrice: _vm.bidPrice,
                        askPrice: _vm.askPrice,
                        marketPrice: _vm.marketPrice,
                        feesRate: _vm.feesRate,
                        marketSellPrice: _vm.marketSellPrice,
                        marketBuyPrice: _vm.marketBuyPrice,
                    }},on:{"select-leverage":function($event){_vm.presetedLeverage = $event},"set-quantity":function($event){_vm.presetedQuantity = $event}}}):_vm._e(),(_vm.activeOrderType.value === _vm.OrdersTypes['STOP_LIMIT'].value)?_c('FuturesStopLimit',{attrs:{"order-type":_vm.activeOrderType.value,"current-expiries":_vm.expiries[_vm.currentExpiryIndex].value,"placement":_vm.placement,"pair":_vm.pair,"unique-id":_vm.uniqueId,"preseted-leverage":_vm.presetedLeverage,"preseted-quantity":_vm.presetedQuantity,"store-data":{
                        totalBalanceFree: _vm.totalBalanceFree,
                        activeSide: _vm.activeSide,
                        commissionAsset: _vm.commissionAsset,
                        quantityRestriction: _vm.quantityRestriction,
                        priceRestriction: _vm.priceRestriction,
                        totalRestriction: _vm.totalRestriction,
                        bidPrice: _vm.bidPrice,
                        askPrice: _vm.askPrice,
                        marketPrice: _vm.marketPrice,
                        feesRate: _vm.feesRate,
                    }},on:{"select-leverage":function($event){_vm.presetedLeverage = $event},"set-quantity":function($event){_vm.presetedQuantity = $event}}}):_vm._e(),(_vm.activeOrderType.value === _vm.OrdersTypes['STOP_MARKET'].value)?_c('FuturesStopMarket',{attrs:{"order-type":_vm.activeOrderType.value,"placement":_vm.placement,"pair":_vm.pair,"unique-id":_vm.uniqueId,"preseted-leverage":_vm.presetedLeverage,"preseted-quantity":_vm.presetedQuantity,"store-data":{
                        totalBalanceFree: _vm.totalBalanceFree,
                        activeSide: _vm.activeSide,
                        commissionAsset: _vm.commissionAsset,
                        quantityRestriction: _vm.quantityRestriction,
                        priceRestriction: _vm.priceRestriction,
                        totalRestriction: _vm.totalRestriction,
                        bidPrice: _vm.bidPrice,
                        askPrice: _vm.askPrice,
                        marketPrice: _vm.marketPrice,
                        feesRate: _vm.feesRate,
                        marketSellPrice: _vm.marketSellPrice,
                        marketBuyPrice: _vm.marketBuyPrice,
                    }},on:{"select-leverage":function($event){_vm.presetedLeverage = $event},"set-quantity":function($event){_vm.presetedQuantity = $event}}}):_vm._e()]]],2),((!_vm.hasAccountAccess || !_vm.isActivePlacementLinked) && _vm.activeAccountId !== 'DEMOACC')?_c('ModuleBlocker',{attrs:{"is-placement-not-linked":!_vm.isActivePlacementLinked,"current-placement-name":_vm.placement}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }