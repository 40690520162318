<template>
    <div id="quickBalanceModule" :class="[theme.quickbalances.quickbalances, s.module]">
        <div :class="s.header" class="draggable">
            <div :class="s.group">
                <AccountColorMarker
                    :color="activeAccountColor"
                    :withName="{ name: activeAccountName, description: activeAccountDescription }"
                    no-margin
                    need-outline
                />
                <NumberFormater
                    :precision="balancePrecision"
                    :symbol="quotationAssetCharacter"
                    :value="isDeFi ? defiTotalScore : isEarn ? earnTotalScore : totalScore"
                />
                <NumberFormater
                    :class="s.bottom"
                    v-if="isEarn"
                    :precision="2"
                    :symbol="quotationAssetCharacter"
                    :value="summaryEarnings"
                    :prefix="summaryEarnings > 0 ? '+' : ''"
                    :number-class-name="s.numberText"
                    :fraction-class-name="s.fractionText"
                />
                <Info
                    v-if="isDeFi || isEarn"
                    :text="infoText"
                />
            </div>
            <div :class="s.group">
                <router-link
                    to="/wallets"
                    :class="s.underlined"
                >
                    See Wallets&rarr;
                </router-link>
                <Search
                    @on-search="search = $event"
                    :closeOnClickOutside="false"
                />
            </div>
        </div>
        <div :class="s.moduleContent">
            <div :class="s.w100">
                <div :class="s.subheaderContainer">
                    <div>
                        <div :class="s.checkboxesContainer">
                            <Checkbox
                                v-for="checkbox in assetTypes"
                                :key="checkbox.name"
                                :value="checkbox.value"
                                @state-changed="handleCheckbox(checkbox.name, checkbox.value)"
                                :label-class="theme.quickbalances.menuText"
                                :label-active-class="theme.quickbalances.active"
                            >
                                <template>
                                    {{ checkbox.name }}
                                </template>
                            </Checkbox>
                        </div>
                    </div>
                    <div v-if="!isDeFi && hasAnyBalance">
                        <span
                            @click="filterEmptyBalances = !filterEmptyBalances"
                            :class="theme.quickbalances.zeroBalancesSwitch"
                        >
                            {{ filterEmptyBalances ? 'Show' : 'Hide' }} Zero Balances
                        </span>
                    </div>
                </div>
                <div :class="s.contentContainer">
                    <ChoosePlacement
                        v-if="!isDeFi && !isEarn"
                        :is-workspace="isWorkspace"
                        @set-placement="setPlacement($event)"
                        :class="s.w30"
                    />
                    <div :class="[{ [s.w100]: isDeFi || isEarn, [s.w70]: !isDeFi && !isEarn }]">
                        <div
                            ref="grid"
                            @scroll="hideModal"
                            @wheel="onWheel"
                            :class="[s.balancesGrid, { [s.noScrollBar]: isModalShown }]"
                        >
                            <Balance
                                v-for="balance in activeBalances"
                                :key="balance.id"
                                ref="balance"
                                :balance="balance"
                                :is-de-fi="isDeFi"
                                :is-workspace="isWorkspace"
                                @toggle-modal="isModalShown = $event"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { assetsTypes } from 'Store/v2/Assets';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import { getQuickBalancesPositions } from 'Store/v2/Futures';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import theme from 'Theme';
import Checkbox from 'Control/Checkbox.vue';
import NumberFormater from 'UI/NumberFormater.vue';
import Balance from 'Modules/AccountPortfolio/components/Balance.vue';
import { setAccountPortfolioActivePlacementId } from 'Store/v2/Balances';
import { changeScroll } from 'Lib/utils/horizontalScroll';
import Search from 'Control/Search.vue';
import Info from 'UI/Info.vue';
import PublicDataApi from 'Apis/PublicData';
import AssetQuotationRequest from 'Entities/publicPresenter/AssetQuotationRequest';
import ApiError from 'Entities/ApiError';

import ChoosePlacement from './components/ChoosePlacement.vue';

export default {
    name: 'AccountPortfolio',
    components: {
        Info,
        Search,
        AccountColorMarker,
        ChoosePlacement,
        Checkbox,
        Balance,
        NumberFormater,
    },
    props: {
        isDeFi: {
            type: Boolean,
            default: false,
        },
        isEarn: {
            type: Boolean,
            default: false,
        },
        isWorkspace: {
            type: Boolean,
            default: false,
        },
        positionsRewards: {
            type: undefined,
            default: () => [],
        },
        activeBlockchain: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            assetTypes: [
                { name: 'All', value: true },
                { name: 'Fiat', value: true },
                { name: 'Stablecoin', value: true },
                { name: 'Crypto', value: true },
            ],
            assetsTypes,
            filterEmptyBalances: false,
            currentTypeFilter: null,
            theme,
            togglePlacementListenerId: null,
            toggledFiats: false,
            toggledCrypto: false,
            toggledStablecoin: false,
            activeAccountPortfolioWorkspacePlacementId: 0,
            isModalShown: false,
            search: '',
            quotationsMap: new Map(),
        };
    },
    computed: {
        ...mapGetters({
            totalScore: 'Balances/GET_ACTIVE_ACCOUNT_TOTAL_SCORE',
            accountPortfolioActivePlacementId: 'Balances/GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID',
            placementsList: 'Placements/placements',
            activeTerminalPlacementId: 'Placements/activeTerminalPlacementId',
            activeAccountName: 'Accounts/activeAccountName',
            activeAccountId: 'Accounts/activeAccountID',
            activeAccountDescription: 'Accounts/activeAccountDescription',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            quotationAssetPrecision: 'Assets/GET_QUOTATION_ASSET_PRECISION',
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        summaryEarnings() {
            return this.positionsRewards.reduce((accum, { rewardAmount, rewardAsset }) => {
                if (this.quotationsMap.has(rewardAsset)) {
                    return accum + (rewardAmount * this.quotationsMap.get(rewardAsset));
                }
                return accum;
            }, 0);
        },
        infoText() {
            if (this.isDeFi) {
                return 'All operations involving DeFi instruments are conducted via direct interaction with the smart contracts of the chosen network. These transactions are executed straight from the wallets within the custodial storage.';
            }
            if (this.isEarn) {
                return 'Simple Binance Earn investment instruments utilize the balances from Binance Spot Trading. To allocate funds for Simple Binance Earn, ensure to use the allocation functionality directed towards the Binance Spot platform.';
            }
            return '';
        },
        accountPortfolioActivePlacementBalances() {
            if (this.isWorkspace) {
                return this.$store.getters['Balances/GET_ACTIVE_ACCOUNT_BALANCES_BY_PLACEMENT_ID'](this.activeAccountPortfolioWorkspacePlacementId);
            }
            return this.$store.getters['Balances/GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_BALANCES'];
        },
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        isCurrentPlacementFutures() {
            if (this.isWorkspace) {
                const placement = this.$store.getters['Placements/getPlacementById'](this.activeAccountPortfolioWorkspacePlacementId);
                return placement.type ? placement.type.toLowerCase().indexOf('futures') !== -1 : false;
            }
            return this.$store.getters['Placements/getPlacementById'](this.accountPortfolioActivePlacementId).type.toLowerCase().indexOf('futures') !== -1;
        },
        currencyTypeToggleAdapter: {
            get() {
                return this.currenciesTypes.filter((t) => t.type !== 'fiat' || !this.isDeFi).findIndex(({ type }) => type === this.currentTypeFilter);
            },
            set({ type }) {
                this.currentTypeFilter = type;
            },
        },

        balances() {
            const result = [];
            let assetsArray;
            if (this.isWorkspace) {
                const temp = this.$store.state.Assets.cachedAssets.find(({ placementId }) => Number(placementId) === Number(this.activeAccountPortfolioWorkspacePlacementId));
                assetsArray = temp ? temp.assets : [];
            } else {
                assetsArray = this.$store.getters['Assets/GET_ACTIVE_TERMINAL_ACCOUNT_PORTFOLIO_PLACEMENT_ASSETS'];
            }
            assetsArray.forEach(
                ({
                    symbol, type, multiplier, placementName, placementId, id, blockchainName,
                }) => {
                    const currentBalances = this.accountPortfolioActivePlacementBalances.filter(
                        ({ assetSymbol, blockchainName: bn }) => (
                            assetSymbol === symbol
                            && (this.isDeFi ? bn === this.activeBlockchain : true)
                        ),
                    );
                    if (currentBalances.length === 0 && !this.isDeFi) {
                        result.push({
                            id,
                            assetSymbol: symbol,
                            assetType: type,
                            assetPrecision: Math.log10(multiplier),
                            placementName,
                            placementId,
                            blockchainName,
                            total: 0,
                            free: 0,
                            hold: 0,
                            quotation: 0,
                        });
                    } else {
                        currentBalances.forEach((balance) => {
                            result.push(balance);
                        });
                    }
                },
            );
            return result;
        },
        activeBalances() {
            const activeTypes = this.assetTypes
                .filter(({ value }) => value)
                .flatMap(({ name }) => {
                    switch (name) {
                        case 'All': {
                            return [assetsTypes.FIAT, assetsTypes.CRYPTO_SPOT, assetsTypes.STABLE_COIN];
                        }
                        case 'Fiat': {
                            return [assetsTypes.FIAT];
                        }
                        case 'Stablecoin': {
                            return [assetsTypes.STABLE_COIN];
                        }
                        case 'Crypto': {
                            return [assetsTypes.CRYPTO_SPOT];
                        }
                        default: {
                            return '';
                        }
                    }
                });

            return this.balances
                .filter(({ assetSymbol, assetType, total }) => assetSymbol
                    && activeTypes.includes(assetType)
                    && (!this.filterEmptyBalances || total > 0)
                    && (this.search === '' ? true : assetSymbol.toLowerCase().indexOf(this.search.toLowerCase()) !== -1))
                .sort((
                    {
                        total: a,
                        quotationAssetSymbols: quotationAssetSymbolsA,
                        quotations: quotationsA,
                    },
                    {
                        total: b,
                        quotationAssetSymbols: quotationAssetSymbolsB,
                        quotations: quotationsB,
                    },
                ) => {
                    const quotationIndexA = quotationAssetSymbolsA ? quotationAssetSymbolsA.findIndex((q) => q === this.quotationAssetSymbol) : -1;
                    let rateA = 0;
                    if (quotationIndexA !== -1 && quotationsA !== undefined) {
                        rateA = quotationsA[quotationIndexA];
                    }

                    const quotationIndexB = quotationAssetSymbolsB ? quotationAssetSymbolsB.findIndex((q) => q === this.quotationAssetSymbol) : -1;
                    let rateB = 0;
                    if (quotationIndexB !== -1 && quotationsB !== undefined) {
                        rateB = quotationsB[quotationIndexB];
                    }

                    return Math.abs((b * rateB)) - Math.abs((a * rateA));
                });
        },
        hasAnyBalance() {
            return this.balances.filter(({ total }) => total > 0).length > 0;
        },
        defiTotalScore() {
            const { balances } = this.$store.state.Balances;
            const activeAccountId = this.$store.getters['Accounts/activeAccountID'];
            return balances.reduce((previous, current) => {
                if (current.accountId === activeAccountId && current.placementName === 'Single Broker') {
                    let rate = 0;
                    const quotationIndex = current.quotationAssetSymbols
                        ? current.quotationAssetSymbols.findIndex((q) => q === this.quotationAssetSymbol)
                        : -1;
                    if (quotationIndex !== -1) {
                        rate = current.quotations
                            ? current.quotations[quotationIndex]
                            : 0;
                    }
                    return previous + (current.total * rate);
                }
                return previous;
            }, 0);
        },
        earnTotalScore() {
            const { balances } = this.$store.state.Balances;
            const activeAccountId = this.$store.getters['Accounts/activeAccountID'];
            return balances
                .filter((b) => b.accountId === activeAccountId && b.placementName === 'Binance')
                .reduce((previous, current) => previous + current.total * current.quotation, 0);
        },

        currenciesTypes() {
            return [
                {
                    name: 'All currencies',
                    type: null,
                },
                ...Object.values(assetsTypes).map((type) => ({
                    name: this.$store.getters.localization.Body.Terminal.AccountPortfolio.BalancesTypes[type].Name,
                    type,
                })),
            ];
        },

        balancePrecision() {
            const quotationAssetSymbol = this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL'];
            return calculatePrecision(quotationAssetSymbol);
        },
    },
    methods: {
        getRewardAssetsQuotations() {
            this.quotationsMap = new Map();
            this.positionsRewards.forEach(async ({ rewardAsset }) => {
                if (!this.quotationsMap.has(rewardAsset)) {
                    try {
                        const { data: quotation } = await PublicDataApi.publicGetAssetQuotation(new AssetQuotationRequest({
                            quotationSymbol: this.quotationAssetSymbol,
                            symbol: rewardAsset,
                        }));
                        this.quotationsMap.set(rewardAsset, quotation.rate);
                    } catch (error) {
                        if (error instanceof ApiError) {
                            await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting asset quotation');
                        }
                    }
                }
            });
        },
        async setPlacement(placementId) {
            this.activeAccountPortfolioWorkspacePlacementId = placementId;
            await this.$store.dispatch(setAccountPortfolioActivePlacementId(placementId));
        },
        toggleAll(column) {
            let id;
            switch (column) {
                case 'Fiats': {
                    this.toggledFiats = false;
                    this.toggledCrypto = true;
                    this.toggledStablecoin = true;
                    // document.getElementById('Crypto')?.classList.add('toggled');
                    // document.getElementById('Stablecoin')?.classList.add('toggled');
                    id = 'Fiats';
                    break;
                }
                case 'Crypto': {
                    this.toggledFiats = true;
                    this.toggledCrypto = false;
                    this.toggledStablecoin = true;
                    // document.getElementById('Fiats')?.classList.add('toggled');
                    // document.getElementById('Stablecoin')?.classList.add('toggled');
                    id = 'Crypto';
                    break;
                }
                case 'Stablecoin': {
                    this.toggledFiats = true;
                    this.toggledCrypto = true;
                    this.toggledStablecoin = false;
                    // document.getElementById('Crypto')?.classList.add('toggled');
                    // document.getElementById('Fiats')?.classList.add('toggled');
                    id = 'Stablecoin';
                    break;
                }
            }
            document.querySelectorAll('.adaptive--tablet-landscape-up').forEach((div) => {
                div.classList.remove('solo');
            });
            document.getElementById(id).querySelector('.adaptive--tablet-landscape-up').classList.add('solo');
        },
        handleCheckbox(name, value) {
            if (name === 'All') {
                if (!value) {
                    this.assetTypes.map((el) => {
                        el.value = true;
                        return el;
                    });
                    return;
                }
                this.assetTypes.map((el) => {
                    if (el.name === name) {
                        el.value = !el.value;
                    }
                    return el;
                });
                return;
            }
            this.assetTypes.map((el) => {
                if (el.name === name) {
                    el.value = !el.value;
                }
                return el;
            });
            if (this.assetTypes.some(({ name: n, value: v }) => n !== 'All' && !v)) {
                this.assetTypes.map((el) => {
                    if (el.name === 'All') {
                        el.value = false;
                    }
                    return el;
                });
            } else {
                this.assetTypes.map((el) => {
                    if (el.name === 'All') {
                        el.value = true;
                    }
                    return el;
                });
            }
        },
        onWheel(e) {
            changeScroll(e, this.$refs.grid);
            this.hideModal();
        },
        hideModal() {
            (this.$refs.balance ? this.$refs.balance : []).forEach((b) => {
                b.hideModal();
            });
        },
    },
    async mounted() {
        if (this.isDeFi) {
            await this.setPlacement(1);
        }
        if (this.isEarn) {
            await this.setPlacement(2);
            await this.getRewardAssetsQuotations();
        }
        if (this.activeAccountId) {
            await this.$store.dispatch(getQuickBalancesPositions(undefined));
        }
    },
    watch: {
        async activeAccountId(_, oldVal) {
            if (!oldVal) {
                await this.$store.dispatch(getQuickBalancesPositions(undefined));
            }
        },
        async positionsRewards() {
            await this.getRewardAssetsQuotations();
        },
        hasAnyBalance(val) {
            if (!val) {
                this.filterEmptyBalances = false;
            }
        },
        accountPortfolioActivePlacementId(val) {
            if (this.isDeFi && val !== 1) {
                this.setPlacement(1);
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.module {
    background: var(--cl-white);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 0 10px var(--cl-expand);
    & .w100 {
        width: 100%;
    }
    & .w30 {
        width: 30%;
    }
    & .w70 {
        width: 70%;
        padding-right: var(--m-m);
    }
    & .subheaderContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 12px;
        & .checkboxesContainer {
            display: flex;
            align-items: center;
            column-gap: 12px;
        }
    }
    & .contentContainer {
        display: flex;
        width: 100%;
        max-height: calc(100% - 48px);
        min-height: calc(100% - 48px);
    }
    & .moduleContent {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-grow: 1;
        max-height: calc(100% - 35px);
        & .balancesGrid {
            display: grid;
            grid-auto-flow: column;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            grid-column-gap: var(--m-xxl);
            grid-row-gap: var(--m-s);
            padding: 0 var(--m-m);
            overflow-x: scroll;
            overflow-y: hidden;
            min-height: 100%;
            max-height: 100%;
            &.noScrollBar {
                scrollbar-width: none;
            }
        }
    }
    & .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 35px;
        border-bottom: 1px solid var(--main-border-color);
        padding: 18.5px var(--m-m);
        z-index: 2;
        & .group {
            display: flex;
            align-items: center;
            column-gap: var(--m-m);

            & .bottom {
                margin-bottom: -2px;
            }
        }
    }
    & .underlined {
        text-decoration: underline;
    }
    & .numberText {
        font-weight: var(--fw-extrabold);
        font-size: var(--fs-m);
    }
    & .fractionText {
        font-weight: var(--fw-extrabold);
        font-size: var(--fs-s);
    }
}
</style>
