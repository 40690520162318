import { Action } from 'vuex';

import { actionCreator, mutationCreator } from 'Store/utils';
import AccountsApi from 'Apis/Accounts';
import TotalBalancesRequest from 'Entities/privatePresenter/TotalBalancesRequest';
import TotalBalance, { ITotalBalance } from 'Entities/privatePresenter/TotalBalance';

const state = {
    totalBalances: [] as TotalBalance[],
};

export type MarginState = typeof state;

export enum MarginMutations {
    SET_TOTAL_BALANCES = 'SET_TOTAL_BALANCES',
}

export const SET_TOTAL_BALANCES = mutationCreator<TotalBalance[]>('Margin', MarginMutations.SET_TOTAL_BALANCES);

const mutations: Record<MarginMutations, (state: MarginState, ...args: any[]) => void> = {
    SET_TOTAL_BALANCES(state, { payload: totalBalances }: ReturnType<typeof SET_TOTAL_BALANCES>) {
        state.totalBalances = totalBalances;
    },
};

export enum MarginActions {
    getTotalBalances = 'getTotalBalances',
    updateTotalBalancesBySocket = 'updateTotalBalancesBySocket',
}

export const getTotalBalances = actionCreator<undefined>('Margin', MarginActions.getTotalBalances);
export const updateTotalBalancesBySocket = actionCreator<ITotalBalance>('Margin', MarginActions.updateTotalBalancesBySocket);

const actions: Record<MarginActions, (Action<MarginState, any>)> = {
    async getTotalBalances({ rootState, dispatch, commit }) {
        try {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch(getTotalBalances(undefined, true));
                },
            }, { root: true });

            const accountId = rootState.Accounts.activeAccountID;
            if (!accountId) {
                return;
            }

            const { data: totalBalances } = await AccountsApi.privateGetTotalBalances(new TotalBalancesRequest({
                accountId,
            }));
            commit(SET_TOTAL_BALANCES(totalBalances, true));
        } catch {
            // api error
        }
    },
    updateTotalBalancesBySocket({ state, commit }, { payload: balance }: ReturnType<typeof updateTotalBalancesBySocket>) {
        const tempArray = [...state.totalBalances];
        const index = tempArray.findIndex(({ placementGroupTag }) => placementGroupTag === balance.placementGroupTag);
        if (index !== -1) {
            tempArray.splice(index, 1, new TotalBalance(balance));
        }
        commit(SET_TOTAL_BALANCES(tempArray, true));
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
