
import { mapGetters } from 'vuex';
import mixins from 'vue-typed-mixins';

import ModuleBlocker from 'UI/ModuleBlocker.vue';
import theme from 'Theme';
import TradingData from 'Modules/Trading/Trading.Data.vue';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import { OrdersTypes, OrdersSides, FuturesOrderSides } from 'Models/trading';
import { getPlacementThemeColors } from 'Config/placementsManifest';
import workspaceModule from 'Mixins/workspaceModule';
import Dropdown from 'Control/Dropdown.vue';
import SwitchControl from 'Control/Switch.vue';
import MarginLimit from 'Modules/Trading/components/SpotMargin/MarginLimit.vue';
import MarginMarket from 'Modules/Trading/components/SpotMargin/MarginMarket.vue';

import FuturesCryptoLimit from './components/Futures/FuturesCryptoLimit.vue';
import FuturesSpotMarket from './components/Futures/FuturesSpotMarket.vue';
import FuturesStopLimit from './components/Futures/FuturesStopLimit.vue';
import FuturesStopMarket from './components/Futures/FuturesStopMarket.vue';
import StopMarket from './components/StopMarket.vue';
import StopLimit from './components/StopLimit.vue';
import SpotMarket from './components/SpotMarket.vue';
import CryptoLimit from './components/CryptoLimit.vue';

export default mixins(TradingData).extend<any, any, any, any>({
    name: 'Trading',
    mixins: [workspaceModule, TradingData],
    components: {
        MarginMarket,
        MarginLimit,
        SwitchControl,
        FuturesStopMarket,
        FuturesStopLimit,
        CryptoLimit,
        SpotMarket,
        StopLimit,
        StopMarket,
        FuturesCryptoLimit,
        FuturesSpotMarket,
        AccountColorMarker,
        ModuleBlocker,
        Dropdown,
    },
    props: {
        isModal: {
            type: Boolean,
            default: false,
        },
        placement: {
            type: String,
            required: true,
        },
        pair: {
            type: String,
            required: true,
        },
        uniqueId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            OrdersTypes,
            OrdersSides,
            FuturesOrderSides,
            currentExpiryLabel: 'Good Till Cancelled',
            getPlacementThemeColors,
            changePairListenerId: null,
            changePlacementListenerId: null,
            theme,
            dropdownActiveIndex: 0,
            presetedLeverage: 1,
            presetedQuantity: 0,
        };
    },
    computed: {
        ...mapGetters({
            activeAccount: 'Accounts/activeAccount',
            activeAccountId: 'Accounts/activeAccountID',
            isThemeDark: 'isThemeDark',
            activePlacementName: 'Placements/activeTerminalPlacementName',
        }),
        maxLeverage() {
            const leverages = this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair)?.placementLeverages;
            if (!leverages) {
                return null;
            }
            return Math.max(...leverages.map((l) => Number(l.level)).sort((a, b) => a - b));
        },
        feesRate() {
            return this.personalFeesRate;
        },
        baseAssetSymbol() {
            return this.pair.split('/')[0];
        },
        activePlacement() {
            return this.$store.state.Placements.placements.find(({ name }) => name === this.placement);
        },
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        currentExpiryIndex() {
            return this.expiries.findIndex(({ label }) => label === this.currentExpiryLabel);
        },
        hasEnabledOrderType() {
            return this.getOrdersTypes.some(({ disabled }) => !disabled);
        },
        hasAccountAccess() {
            return this.$store.getters['Accounts/hasActiveAccountPolicy']('trading');
        },
        isActivePlacementLinked() {
            return this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](this.placement);
        },
        isPlacementTradingBlocked() {
            return false;
        },
        isTradingBlocked() {
            return !this.hasAccountAccess || this.isPlacementTradingBlocked;
        },

        isCurrentPlacementFutures() {
            const activePlacementName = this.placement;
            if (!activePlacementName) {
                return false;
            }
            return activePlacementName.toLowerCase().indexOf('futures') !== -1;
        },
    },
    methods: {
        activeOrderSide(value) {
            if (this.isCurrentPlacementFutures) {
                return this.$store.getters.localization.Body.Terminal.Trading.FuturesOrderSides[value].toUpperCase();
            }
            return this.$store.getters.localization.Body.Terminal.Trading.OrderSides[value].toUpperCase();
        },
        setActiveOrderType({ el, index }) {
            if (el.disabled) {
                return;
            }
            this.activeOrderTypeIndex = index;
            if (this.getExpiries[0]) {
                this.setExpiry({ item: this.getExpiries[0].label, index: 0 });
            }
        },
        setExpiry({ item, index }) {
            this.currentExpiryLabel = item;
            this.dropdownActiveIndex = index;
        },
    },
    watch: {
        placement() {
            this.activeOrderTypeIndex = 0;
        },
        pair() {
            this.activeOrderTypeIndex = 0;
        },
    },
});
