// This file was autogenerated. Please do not change.
export interface IBorrow {
    accountId: string;
    amount: string;
    assetSymbol: string;
    balanceId: string;
    cost: string;
    createdAt: string;
    hourlyRate: string;
    interestBearingSize: string;
    placementName: string;
    placementTag: string;
}

export default class Borrow {
    readonly _accountId: string;

    /**
     * Example: A5Q98WZ7PDX
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _amount: number;

    /**
     * Example: 0.01
     */
    get amount(): number {
        return this._amount;
    }

    readonly _assetSymbol: string;

    /**
     * Example: BTC
     */
    get assetSymbol(): string {
        return this._assetSymbol;
    }

    readonly _balanceId: string;

    /**
     * Example: BL5LP5UP9VW
     */
    get balanceId(): string {
        return this._balanceId;
    }

    readonly _cost: number;

    /**
     * Example: 0.01
     */
    get cost(): number {
        return this._cost;
    }

    readonly _createdAt: string;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get createdAt(): string {
        return this._createdAt;
    }

    readonly _hourlyRate: number;

    /**
     * Example: 0.01
     */
    get hourlyRate(): number {
        return this._hourlyRate;
    }

    readonly _interestBearingSize: number;

    /**
     * Example: 0.01
     */
    get interestBearingSize(): number {
        return this._interestBearingSize;
    }

    readonly _placementName: string;

    /**
     * Example: ByBit
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _placementTag: string;

    /**
     * Example: BYBIT
     */
    get placementTag(): string {
        return this._placementTag;
    }

    constructor(props: IBorrow) {
        this._accountId = props.accountId.trim();
        this._amount = Number(props.amount);
        this._assetSymbol = props.assetSymbol.trim();
        this._balanceId = props.balanceId.trim();
        this._cost = Number(props.cost);
        this._createdAt = props.createdAt.trim();
        this._hourlyRate = Number(props.hourlyRate);
        this._interestBearingSize = Number(props.interestBearingSize);
        this._placementName = props.placementName.trim();
        this._placementTag = props.placementTag.trim();
    }

    serialize(): IBorrow {
        const data: IBorrow = {
            accountId: this._accountId,
            amount: String(this._amount),
            assetSymbol: this._assetSymbol,
            balanceId: this._balanceId,
            cost: String(this._cost),
            createdAt: this._createdAt,
            hourlyRate: String(this._hourlyRate),
            interestBearingSize: String(this._interestBearingSize),
            placementName: this._placementName,
            placementTag: this._placementTag,
        };
        return data;
    }

    toJSON(): IBorrow {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            amount: true,
            assetSymbol: typeof this._assetSymbol === 'string' && !!this._assetSymbol.trim(),
            balanceId: typeof this._balanceId === 'string' && !!this._balanceId.trim(),
            cost: true,
            createdAt: typeof this._createdAt === 'string' && !!this._createdAt.trim(),
            hourlyRate: true,
            interestBearingSize: true,
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            placementTag: typeof this._placementTag === 'string' && !!this._placementTag.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
