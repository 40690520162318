// This file was autogenerated. Please do not change.
/* User payload */
export interface IUserPayload {
    email: string;
    firstName: string;
    inviteCode: string;
    lastName?: string;
    password: string;
    recaptchaToken: string;
    type: ('Individual' | 'Corporate' | 'Subuser');
}

export default class UserPayload {
    readonly _email: string;

    /** Description: User's email */
    get email(): string {
        return this._email;
    }

    readonly _firstName: string;

    /** Description: User's first name */
    get firstName(): string {
        return this._firstName;
    }

    readonly _inviteCode: string;

    /** Description: Invite code */
    get inviteCode(): string {
        return this._inviteCode;
    }

    readonly _lastName: string | undefined;

    /** Description: User's last name */
    get lastName(): string | undefined {
        return this._lastName;
    }

    readonly _password: string;

    /** Description: User's password */
    get password(): string {
        return this._password;
    }

    readonly _recaptchaToken: string;

    /** Description: Recaptcha token */
    get recaptchaToken(): string {
        return this._recaptchaToken;
    }

    readonly _type: ('Individual' | 'Corporate' | 'Subuser');

    /** Description: User type */
    get type(): ('Individual' | 'Corporate' | 'Subuser') {
        return this._type;
    }

    constructor(props: IUserPayload) {
        this._email = props.email.trim();
        this._firstName = props.firstName.trim();
        this._inviteCode = props.inviteCode.trim();
        if (typeof props.lastName === 'string') {
            this._lastName = props.lastName.trim();
        }
        this._password = props.password.trim();
        this._recaptchaToken = props.recaptchaToken.trim();
        this._type = props.type;
    }

    serialize(): IUserPayload {
        const data: IUserPayload = {
            email: this._email,
            firstName: this._firstName,
            inviteCode: this._inviteCode,
            password: this._password,
            recaptchaToken: this._recaptchaToken,
            type: this._type,
        };
        if (typeof this._lastName !== 'undefined') {
            data.lastName = this._lastName;
        }
        return data;
    }

    toJSON(): IUserPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            email: typeof this._email === 'string' && !!this._email.trim(),
            firstName: typeof this._firstName === 'string' && !!this._firstName.trim(),
            lastName: !this._lastName ? true : typeof this._lastName === 'string',
            password: typeof this._password === 'string' && !!this._password.trim(),
            recaptchaToken: typeof this._recaptchaToken === 'string' && !!this._recaptchaToken.trim(),
            inviteCode: typeof this._inviteCode === 'string' && !!this._inviteCode.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
