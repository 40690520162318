// This file was autogenerated. Please do not change.
export interface IBorrowQuota {
    maxAmount?: string;
    maxQuantity?: string;
}

export default class BorrowQuota {
    readonly _maxAmount: number | undefined;

    /**
     * Example: 5
     */
    get maxAmount(): number | undefined {
        return this._maxAmount;
    }

    readonly _maxQuantity: number | undefined;

    /**
     * Example: 50
     */
    get maxQuantity(): number | undefined {
        return this._maxQuantity;
    }

    constructor(props: IBorrowQuota) {
        if (typeof props.maxAmount === 'string') {
            this._maxAmount = Number(props.maxAmount);
        }
        if (typeof props.maxQuantity === 'string') {
            this._maxQuantity = Number(props.maxQuantity);
        }
    }

    serialize(): IBorrowQuota {
        const data: IBorrowQuota = {
        };
        if (typeof this._maxAmount !== 'undefined') {
            data.maxAmount = String(this._maxAmount);
        }
        if (typeof this._maxQuantity !== 'undefined') {
            data.maxQuantity = String(this._maxQuantity);
        }
        return data;
    }

    toJSON(): IBorrowQuota {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            maxAmount: true,
            maxQuantity: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
