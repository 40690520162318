
import { maxValue, minValue, required } from 'vuelidate/dist/validators.min';
import { mapGetters } from 'vuex';
import mixins from 'vue-typed-mixins';

import { getManifest, hasManifest } from 'Models/assetsManifest';
import MfaDisabled from 'Common/MfaDisabled.vue';
import Balance from 'Entities/privatePresenter/Balance';
import numberFormater from 'Mixins/numberFormater';
import Button from 'Control/Button.vue';
import TransferData, { TransferTypes, TransferUI } from 'Modules/Transfer/Transfer.Data.vue';
import Asset from 'Entities/publicPresenter/Asset';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import BlockHeader from 'UI/BlockHeader.vue';
import AssetsBlockchainsDropdown from 'Control/AssetsBlockchainsDropdown.vue';
import ModuleBlocker from 'UI/ModuleBlocker.vue';
import HeaderSwitcher from 'Control/HeaderSwitcher.vue';
import Icon from 'UI/Icon.vue';
import MoneyInput from 'Control/MoneyInput.vue';

import PlacementBalance from './PlacementBalance.vue';
import Accounts from './Accounts.vue';
import Exchanges from './Exchanges.vue';

interface Data {
    isTransferButtonDisabled: boolean;
    isFeeInvalid: boolean;
    transferTypes: typeof TransferTypes;
    transferTypesValues: TransferTypes[];
    quantityButtons: {
        title: string,
        callback: () => void;
    }[];
    feeSize: 'low' | 'medium' | 'high' | undefined;
    showTooltip: boolean;
    FROM_OKEX_MULTIPLIER: number;
    COMMON_MULTIPLIER: number;
}

interface Methods {
    setQuantity: (value: string) => void;
    setMinSize: () => void;
    setActivePage: (data: number) => void;
    setQuantityPartOfFree: (factor: number) => void;
    transfer: () => void;
    setToExchange: (data: any) => void;
    setFromExchange: (data: any) => void;
    setToAccount: (data: any) => void;
    setFromAccount: (data: any) => void;
    onSelectAsset: (data: number) => void;
    onSelectBlockchain: (data: number) => void;
    openModal: () => void;
    toggleDemoAccountAlert: () => void;
}

interface Computed {
    dropPrimaryShadow: any;
    currenciesList: any;
    isMFADisabled: any;
    quantity: any;
    activeCurrencyIndex: any;
    activeCurrency: any;
    customValidationErrors: any;
    hasAccountAccess: any;
    activeAccountId: any;
    activeBlockchainIndex: number;
    activeBlockchain: string;
    fromExchange: string;
    toExchange: string;
    fromAccount: string;
    toAccount: string;
    currentFromBalance: Balance | null;
    currentToBalance: Balance | null;
    currentPage: TransferTypes;
    disabledQuantityButtonsIndexes: number[];
    blockchainsList: any;
    isBlockchainNeeded: boolean;
    transferMinSize: number;
    currentUser: InternalUserResponse | undefined;
    isKycVerified: boolean;
    quantityPrecision: number;
    quantityPrecisionMultiplier: number;
    arePlacementsAllowed: boolean;
    fromBalances: Balance[];
    toBalances: Balance[];
    quantityErrorText: string;
    disabledCurrenciesIndexes: number[];
    disabledBlockchainsIndexes: number[];
    isTransferAllowed: boolean;
    transferRestrictionNotes: string;
}

export default mixins(TransferData).extend<Data, Methods, Computed>({
    name: 'Transfer',
    components: {
        Icon,
        HeaderSwitcher,
        Exchanges,
        Accounts,
        PlacementBalance,
        MfaDisabled,
        BlockHeader,
        AssetsBlockchainsDropdown,
        ModuleBlocker,
        Button,
        MoneyInput,
    },
    mixins: [numberFormater, TransferData],
    data() {
        return {
            console,
            hasManifest,
            getManifest,
            FROM_OKEX_MULTIPLIER: 1000,
            COMMON_MULTIPLIER: 100000000,
            transferTypes: TransferTypes,
            transferTypesValues: [TransferTypes.Exchanges, TransferTypes.Accounts],
            quantityButtons: [{
                title: 'Min',
                callback: () => (this as any).setMinSize(),
            }, {
                title: '25%',
                callback: () => (this as any).setQuantityPartOfFree(0.25),
            }, {
                title: '50%',
                callback: () => (this as any).setQuantityPartOfFree(0.5),
            }, {
                title: '75%',
                callback: () => (this as any).setQuantityPartOfFree(0.75),
            }, {
                title: 'Max',
                callback: () => (this as any).setQuantityPartOfFree(1),
            }],
            feeSize: undefined,
            isTransferButtonDisabled: false,
            isFeeInvalid: true,
            showTooltip: true,
        };
    },
    computed: {
        ...mapGetters({
            dropPrimaryShadow: 'Transactions/backLightWalletsTransferModuleActive',
            isMFADisabled: 'Auth/isMFADisabled',
        }),
        transferRestrictionNotes() {
            if (!this.activeCurrency) {
                return '';
            }

            const detailsArray = [...this.activeCurrency.transferDetails]
                .sort(({ blockchainName: a }, { blockchainName: b }) => {
                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                });
            return detailsArray && detailsArray[this.activeBlockchainIndex]
                ? detailsArray[this.activeBlockchainIndex].notes ?? ''
                : '';
        },
        isTransferAllowed() {
            return !this.disabledCurrenciesIndexes.includes(this.activeCurrencyIndex)
                && !this.disabledBlockchainsIndexes.includes(this.activeBlockchainIndex);
        },
        disabledCurrenciesIndexes() {
            return this.currenciesList.reduce((acc, current, index) => {
                const isAvailable = current.transferDetails.some(({ active }) => active);
                if (!isAvailable) {
                    acc.push(index);
                }
                return acc;
            }, []);
        },
        disabledBlockchainsIndexes() {
            if (!this.activeCurrency) {
                return [];
            }

            const detailsArray = [...this.activeCurrency.transferDetails]
                .sort(({ blockchainName: a }, { blockchainName: b }) => {
                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                });
            return detailsArray.reduce((acc, { active }, index) => {
                if (!active) {
                    acc.push(index);
                }
                return acc;
            }, []);
        },
        quantityErrorText() {
            if (!this.$v.quantity.minValue) {
                return `Quantity must be greater than ${Number(this.transferMinSize.toFixed(this.quantityPrecision)).noExponents()}`;
            }
            if (!this.$v.quantity.maxValue) {
                return this.currentFromBalance && Number(this.currentFromBalance.free) > 0 ? `Quantity must be lower than ${this.currentFromBalance.free.noExponents()}` : 'Not enough free funds';
            }
            return '';
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        transferMinSize() {
            return this.GET_TRANSFER_MIN_SIZE;
        },
        isBlockchainNeeded() {
            return this.GET_BLOCKCHAIN_NEEDED;
        },
        activeAccountId() {
            return this.$store.state.Accounts.activeAccountID;
        },
        currenciesList() {
            return this.GET_ASSETS;
        },
        blockchainsList() {
            return this.GET_BLOCKCHAINS;
        },
        quantity: {
            get() {
                return Number(this.ui.quantity).toFixed(this.quantityPrecision).noExponents();
            },
            set(value) {
                this.SET_QUANTITY(Number(value).toFixed(this.quantityPrecision).noExponents());
            },
        },
        activeCurrencyIndex: {
            get() {
                return this.ui.currentAssetIndex;
            },
            set(assetIndex) {
                this.SET_ACTIVE_ASSET({ symbol: String(this.currenciesList[assetIndex]?.symbol), index: assetIndex });
            },
        },
        activeBlockchainIndex: {
            get() {
                return this.ui.currentBlockchainIndex;
            },
            set(index) {
                this.SET_ACTIVE_BLOCKCHAIN(index);
            },
        },
        activeBlockchain() {
            return this.ui.blockchain;
        },
        activeCurrency() {
            return this.currenciesList[this.activeCurrencyIndex];
        },
        customValidationErrors() {
            return {
                maxValue: 'Not enough funds to perform transfer',
            };
        },
        hasAccountAccess() {
            return this.$store.getters['Accounts/hasActiveAccountPolicy']('allocation');
        },
        fromExchange() {
            return this.ui.fromExchange.id;
        },
        toExchange() {
            return this.ui.toExchange.id;
        },
        fromAccount() {
            return this.ui.fromAccount.id;
        },
        toAccount() {
            return this.ui.toAccount.id;
        },
        currentFromBalance() {
            if (this.fromBalances && this.activeCurrency) {
                return this.fromBalances.filter((b) => {
                    const transferDetail = this.activeCurrency.transferDetails.find(({ blockchainName }) => blockchainName === this.activeBlockchain);
                    if (transferDetail && transferDetail.relatedBalanceAsset.source) {
                        if (b.assetSymbol === transferDetail.relatedBalanceAsset.source) {
                            if (this.currentPage === 'Exchanges') {
                                if (this.fromExchange !== 'Single Broker') {
                                    return true;
                                }
                                if (b.blockchainName === this.activeBlockchain || b.assetType === 'fiat') {
                                    return true;
                                }
                            } else {
                                if (this.fromAccount !== 'Single Broker') {
                                    return true;
                                }
                                if (b.blockchainName === this.activeBlockchain || b.assetType === 'fiat') {
                                    return true;
                                }
                            }
                        }
                    } else if (b.assetSymbol === this.activeCurrency.symbol) {
                        if (this.currentPage === 'Exchanges') {
                            if (this.fromExchange !== 'Single Broker') {
                                return true;
                            }
                            if (b.blockchainName === this.activeBlockchain || b.assetType === 'fiat') {
                                return true;
                            }
                        } else {
                            if (this.fromAccount !== 'Single Broker') {
                                return true;
                            }
                            if (b.blockchainName === this.activeBlockchain || b.assetType === 'fiat') {
                                return true;
                            }
                        }
                    }
                    return false;
                })[0];
            }
            return null;
        },
        currentToBalance() {
            if (this.toBalances && this.activeCurrency) {
                return this.toBalances.filter((b) => {
                    const transferDetail = this.activeCurrency.transferDetails.find(({ blockchainName }) => blockchainName === this.activeBlockchain);
                    if (transferDetail && transferDetail.relatedBalanceAsset.destination) {
                        if (b.assetSymbol === transferDetail.relatedBalanceAsset.destination) {
                            if (this.currentPage === 'Exchanges') {
                                if (this.toExchange !== 'Single Broker') {
                                    return true;
                                }
                                if (b.blockchainName === this.activeBlockchain) {
                                    return true;
                                }
                            } else {
                                return true;
                            }
                        }
                    } else if (b.assetSymbol === this.activeCurrency.symbol) {
                        if (this.currentPage === 'Exchanges') {
                            if (this.toExchange !== 'Single Broker') {
                                return true;
                            }
                            if (b.blockchainName === this.activeBlockchain) {
                                return true;
                            }
                        } else {
                            return true;
                        }
                    }
                    return false;
                })[0];
            }
            return null;
        },
        currentPage() {
            return this.ui.transferType;
        },
        disabledQuantityButtonsIndexes() {
            const result: number[] = [];
            if (!this.currentFromBalance) {
                return [1, 2, 3, 4];
            }
            if (this.currentFromBalance!.free * 0.25 < this.transferMinSize) {
                result.push(1);
            }
            if (this.currentFromBalance!.free * 0.5 < this.transferMinSize) {
                result.push(2);
            }
            if (this.currentFromBalance!.free * 0.75 < this.transferMinSize) {
                result.push(3);
            }
            if (this.currentFromBalance!.free < this.transferMinSize) {
                result.push(4);
            }
            return result;
        },
        quantityPrecision() {
            // TODO: Позже придумать как уйти от хардкода названия биржи
            if (this.ui.transferType === this.transferTypes.Exchanges && this.ui.fromExchange.id === 'OKX') {
                return 3;
            }
            return 8;
        },
        quantityPrecisionMultiplier() {
            if (this.ui.transferType === this.transferTypes.Exchanges && this.ui.fromExchange.id === 'OKX') {
                return this.FROM_OKEX_MULTIPLIER;
            }
            return this.COMMON_MULTIPLIER;
        },
        arePlacementsAllowed() {
            if (this.ui.transferType === TransferTypes.Exchanges) {
                const statusFrom = this.$store.state.Placements.maintenanceStatuses.get(this.ui.fromExchange.id) || this.ui.fromExchange.id === 'Single Broker';
                const statusTo = this.$store.state.Placements.maintenanceStatuses.get(this.ui.toExchange.id) || this.ui.toExchange.id === 'Single Broker';
                const linkedFrom = this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](this.ui.fromExchange.id) || this.ui.fromExchange.id === 'Single Broker';
                const linkedTo = this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](this.ui.toExchange.id) || this.ui.toExchange.id === 'Single Broker';
                const pendingTo = this.$store.getters['Accounts/isPlacementPending'](this.ui.toExchange.id);
                const pendingFrom = this.$store.getters['Accounts/isPlacementPending'](this.ui.fromExchange.id);
                return statusFrom && statusTo && linkedFrom && linkedTo && !pendingFrom && !pendingTo;
            }
            const status = this.$store.state.Placements.maintenanceStatuses.get(this.ui.fromAccount.id) || this.ui.fromAccount.id === 'Single Broker';
            const linked = this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](this.ui.fromAccount.id) || this.ui.fromAccount.id === 'Single Broker';
            const pending = this.$store.getters['Accounts/isPlacementPending'](this.ui.fromAccount.id);
            return status && linked && !pending;
        },
        fromBalances() {
            return this.$store.state.Balances.balances.filter(({ accountId, placementName }) => {
                return accountId === this.activeAccountId
                    && (this.ui.transferType === 'Accounts'
                        ? placementName === this.ui.fromAccount.id
                        : placementName === this.ui.fromExchange.id
                    );
            });
        },
        toBalances() {
            return this.$store.state.Balances.balances.filter(({ accountId, placementName }) => {
                return (this.ui.transferType === 'Accounts' ? accountId === this.ui.toAccount.id : accountId === this.activeAccountId)
                    && (this.ui.transferType === 'Accounts' ? placementName === this.ui.fromAccount.id : placementName === this.ui.toExchange.id);
            });
        },
    },
    validations() {
        return {
            quantity: {
                required,
                minValue: minValue(Number(this.transferMinSize.toFixed(this.quantityPrecision))),
                maxValue: maxValue(this.currentFromBalance ? this.currentFromBalance.free : 0),
            },
        };
    },
    methods: {
        toggleDemoAccountAlert() {
            if (this.$store.getters['Accounts/isActiveAccountDemo']) {
                this.$store.state.Accounts.showDemoAccountAlert = true;
                this.$store.commit('Accounts/SET_IS_DEMO_ACCOUNT_SHAKING');
            }
        },
        setToAccount(data) {
            this.SET_TO_ACCOUNT(data);
            this.$refs.quantityInput.cleanFocusButtons();
        },
        setFromAccount(data) {
            this.SET_FROM_ACCOUNT(data);
            this.$refs.quantityInput.cleanFocusButtons();
        },
        setToExchange(data) {
            this.SET_TO_EXCHANGE(data);
            this.$refs.quantityInput.cleanFocusButtons();
        },
        setFromExchange(data) {
            this.SET_FROM_EXCHANGE(data);
            this.$refs.quantityInput.cleanFocusButtons();
        },
        setQuantity(num) {
            this.quantity = num;
        },
        setMinSize() {
            this.quantity = Math.floor(Number(this.transferMinSize.toFixed(this.quantityPrecision)) * this.quantityPrecisionMultiplier) / this.quantityPrecisionMultiplier;
        },
        setActivePage(index) {
            this.SET_UI({
                transferType: index === 0 ? TransferTypes.Exchanges : TransferTypes.Accounts,
            } as Partial<TransferUI>);
            this.quantity = 0;
            this.$refs.quantityInput.cleanFocusButtons();
        },
        setQuantityPartOfFree(factor) {
            this.quantity = Math.floor(((this.currentFromBalance ? this.currentFromBalance.free : 0) * factor) * this.quantityPrecisionMultiplier) / this.quantityPrecisionMultiplier;
        },
        transfer() {
            setTimeout(() => {
                this.showTooltip = true;
            }, 100);

            if (this.fromExchange === this.toExchange && this.currentPage === 'Exchanges') {
                return;
            }
            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.isTransferButtonDisabled = true;
                this.$router.push({
                    path: '/Wallets/Confirmations/CompleteTransfer',
                    query: {
                        ui: this.ui,
                        fromBalances: this.fromBalances.map((b) => b.serialize()),
                        assetsKeys: Array.from(this.assets?.keys()),
                        assetsValues: Array.from(this.assets?.values()).map((v) => (v as Asset).serialize()),
                        previousRoute: this.$router.currentRoute.path,
                        isBlockchainNeeded: this.isBlockchainNeeded,
                        isFiatTransfer: this.GET_IS_SELECTED_ASSET_FIAT,
                        notes: this.transferRestrictionNotes,
                    },
                });
            }
        },
        onSelectAsset(index) {
            this.activeCurrencyIndex = index;
            this.setQuantity(0);
            this.ui.lastAssetSymbol = this.currenciesList[index].symbol;
            this.$refs.quantityInput.cleanFocusButtons();
        },
        onSelectBlockchain(index) {
            this.activeBlockchainIndex = index;
            this.setQuantity(0);
            this.$refs.quantityInput.cleanFocusButtons();
        },
        openModal() {
            this.$modal.show('multitransfersModal');
        },
    },
    async created() {
        await this.init();
        document.addEventListener('click', () => {
            this.showTooltip = false;
        });
    },
    mounted() {
        this.activeCurrencyIndex = 0;
    },
    watch: {
        async fromExchange() {
            if (this.activeAccountId) {
                await this.getAssests();
            }
        },
        async toExchange() {
            if (this.activeAccountId) {
                await this.getAssests();
            }
        },
        async fromAccount() {
            if (this.activeAccountId) {
                await this.getAssests();
            }
        },
        quantity() {
            this.$v.quantity.$reset();
        },
    },
});
