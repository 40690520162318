<template>
    <form
        class="stop-limit-form"
        :class="s.mainContainer"
        @submit.prevent="placeOrder"
    >
        <div :class="[s.row, s.w100, s.h100, s.pbM]">
            <div :class="[s.column, s.w66]">
                <div :class="[s.row, s.w100]">
                    <div :class="[s.column, s.w50, s.rowGap, s.firstColumnPadding]">
                        <money-input
                            title="Quantity"
                            ref="QuantityInput"
                            placeholder="0"
                            :currency="baseAssetSymbol"
                            has-arrows
                            :buttons="quantityButtons"
                            :is-buttons-disabled="isQuantityButtonsDisabled"
                            :max-precision="activeTerminalAssetPairPrecisionAmount"
                            :step="1 / 10 ** activeTerminalAssetPairPrecisionAmount"
                            :value="quantity"
                            :disabled-buttons-indexes="disabledQuantityButtonsIndexes"
                            :show-tooltip="showTooltip"
                            :available="quantityBalanceFree"
                            :is-external-error="$v.quantity.$error"
                            :external-error-text="quantityErrorText"
                            @input="setQuantity"
                        />
                        <money-input
                            title="Total"
                            ref="TotalInput"
                            placeholder="0"
                            :currency="quoteAssetSymbol"
                            :buttons="totalButtons"
                            :max-precision="activeTerminalAssetPairPrecisionPrice"
                            :step="1 / 10 ** activeTerminalAssetPairPrecisionPrice"
                            :is-buttons-disabled="isTotalButtonsDisabled"
                            :value="total"
                            :disabled-buttons-indexes="disabledTotalButtonsIndexes"
                            :show-tooltip="showTooltip"
                            :available="totalBalanceFree"
                            :is-external-error="$v.total.$error"
                            :external-error-text="totalErrorText"
                            @input="setTotal"
                        />
                    </div>
                    <div :class="[s.column, s.w50, s.rowGap, s.secondColumnPadding]">
                        <money-input
                            title="Limit Price"
                            ref="PriceInput"
                            placeholder="0"
                            :currency="quoteAssetSymbol"
                            has-arrows
                            :buttons="priceButtons"
                            :step="1 / 10 ** pricePrecision"
                            :restrictions="priceRestriction"
                            :max-precision="pricePrecision"
                            :is-always-active="true"
                            :value="price"
                            :show-tooltip="showTooltip"
                            :is-external-error="$v.price.$error"
                            :external-error-text="priceErrorText"
                            @input="setPrice"
                        />
                        <money-input
                            title="Stop Price"
                            ref="StopPriceInput"
                            placeholder="0"
                            :currency="quoteAssetSymbol"
                            has-arrows
                            :value="triggerPrice"
                            :buttons="stopPriceButtons"
                            :show-tooltip="showTooltip"
                            :restrictions="priceRestriction"
                            :max-precision="pricePrecision"
                            :is-external-error="$v.triggerPrice.$error"
                            :external-error-text="stopPriceErrorText"
                            @input="setTriggerPrice"
                        />
                    </div>
                </div>
            </div>
            <div :class="[s.column, s.w33, s.justifyContentBetween, s.prS]">
                <OrderValueBlock
                    :exchange="currentPlacement ? currentPlacement.name : ''"
                    :placement="placement"
                    :pair="baseAssetSymbol && quoteAssetSymbol ? `${baseAssetSymbol}/${quoteAssetSymbol}` : ''"
                    :fee="newCommission && newCommission > 0 ? newCommission : 0"
                    :quoted-fee="quotedCommission && quotedCommission > 0 ? quotedCommission : 0"
                    :side="activeSide.value"
                    :order-value="orderValue"
                    :fee-asset="commissionAsset"
                    :fees-rate="storeData.feesRate"
                    :quantity-precision="quantityPrecision"
                    order-type="limit stop"
                />
                <div v-if="!isModal" :class="[s.row, s.w100]">
                    <Button
                        :button-disabled="!isKycVerified"
                        :class="s.multiaccountButton"
                        @click="openMultiaccountOrderModal"
                        button-type="primary"
                    >
                        <template>
                            <Icon icon="multiaccount_order" />
                        </template>
                    </Button>
                    <Button
                        is-submit
                        wide
                        :button-disabled="disabledSubmitAction || !isKycVerified"
                        button-type="primary"
                        @mousedown="toggleDemoAccountAlert"
                    >
                        <template>
                            {{ `${$store.getters.localization.Body.Terminal.Trading.OrderSides[activeSide.field]} ${baseAssetSymbol} stop` }}
                        </template>
                    </Button>
                </div>
            </div>
        </div>
        <div v-if="isModal" :class="[s.row, s.w100, s.modalContainer]">
            <div :class="[s.w100, s.mtMedium]">
                <div
                    v-for="(account, index) in accountsList"
                    :key="account.name"
                >
                    <span
                        v-if="index === 0"
                        :class="s.accountsListHeaderText"
                    >
                        Owned Accounts
                    </span>
                    <span
                        v-if="index === firstManagedAccountIndex"
                        :class="s.accountsListHeaderText"
                    >
                        Managed Accounts
                    </span>
                    <div :class="[s.accountsListContainer, s.mainAccountsGrid]">
                        <div>
                            <div :class="[s.row, s.alignCenter, s.smallGap]">
                                <Checkbox
                                    :value="multiaccountOrderFormData.selectedAccountsIndexes[index]"
                                    @state-changed="handleAccountCheckbox(index)"
                                />
                                <span
                                    :class="s.tag"
                                >
                                    <AccountColorMarker
                                        :color="getAccountColor(account)"
                                    />
                                    #{{ account.name }}
                                </span>
                            </div>
                        </div>
                        <div>
                            <div :class="s.balancesGrid">
                                <div :class="s.mlSmall">
                                    <p :class="theme.placeorder.multiallountSubheaderText">Total Balance</p>
                                    <AssetValue
                                        :value="((account.totalQuotations[quotationAssetSymbol] - account.holdQuotations[quotationAssetSymbol]) || 0).floor(quotationAssetPrecision)"
                                        :asset="quotationAssetSymbol"
                                        :need-to-round="false"
                                        :value-class="theme.placeorder.multiaccountValueText"
                                    />
                                </div>
                                <div :class="s.mlSmall">
                                    <p :class="theme.placeorder.multiallountSubheaderText">Available {{ baseAssetSymbol }}</p>
                                    <AssetValue
                                        :value="Number(accountFreeBase(account.id).floor(8)).noExponents()"
                                        :asset="baseAssetSymbol"
                                        :need-to-round="false"
                                        :value-class="theme.placeorder.multiaccountValueText"
                                    />
                                </div>
                                <div :class="s.mlSmall">
                                    <p :class="theme.placeorder.multiallountSubheaderText">Available {{ quoteAssetSymbol }}</p>
                                    <AssetValue
                                        :value="Number(accountFreeQuote(account.id).floor(8)).noExponents()"
                                        :asset="quoteAssetSymbol"
                                        :need-to-round="false"
                                        :value-class="theme.placeorder.multiaccountValueText"
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div :class="[s.row, s.toggleBarsContainer]">
                                <SwitchControl
                                    :value="multiaccountOrderFormData.selectedAccountsSumTypes[index]"
                                    :disabled="!multiaccountOrderFormData.selectedAccountsIndexes[index]"
                                    :class="s.prXxxl"
                                    @change="changeSwitchValue(index)"
                                    with-text="Σ"
                                    off-text="%"
                                />
                                <Checkbox
                                    :value="multiaccountOrderFormData.selectedAccountsIsEditing[index]"
                                    @state-changed="handleEditCheckbox(index)"
                                    :disabled="!multiaccountOrderFormData.selectedAccountsIndexes[index]"
                                    :label-class="s.editText"
                                >
                                    <template>
                                        Edit
                                    </template>
                                </Checkbox>
                            </div>
                        </div>
                        <div v-if="multiaccountOrderFormData.selectedAccountsIndexes[index]" :class="s.additionalGridCell">
                            <div v-if="!multiaccountOrderFormData.selectedAccountsIsEditing[index]" :class="[s.row, s.w100, s.justifyContentBetween]">
                                <div :class="[s.column, s.w33, s.paddingX, s.rowGap]">
                                    <money-input
                                        title="Quantity"
                                        placeholder="0"
                                        :currency="baseAssetSymbol"
                                        :step="1 / 10 ** quantityPrecision"
                                        :restrictions="quantityRestriction"
                                        :max-precision="quantityPrecision"
                                        :value="multiaccountOrderFormData.quantities[index]"
                                        :show-tooltip="showTooltip"
                                        :is-external-error="multiaccountOrderFormData.quantityValidationErrors[index]"
                                        :external-error-text="multiaccountOrderFormData.quantityValidationErrorTexts[index]"
                                        disabled
                                        readonly
                                    />
                                    <money-input
                                        title="Total"
                                        placeholder="0"
                                        type="number"
                                        :currency="quoteAssetSymbol"
                                        :step="1 / 10 ** pricePrecision"
                                        :restrictions="totalRestriction"
                                        :max-precision="pricePrecision"
                                        :value="multiaccountOrderFormData.totals[index]"
                                        :show-tooltip="showTooltip"
                                        :is-external-error="multiaccountOrderFormData.totalValidationErrors[index]"
                                        :external-error-text="multiaccountOrderFormData.totalValidationErrorTexts[index]"
                                        disabled
                                        readonly
                                    />
                                </div>
                                <div :class="[s.column, s.w33, s.paddingX, s.rowGap, s.borderRight]">
                                    <money-input
                                        title="Price"
                                        placeholder="0"
                                        :currency="quoteAssetSymbol"
                                        :step="1 / 10 ** pricePrecision"
                                        :restrictions="priceRestriction"
                                        :max-precision="pricePrecision"
                                        :is-always-active="true"
                                        :value="multiaccountOrderFormData.prices[index]"
                                        disabled
                                        readonly
                                    />
                                    <money-input
                                        title="Trigger Price"
                                        placeholder="0"
                                        :currency="quoteAssetSymbol"
                                        :step="1 / 10 ** pricePrecision"
                                        :restrictions="priceRestriction"
                                        :max-precision="pricePrecision"
                                        :is-always-active="true"
                                        :value="multiaccountOrderFormData.stopPrices[index]"
                                        disabled
                                        readonly
                                    />
                                </div>
                                <div :class="[s.column, s.w33, s.paddingX]">
                                    <OrderValueBlock
                                        :exchange="currentPlacement ? currentPlacement.name : ''"
                                        :placement="placement"
                                        :pair="baseAssetSymbol && quoteAssetSymbol ? `${baseAssetSymbol}/${quoteAssetSymbol}` : ''"
                                        :fee="multiaccountOrderFormData.fees[index]"
                                        :quoted-fee="multiaccountOrderFormData.quotedFees[index]"
                                        :side="activeSide.value"
                                        :order-value="multiaccountOrderFormData.orderValues[index]"
                                        :fee-asset="commissionAsset"
                                        :fees-rate="storeData.feesRate"
                                        :quantity-precision="quantityPrecision"
                                        order-type="limit stop"
                                        :need-background="false"
                                    />
                                </div>
                            </div>
                            <div v-else :class="[s.row, s.w100, s.justifyContentBetween]">
                                <div :class="[s.column, s.w33, s.paddingX, s.rowGap]">
                                    <money-input
                                        title="Quantity"
                                        placeholder="0"
                                        has-arrows
                                        :currency="baseAssetSymbol"
                                        :step="1 / 10 ** quantityPrecision"
                                        :restrictions="quantityRestriction"
                                        :max-precision="quantityPrecision"
                                        :value="multiaccountOrderFormData.quantities[index]"
                                        :buttons="makeMultiaccountQuantityButtons(index)"
                                        :is-buttons-disabled="isMultiaccountQuantityButtonsDisabled(index)"
                                        :disabled-buttons-indexes="getMultiaccountDisabledQuantityButtonsIndexes(index)"
                                        :show-tooltip="showTooltip"
                                        :is-external-error="multiaccountOrderFormData.quantityValidationErrors[index]"
                                        :external-error-text="multiaccountOrderFormData.quantityValidationErrorTexts[index]"
                                        @input="setMultiaccountQuantity($event, index)"
                                    />
                                    <money-input
                                        title="Total"
                                        placeholder="0"
                                        type="number"
                                        has-arrows
                                        :currency="quoteAssetSymbol"
                                        :step="1 / 10 ** pricePrecision"
                                        :restrictions="totalRestriction"
                                        :max-precision="pricePrecision"
                                        :value="multiaccountOrderFormData.totals[index]"
                                        :buttons="makeMultiaccountTotalButtons(index)"
                                        :is-buttons-disabled="isMultiaccountTotalButtonsDisabled(index)"
                                        :disabled-buttons-indexes="getMultiaccountDisabledTotalButtonsIndexes(index)"
                                        :show-tooltip="showTooltip"
                                        :is-external-error="multiaccountOrderFormData.totalValidationErrors[index]"
                                        :external-error-text="multiaccountOrderFormData.totalValidationErrorTexts[index]"
                                        @input="setMultiaccountTotal($event, index)"
                                    />
                                </div>
                                <div :class="[s.column, s.w33, s.paddingX, s.rowGap, s.borderRight]">
                                    <money-input
                                        title="Price"
                                        placeholder="0"
                                        has-arrows
                                        :currency="quoteAssetSymbol"
                                        :step="1 / 10 ** pricePrecision"
                                        :restrictions="priceRestriction"
                                        :max-precision="pricePrecision"
                                        :is-always-active="true"
                                        :value="multiaccountOrderFormData.prices[index]"
                                        :buttons="makeMultiaccountPriceButtons(index)"
                                        @input="setMultiaccountPrice($event, index)"
                                    />
                                    <money-input
                                        title="Trigger Price"
                                        placeholder="0"
                                        has-arrows
                                        :currency="quoteAssetSymbol"
                                        :step="1 / 10 ** pricePrecision"
                                        :restrictions="priceRestriction"
                                        :max-precision="pricePrecision"
                                        :is-always-active="true"
                                        :value="multiaccountOrderFormData.stopPrices[index]"
                                        :buttons="makeMultiaccountStopPriceButtons(index)"
                                        @input="setMultiaccountStopPrice($event, index)"
                                    />
                                </div>
                                <div :class="[s.column, s.w33, s.paddingX]">
                                    <OrderValueBlock
                                        :exchange="currentPlacement ? currentPlacement.name : ''"
                                        :placement="placement"
                                        :pair="baseAssetSymbol && quoteAssetSymbol ? `${baseAssetSymbol}/${quoteAssetSymbol}` : ''"
                                        :fee="multiaccountOrderFormData.fees[index]"
                                        :quoted-fee="multiaccountOrderFormData.quotedFees[index]"
                                        :side="activeSide.value"
                                        :order-value="multiaccountOrderFormData.orderValues[index]"
                                        :fee-asset="commissionAsset"
                                        :fees-rate="storeData.feesRate"
                                        :quantity-precision="quantityPrecision"
                                        order-type="limit stop"
                                        :need-background="false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="[s.row, s.w100, s.justifyContentEnd, s.mtExtraLarge, s.buttonsContainer]">
                    <Button
                        :class="s.cancelButton"
                        @click="$modal.hide(uniqueId)"
                        button-type="info"
                    >
                        <template>
                            cancel
                        </template>
                    </Button>
                    <Button
                        @click="performMultiaccountOrder"
                        :class="s.w25"
                        button-type="primary"
                    >
                        <template>
                            {{ `place ${selectedOrdersNumber} orders` }}
                        </template>
                    </Button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/dist/validators.min';
import { mapGetters } from 'vuex';
import { nanoid } from 'nanoid';

import theme from 'Theme';
import AssetValue from 'UI/AssetValue.vue';
import Button from 'Control/Button.vue';
import {
    OrdersTypes,
    OrdersSides,
    OrderExpiries,
    RestrictionsAdapter,
    GenerateRestrictions,
    OrderRestrictionFields,
    OrderActiveFieldValues as fieldPriorities,
} from 'Models/trading';
import orderValue from 'Mixins/orderValue';
import numberFormater from 'Mixins/numberFormater';
import { SET_UI } from 'Store/v2/MultiaccountOrder';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import SwitchControl from 'Control/Switch.vue';
import Checkbox from 'Control/Checkbox.vue';
import Icon from 'UI/Icon.vue';
import MoneyInput from 'Control/MoneyInput.vue';
import TradingApi from 'Apis/Trading';
import PlaceOrderRequest from 'Lib/entities/orderRegistrator/PlaceOrderRequest';
import ApiError from 'Entities/ApiError';
import AccountColorMarker from 'Common/AccountColorMarker.vue';

import OrderValueBlock from './OrderValueBlock.vue';

const OrderExpiriesList = Object.values(OrderExpiries);

export default {
    name: 'StopLimit',
    mixins: [orderValue, numberFormater],
    components: {
        AccountColorMarker,
        Icon,
        OrderValueBlock,
        AssetValue,
        Button,
        SwitchControl,
        Checkbox,
        MoneyInput,
    },
    props: {
        currentExpiry: {
            type: String,
            required: true,
        },
        isModal: {
            type: Boolean,
            default: false,
        },
        placement: {
            type: String,
            required: true,
        },
        pair: {
            type: String,
            required: true,
        },
        storeData: {
            type: undefined,
            required: true,
        },
        uniqueId: {
            type: String,
            required: true,
        },
        presetedQuantity: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            OrdersSides,
            priorityField: fieldPriorities.QUANTITY,
            showTooltip: true,
            theme,
            uniqueComponentId: '',
            multiaccountOrderFormData: {
                selectedAccountsIndexes: [],
                selectedAccountsSumTypes: [],
                selectedAccountsIsEditing: [],
                quantities: [],
                totals: [],
                prices: [],
                stopPrices: [],
                orderValues: [],
                fees: [],
                quotedFees: [],
                quantityValidationErrors: [],
                totalValidationErrors: [],
                totalValidationErrorTexts: [],
                quantityValidationErrorTexts: [],
            },
            quantityButtons: [
                {
                    title: 'Min',
                    callback: () => {
                        this.setQuantityMinSize();
                    },
                },
                {
                    title: '25%',
                    callback: () => {
                        this.autoSetQuantity(0.25);
                    },
                },
                {
                    title: '50%',
                    callback: () => {
                        this.autoSetQuantity(0.5);
                    },
                },
                {
                    title: '75%',
                    callback: () => {
                        this.autoSetQuantity(0.75);
                    },
                },
                {
                    title: 'Max',
                    callback: () => {
                        this.autoSetQuantity(1);
                    },
                },
            ],
            totalButtons: [
                {
                    title: 'Min',
                    callback: () => {
                        this.setTotalMinSize();
                    },
                },
                {
                    title: '25%',
                    callback: () => {
                        this.autoSetTotal(0.25);
                    },
                },
                {
                    title: '50%',
                    callback: () => {
                        this.autoSetTotal(0.5);
                    },
                },
                {
                    title: '75%',
                    callback: () => {
                        this.autoSetTotal(0.75);
                    },
                },
                {
                    title: 'Max',
                    callback: () => {
                        this.autoSetTotal(1);
                    },
                },
            ],
            priceButtons: [
                {
                    title: 'Bid',
                    callback: () => {
                        this.autoSetPrice('BID');
                    },
                },
                {
                    title: 'Market',
                    isLarge: true,
                    callback: () => {
                        this.autoSetPrice('MARKET');
                    },
                },
                {
                    title: 'Ask',
                    callback: () => {
                        this.autoSetPrice('ASK');
                    },
                },
            ],
            stopPriceButtons: [
                {
                    title: 'Bid',
                    callback: () => {
                        this.autoSetStopPrice('BID');
                    },
                },
                {
                    title: 'Market',
                    isLarge: true,
                    callback: () => {
                        this.autoSetStopPrice('MARKET');
                    },
                },
                {
                    title: 'Ask',
                    callback: () => {
                        this.autoSetStopPrice('ASK');
                    },
                },
            ],

            formData: {
                quantity: 0,
                triggerPrice: 0,
                price: 0,
                activeExpiryIndex: 0,
                total: 0,
            },

            disabledSubmitAction: false,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            activeAccountId: 'Accounts/activeAccountID',
            isThemeDark: 'isThemeDark',
        }),
        accountsOrder() {
            return [...(this.$store.state.Interface.config.ownedAccountsOrder || []), ...(this.$store.state.Interface.config.managedAccountsOrder || [])];
        },
        multiAccountsQuantityMaxValues() {
            return this.accountsList.map((a) => {
                const balance = this.$store.state.Balances.balances.find((b) => (
                    b.accountId === a.id
                    && b.placementName === this.currentPlacement?.name
                    && b.assetSymbol === this.baseAssetSymbol
                ));
                if (balance) {
                    return balance.free;
                }
                return 0;
            });
        },
        multiAccountsTotalMaxValues() {
            return this.accountsList.map((a) => {
                const balance = this.$store.state.Balances.balances.find((b) => (
                    b.accountId === a.id
                    && b.placementName === this.currentPlacement?.name
                    && b.assetSymbol === this.quoteAssetSymbol
                ));
                if (balance) {
                    return balance.free;
                }
                return 0;
            });
        },
        quantityErrorText() {
            if (!this.$v.quantity.maxValue) {
                return `Quantity value must be lower than ${this.quantityMaxValue.noExponents()}`;
            }
            return '';
        },
        totalErrorText() {
            if (!this.$v.total.minValue) {
                return `Total value must be greater than ${this.totalMinValue.noExponents()}`;
            }
            if (!this.$v.total.maxValue) {
                return `Total value must be lower than ${this.totalMaxValue.noExponents()}`;
            }
            return '';
        },
        priceErrorText() {
            if (!this.$v.price.minValue) {
                return `Price value must be greater than ${this.priceRestriction.minValue.noExponents()}`;
            }
            if (!this.$v.price.maxValue) {
                return `Price value must be lower than ${this.priceRestriction.maxValue.noExponents()}`;
            }
            return '';
        },
        stopPriceErrorText() {
            if (!this.$v.triggerPrice.minValue) {
                return `Stop price value must be greater than ${this.priceRestriction.minValue.noExponents()}`;
            }
            if (!this.$v.triggerPrice.maxValue) {
                return `Stop price value must be lower than ${this.priceRestriction.maxValue.noExponents()}`;
            }
            return '';
        },
        placementId() {
            return this.$store.getters['Placements/getPlacementIdByName'](this.placement);
        },
        activePlacement() {
            return this.$store.getters['Placements/getPlacementByName'](this.placement);
        },
        activeTerminalAssetPairPrecisionAmount() {
            return this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair)?.placementPrecisionQuantity ?? 8;
        },
        activeTerminalAssetPairPrecisionPrice() {
            return this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair)?.placementPrecisionPrice ?? 8;
        },
        assetPair() {
            return this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair);
        },
        assetPairId() {
            return this.assetPair.id;
        },
        baseAssetSymbol() {
            return this.pair.split('/')[0];
        },
        quoteAssetSymbol() {
            return this.pair.split('/')[1];
        },
        baseAsset() {
            return this.$store.getters['Assets/GET_ASSET_BY_SYMBOL'](this.baseAssetSymbol);
        },
        quoteAsset() {
            return this.$store.getters['Assets/GET_ASSET_BY_SYMBOL'](this.quoteAssetSymbol);
        },
        commissionAsset() {
            return this.storeData.commissionAsset;
        },
        activeSide() {
            return this.storeData.activeSide;
        },
        priceRestriction() {
            return this.storeData.priceRestriction;
        },

        quotationAssetPrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
        accountsList() {
            return this.$store.state.Accounts.accounts
                .filter((a) => a.id !== this.$store.getters['Accounts/activeAccountID'])
                .sort(({ id: a }, { id: b }) => {
                    return this.accountsOrder.indexOf(a) - this.accountsOrder.indexOf(b);
                })
                .sort(({ roleName: a }, { roleName: b }) => {
                    if (a === 'owner' && b !== 'owner') {
                        return -1;
                    }
                    if (a !== 'owner' && b === 'owner') {
                        return 1;
                    }
                    return 0;
                });
        },
        currentPlacement() {
            return this.$store.state.Placements.placements.find((p) => p.id === this.placementId);
        },
        newCommission() {
            if (this.currentPlacement) {
                if (this.expiries[this.activeExpiryIndex].value === 'GTC') {
                    return this.activeSide.value === 'SELL' ? this.total * Number(this.currentPlacement.commissionMaker) * 0.01 : this.quantity * Number(this.currentPlacement.commissionMaker) * 0.01;
                }
                return this.activeSide.value === 'SELL' ? this.total * Number(this.currentPlacement.commissionTaker) * 0.01 : this.quantity * Number(this.currentPlacement.commissionTaker) * 0.01;
            }
            return 0;
        },
        quotedCommission() {
            if (this.currentPlacement) {
                if (this.expiries[this.activeExpiryIndex].value === 'GTC') {
                    return this.orderValue * Number(this.currentPlacement.commissionMaker) * 0.01;
                }
                return this.orderValue * Number(this.currentPlacement.commissionTaker) * 0.01;
            }
            return 0;
        },

        bidPrice() {
            return this.storeData.bidPrice;
        },
        askPrice() {
            return this.storeData.askPrice;
        },
        lastMarketPrice() {
            return this.storeData.marketPrice;
        },
        expiries() {
            return OrderExpiriesList.filter((expiry) => expiry.orderTypes.some((orderType) => this.activePlacement.orderTypes
                .filter((placementOrderType) => OrdersTypes.STOP_LIMIT.typeNames.some((stopLimitTypeName) => stopLimitTypeName === placementOrderType))
                .some((placementOrderType) => placementOrderType === orderType)));
        },

        assetPairSymbol() {
            return this.assetPair?.symbol;
        },

        quantityRestriction() {
            return RestrictionsAdapter(GenerateRestrictions(this.orderRestrictions?.[OrderRestrictionFields.QUANTITY]));
        },
        totalRestriction() {
            return RestrictionsAdapter(GenerateRestrictions(this.orderRestrictions?.[OrderRestrictionFields.TOTAL]));
        },

        isQuantityPriority() {
            return this.priorityField === fieldPriorities.QUANTITY;
        },

        quantity: {
            get() {
                const result = this.truncateNumber(Number(this.isQuantityPriority ? this.formData.quantity : this.total / this.price) || 0, this.quantityPrecision);
                return result === Infinity ? 0 : result;
            },
            set(quantity) {
                this.formData.quantity = this.truncateNumber(Number(quantity) || 0, this.quantityPrecision);
                this.priorityField = fieldPriorities.QUANTITY;
            },
        },
        total: {
            get() {
                return this.truncateNumber(Number(this.isQuantityPriority ? this.quantity * this.price : this.formData.total) || 0, this.pricePrecision);
            },
            set(total) {
                this.formData.total = this.truncateNumber(Number(total) || 0, this.pricePrecision);
                this.priorityField = fieldPriorities.TOTAL;
            },
        },
        price: {
            get() {
                return this.formData.price;
            },
            set(value) {
                this.formData.price = value;
            },
        },
        triggerPrice: {
            get() {
                return this.formData.triggerPrice;
            },
            set(value) {
                this.formData.triggerPrice = (+value).floor(this.activeTerminalAssetPairPrecisionPrice);
            },
        },
        activeExpiryIndex: {
            get() {
                return this.formData.activeExpiryIndex;
            },
            set(index) {
                this.formData.activeExpiryIndex = index;
            },
        },

        activeExpiry() {
            return this.expiries[this.activeExpiryIndex];
        },

        quantityBalanceFree() {
            return (
                this.$store.getters['Balances/GET_ACTIVE_ACCOUNT_BALANCE_BY_PARAMS'](this.placementId, this.baseAssetSymbol)
                    ?.free || 0
            );
        },
        totalBalanceFree() {
            return (
                this.$store.getters['Balances/GET_ACTIVE_ACCOUNT_BALANCE_BY_PARAMS'](this.placementId, this.quoteAssetSymbol)
                    ?.free || 0
            );
        },
        quantityPrecision() {
            return Math.min(
                this.activeTerminalAssetPairPrecisionAmount,
                this.quantityRestriction.stepSize ? this.quantityRestriction.stepSize.getPrecision() : Infinity,
            );
        },
        pricePrecision() {
            return Math.min(
                this.activeTerminalAssetPairPrecisionPrice,
                this.priceRestriction.stepSize ? this.priceRestriction.stepSize.getPrecision() : Infinity,
            );
        },
        totalMinValue() {
            // TODO: костыль для маленьких ордеров на тестовом аккаунте для тестового окружения (убрать позже)
            if (this.currentUser && (this.currentUser.id === 'UZHMN9A2Q' || this.currentUser.id === 'UQELMU9GE')) {
                return 0;
            }
            const quoteAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.quoteAssetSymbol);
            if (quoteAsset) {
                const quoteAssetRate = (this.$store.state.Assets.quotations.get(quoteAsset.symbol)?.USD ?? 0);
                if (quoteAssetRate !== 0) {
                    return (25 / quoteAssetRate).floor(this.pricePrecision);
                }
                return 0;
            }
            return 0;
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        quantityMinValue() {
            // TODO: костыль для маленьких ордеров на тестовом аккаунте для тестового окружения (убрать позже)
            if (this.currentUser && (this.currentUser.id === 'UZHMN9A2Q' || this.currentUser.id === 'UQELMU9GE')) {
                return 0;
            }
            const baseAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.baseAssetSymbol);
            if (baseAsset) {
                const baseAssetRate = Number((this.$store.state.Assets.quotations.get(baseAsset.symbol)?.USD ?? 0));
                if (baseAssetRate !== 0) {
                    return (25 / baseAssetRate).floor(this.quantityPrecision);
                }
                return 0;
            }
            return 0;
        },
        quantityMaxValue() {
            return Math.min(
                this.activeSide.value === OrdersSides.SELL.value ? this.quantityBalanceFree : Infinity,
                this.quantityRestriction.maxValue,
            );
        },
        totalMaxValue() {
            return Math.min(
                this.activeSide.value === OrdersSides.BUY.value ? this.totalBalanceFree : Infinity,
                this.totalRestriction.maxValue,
            );
        },
        totalCustomValidationErrors() {
            return {
                maxValue:
                    this.totalBalanceFree < this.total
                        ? 'Not enough funds to place an order'
                        : `Total field must be below ${this.totalRestriction.maxValue.noExponents()}`,
            };
        },
        isQuantityButtonsDisabled() {
            if (this.activeSide.value === this.OrdersSides.BUY.value) {
                return this.totalBalanceFree === 0;
            }
            return this.quantityBalanceFree === 0;
        },
        isTotalButtonsDisabled() {
            if (this.activeSide.value === this.OrdersSides.BUY.value) {
                return this.totalBalanceFree === 0;
            }
            return this.quantityBalanceFree === 0;
        },
        disabledQuantityButtonsIndexes() {
            const result = [];
            const balance = this.activeSide.value === this.OrdersSides.BUY.value
                ? this.totalBalanceFree
                : this.quantityBalanceFree;
            const minValue = this.activeSide.value === this.OrdersSides.BUY.value ? this.totalMinValue : this.quantityMinValue;
            if (balance * 0.25 < minValue) {
                result.push(1);
            }
            if (balance * 0.5 < minValue) {
                result.push(2);
            }
            if (balance * 0.75 < minValue) {
                result.push(3);
            }
            if (balance < minValue) {
                result.push(4);
            }
            return result;
        },
        disabledTotalButtonsIndexes() {
            const result = [];
            const balance = this.activeSide.value === this.OrdersSides.BUY.value
                ? this.totalBalanceFree
                : this.quantityBalanceFree;
            const minValue = this.activeSide.value === this.OrdersSides.BUY.value ? this.totalMinValue : this.quantityMinValue;
            if (balance * 0.25 < minValue) {
                result.push(1);
            }
            if (balance * 0.5 < minValue) {
                result.push(2);
            }
            if (balance * 0.75 < minValue) {
                result.push(3);
            }
            if (balance < minValue) {
                result.push(4);
            }
            return result;
        },
        quantityBalance() {
            return this.$store.state.Balances.balances?.find((b) => b.assetSymbol === this.baseAssetSymbol && b.placementId === this.placementId);
        },
        totalBalance() {
            return this.$store.state.Balances.balances?.find((b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId === this.placementId);
        },
        selectedOrdersNumber() {
            return this.multiaccountOrderFormData.selectedAccountsIndexes.reduce((accum, current) => {
                if (current) {
                    return accum + 1;
                }
                return accum;
            }, 1);
        },
        firstManagedAccountIndex() {
            return this.accountsList.findIndex(({ roleName }) => roleName !== 'owner');
        },
        quantityType() {
            if (!this.$store.getters['Placements/activeTerminalPlacement']) {
                return 'base';
            }
            return this.$store.getters['Placements/activeTerminalPlacement'].orderQuantityTypes[`LIMIT_STOP_${this.currentExpiry}`][this.isBuy ? 'BUY' : 'SELL'];
        },
        isBuy() {
            return this.activeSide.value === OrdersSides.BUY.value;
        },
    },
    validations() {
        return {
            quantity: {
                required,
                maxValue: maxValue(this.quantityMaxValue),
            },
            total: {
                required,
                minValue: minValue(this.totalMinValue),
                maxValue: maxValue(this.totalMaxValue),
            },
            price: {
                required,
                maxValue: maxValue(this.priceRestriction.maxValue),
                minValue: minValue(this.priceRestriction.minValue),
            },
            triggerPrice: {
                required,
                maxValue: maxValue(this.priceRestriction.maxValue),
                minValue: minValue(this.priceRestriction.minValue),
            },
        };
    },
    methods: {
        getAccountColor(account) {
            if (!account.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return account.color;
        },
        validateMultiOrder() {
            this.multiaccountOrderFormData.totalValidationErrors = [];
            this.multiaccountOrderFormData.quantityValidationErrors = [];
            this.multiaccountOrderFormData.totalValidationErrorTexts = [];
            this.multiaccountOrderFormData.quantityValidationErrorTexts = [];

            if (this.activeSide.value === this.OrdersSides.BUY.value) {
                this.multiaccountOrderFormData.totals.forEach((t, i) => {
                    if (t < this.totalMinValue && this.multiaccountOrderFormData.selectedAccountsIndexes[i]) {
                        this.multiaccountOrderFormData.totalValidationErrors[i] = true;
                        this.multiaccountOrderFormData.totalValidationErrorTexts[i] = `Total value must be greater than ${this.totalMinValue}`;
                    }
                });
                if (this.multiaccountOrderFormData.totalValidationErrors.length > 0) {
                    return false;
                }
                this.multiaccountOrderFormData.totals.forEach((t, i) => {
                    if (t > this.multiAccountsTotalMaxValues[i] && this.multiaccountOrderFormData.selectedAccountsIndexes[i]) {
                        this.multiaccountOrderFormData.totalValidationErrors[i] = true;
                        this.multiaccountOrderFormData.totalValidationErrorTexts[i] = `Total value must be lower than ${this.multiAccountsTotalMaxValues[i]}`;
                    }
                });
                return this.multiaccountOrderFormData.totalValidationErrors.length === 0;
            }

            this.multiaccountOrderFormData.quantities.forEach((q, i) => {
                if (q < this.quantityMinValue && this.multiaccountOrderFormData.selectedAccountsIndexes[i]) {
                    this.multiaccountOrderFormData.quantityValidationErrors[i] = true;
                    this.multiaccountOrderFormData.quantityValidationErrorTexts[i] = `Quantity value must be greater than ${this.quantityMinValue}`;
                }
            });
            if (this.multiaccountOrderFormData.quantityValidationErrors.length > 0) {
                return false;
            }
            this.multiaccountOrderFormData.quantities.forEach((q, i) => {
                if (q > this.multiAccountsQuantityMaxValues[i] && this.multiaccountOrderFormData.selectedAccountsIndexes[i]) {
                    this.multiaccountOrderFormData.quantityValidationErrors[i] = true;
                    this.multiaccountOrderFormData.quantityValidationErrorTexts[i] = `Quantity value must be lower than ${this.multiAccountsQuantityMaxValues[i]}`;
                }
            });
            return this.multiaccountOrderFormData.quantityValidationErrors.length === 0;
        },
        toggleDemoAccountAlert() {
            if (this.$store.getters['Accounts/isActiveAccountDemo']) {
                this.$store.state.Accounts.showDemoAccountAlert = true;
                this.$store.commit('Accounts/SET_IS_DEMO_ACCOUNT_SHAKING');
            }
        },
        accountFreeBase(id) {
            const balance = this.$store.state.Balances.balances.find((b) => b.accountId === id && b.placementId === this.placementId && b.assetSymbol === this.baseAssetSymbol);
            if (balance) {
                return balance.free;
            }
            return 0;
        },
        accountFreeQuote(id) {
            const balance = this.$store.state.Balances.balances.find((b) => b.accountId === id && b.placementId === this.placementId && b.assetSymbol === this.quoteAssetSymbol);
            if (balance) {
                return balance.free;
            }
            return 0;
        },
        setMultiaccountQuantity(num, index) {
            this.multiaccountOrderFormData.quantities[index] = num.floor(this.quantityPrecision);
            this.multiaccountOrderFormData.totals[index] = (this.multiaccountOrderFormData.prices[index] * this.multiaccountOrderFormData.quantities[index]).floor(this.pricePrecision);
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        setMultiaccountPrice(num, index) {
            this.multiaccountOrderFormData.prices[index] = num.floor(this.pricePrecision);
            this.multiaccountOrderFormData.totals[index] = (this.multiaccountOrderFormData.quantities[index] * this.multiaccountOrderFormData.prices[index]).floor(this.pricePrecision);
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        setMultiaccountStopPrice(num, index) {
            this.multiaccountOrderFormData.stopPrices[index] = num.floor(this.pricePrecision);
        },
        setMultiaccountTotal(num, index) {
            this.multiaccountOrderFormData.totals[index] = num.floor(this.pricePrecision);
            if (this.multiaccountOrderFormData.prices[index] !== 0) {
                this.multiaccountOrderFormData.quantities[index] = (this.multiaccountOrderFormData.totals[index] / this.multiaccountOrderFormData.prices[index]).floor(this.quantityPrecision);
            } else {
                this.multiaccountOrderFormData.quantities[index] = 0;
            }
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        changeSwitchValue(index) {
            this.multiaccountOrderFormData.selectedAccountsSumTypes[index] = !this.multiaccountOrderFormData.selectedAccountsSumTypes[index];
            this.calculateAccountsFormData();
            this.$forceUpdate();
        },
        calculateAccountsFormData() {
            this.accountsList.forEach((account, index) => {
                if (!this.multiaccountOrderFormData.selectedAccountsIsEditing[index]) {
                    if (this.multiaccountOrderFormData.selectedAccountsSumTypes[index]) {
                        this.multiaccountOrderFormData.prices[index] = this.price;
                        this.multiaccountOrderFormData.quantities[index] = this.quantity;
                        this.multiaccountOrderFormData.totals[index] = this.total;
                        this.multiaccountOrderFormData.stopPrices[index] = this.triggerPrice;
                    } else {
                        let balance;
                        if (this.activeSide.value.toUpperCase() === 'BUY') {
                            balance = this.$store.state.Balances.balances.find((b) => b.placementId === this.placementId && b.accountId === account.id && b.assetSymbol === this.quoteAssetSymbol);
                        } else {
                            balance = this.$store.state.Balances.balances.find((b) => b.placementId === this.placementId && b.accountId === account.id && b.assetSymbol === this.baseAssetSymbol);
                        }
                        this.multiaccountOrderFormData.prices[index] = this.price;
                        this.multiaccountOrderFormData.stopPrices[index] = this.triggerPrice;
                        // calculate quantity and total
                        if (!balance) {
                            this.multiaccountOrderFormData.quantities[index] = 0;
                            this.multiaccountOrderFormData.totals[index] = 0;
                        } else if (this.activeSide.value.toUpperCase() === 'BUY') {
                            if (this.totalBalanceFree !== 0) {
                                this.multiaccountOrderFormData.totals[index] = ((this.total * balance.free) / this.totalBalanceFree).floor(this.pricePrecision);
                                if (this.multiaccountOrderFormData.totals[index] > balance.free) {
                                    this.multiaccountOrderFormData.totals[index] = balance.free;
                                }
                            } else {
                                this.multiaccountOrderFormData.totals[index] = 0;
                            }
                            if (this.price !== 0) {
                                this.multiaccountOrderFormData.quantities[index] = (this.multiaccountOrderFormData.totals[index] / this.price).floor(this.quantityPrecision);
                            } else {
                                this.multiaccountOrderFormData.quantities[index] = 0;
                            }
                        } else if (this.activeSide.value.toUpperCase() === 'SELL') {
                            if (this.quantityBalanceFree !== 0) {
                                this.multiaccountOrderFormData.quantities[index] = ((this.quantity * balance.free) / this.quantityBalanceFree).floor(this.quantityPrecision);
                                if (this.multiaccountOrderFormData.quantities[index] > balance.free) {
                                    this.multiaccountOrderFormData.quantities[index] = balance.free;
                                }
                            } else {
                                this.multiaccountOrderFormData.quantities[index] = 0;
                            }
                            this.multiaccountOrderFormData.totals[index] = (this.multiaccountOrderFormData.quantities[index] * this.price).floor(this.pricePrecision);
                        }
                    }
                    this.recalculateOrderDetails(index);
                }
            });
        },
        recalculateOrderDetails(index) {
            // calculate order value
            const quoteAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.quoteAssetSymbol);
            const quoteRate = quoteAsset && this.$store.state.Assets.quotations.has(quoteAsset.symbol)
                ? this.$store.state.Assets.quotations.get(quoteAsset.symbol)[this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL']]
                : 0;
            this.multiaccountOrderFormData.orderValues[index] = quoteRate * this.multiaccountOrderFormData.totals[index];
            // calculate fee
            if (this.currentPlacement) {
                if (this.currentExpiry === 'GTC') {
                    this.multiaccountOrderFormData.fees[index] = this.activeSide.value === 'SELL' ? this.multiaccountOrderFormData.totals[index] * Number(this.currentPlacement.commissionMaker) * 0.01 : this.multiaccountOrderFormData.quantities[index] * Number(this.currentPlacement.commissionMaker) * 0.01;
                } else {
                    this.multiaccountOrderFormData.fees[index] = this.activeSide.value === 'SELL' ? this.multiaccountOrderFormData.totals[index] * Number(this.currentPlacement.commissionTaker) * 0.01 : this.multiaccountOrderFormData.quantities[index] * Number(this.currentPlacement.commissionTaker) * 0.01;
                }
            } else {
                this.multiaccountOrderFormData.fees[index] = 0;
            }
            // calculate quoted fee
            if (this.currentPlacement) {
                if (this.currentExpiry === 'GTC') {
                    this.multiaccountOrderFormData.quotedFees[index] = this.multiaccountOrderFormData.orderValues[index] * Number(this.currentPlacement.commissionMaker) * 0.01;
                } else {
                    this.multiaccountOrderFormData.quotedFees[index] = this.multiaccountOrderFormData.orderValues[index] * Number(this.currentPlacement.commissionTaker) * 0.01;
                }
            } else {
                this.multiaccountOrderFormData.quotedFees[index] = 0;
            }
        },
        multiaccountQuantityMaxValue(index) {
            const balance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.baseAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const balanceFree = balance ? balance.free : 0;
            return Math.min(
                this.activeSide.value === OrdersSides.SELL.value ? balanceFree : Infinity,
                this.quantityRestriction.maxValue,
            );
        },
        multiaccountTotalMaxValue(index) {
            const balance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const balanceFree = balance ? balance.free : 0;
            return Math.min(
                this.activeSide.value === OrdersSides.BUY.value ? balanceFree : Infinity,
                this.totalRestriction.maxValue,
            );
        },
        makeMultiaccountQuantityButtons(index) {
            return [
                {
                    title: 'Min',
                    callback: () => this.autoSetMultiaccountQuantityMinValue(index),
                },
                {
                    title: '25%',
                    callback: () => this.autoSetMultiaccountQuantity(0.25, index),
                },
                {
                    title: '50%',
                    callback: () => this.autoSetMultiaccountQuantity(0.5, index),
                },
                {
                    title: '75%',
                    callback: () => this.autoSetMultiaccountQuantity(0.75, index),
                },
                {
                    title: 'Max',
                    callback: () => this.autoSetMultiaccountQuantity(1, index),
                },
            ];
        },
        makeMultiaccountTotalButtons(index) {
            return [
                {
                    title: 'Min',
                    callback: () => this.autoSetMultiaccountTotalMinValue(index),
                },
                {
                    title: '25%',
                    callback: () => this.autoSetMultiaccountTotal(0.25, index),
                },
                {
                    title: '50%',
                    callback: () => this.autoSetMultiaccountTotal(0.5, index),
                },
                {
                    title: '75%',
                    callback: () => this.autoSetMultiaccountTotal(0.75, index),
                },
                {
                    title: 'Max',
                    callback: () => this.autoSetMultiaccountTotal(1, index),
                },
            ];
        },
        makeMultiaccountPriceButtons(index) {
            return [
                {
                    title: 'Bid',
                    callback: () => {
                        this.autoSetMultiaccountPrice('BID', index);
                    },
                },
                {
                    title: 'Market',
                    isLarge: true,
                    callback: () => {
                        this.autoSetMultiaccountPrice('MARKET', index);
                    },
                },
                {
                    title: 'Ask',
                    callback: () => {
                        this.autoSetMultiaccountPrice('ASK', index);
                    },
                },
            ];
        },
        makeMultiaccountStopPriceButtons(index) {
            return [
                {
                    title: 'Bid',
                    callback: () => {
                        this.autoSetMultiaccountStopPrice('BID', index);
                    },
                },
                {
                    title: 'Market',
                    isLarge: true,
                    callback: () => {
                        this.autoSetMultiaccountStopPrice('MARKET', index);
                    },
                },
                {
                    title: 'Ask',
                    callback: () => {
                        this.autoSetMultiaccountStopPrice('ASK', index);
                    },
                },
            ];
        },
        autoSetMultiaccountPrice(type, index) {
            switch (type) {
                case 'BID':
                    this.multiaccountOrderFormData.prices[index] = this.bidPrice.ceil(this.pricePrecision);
                    break;
                case 'ASK':
                    this.multiaccountOrderFormData.prices[index] = this.askPrice.ceil(this.pricePrecision);
                    break;
                case 'MARKET':
                    this.multiaccountOrderFormData.prices[index] = this.lastMarketPrice.ceil(this.pricePrecision);
                    break;
                default:
                    throw 'Unsupported auto set price type';
            }
            this.multiaccountOrderFormData.totals[index] = (this.multiaccountOrderFormData.quantities[index] * this.multiaccountOrderFormData.prices[index]).floor(this.pricePrecision);
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        autoSetMultiaccountStopPrice(type, index) {
            switch (type) {
                case 'BID':
                    this.multiaccountOrderFormData.stopPrices[index] = this.bidPrice.ceil(this.pricePrecision);
                    break;
                case 'ASK':
                    this.multiaccountOrderFormData.stopPrices[index] = this.askPrice.ceil(this.pricePrecision);
                    break;
                case 'MARKET':
                    this.multiaccountOrderFormData.stopPrices[index] = this.lastMarketPrice.ceil(this.pricePrecision);
                    break;
                default:
                    throw 'Unsupported auto set price type';
            }
            this.multiaccountOrderFormData.totals[index] = (this.multiaccountOrderFormData.quantities[index] * this.multiaccountOrderFormData.prices[index]).floor(this.pricePrecision);
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        autoSetMultiaccountQuantityMinValue(index) {
            const baseAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.baseAssetSymbol);
            if (baseAsset) {
                const baseAssetRate = (this.$store.state.Assets.quotations.get(baseAsset.symbol)?.USD ?? 0);
                if (baseAssetRate !== 0) {
                    this.multiaccountOrderFormData.quantities[index] = (25 / baseAssetRate).floor(this.quantityPrecision);
                } else {
                    this.multiaccountOrderFormData.quantities[index] = 0;
                }
            } else {
                this.multiaccountOrderFormData.quantities[index] = 0;
            }
            this.multiaccountOrderFormData.totals[index] = (this.multiaccountOrderFormData.prices[index] * this.multiaccountOrderFormData.quantities[index]).floor(this.pricePrecision);
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        autoSetMultiaccountTotalMinValue(index) {
            const quoteAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.quoteAssetSymbol);
            if (quoteAsset) {
                const quoteAssetRate = (this.$store.state.Assets.quotations.get(quoteAsset.symbol)?.USD ?? 0);
                if (quoteAssetRate !== 0) {
                    this.multiaccountOrderFormData.totals[index] = (25 / quoteAssetRate).floor(this.pricePrecision);
                } else {
                    this.multiaccountOrderFormData.totals[index] = 0;
                }
            } else {
                this.multiaccountOrderFormData.totals[index] = 0;
            }
            if (this.multiaccountOrderFormData.prices[index] !== 0) {
                this.multiaccountOrderFormData.quantities[index] = (this.multiaccountOrderFormData.totals[index] / this.multiaccountOrderFormData.prices[index]).floor(this.quantityPrecision);
            } else {
                this.multiaccountOrderFormData.quantities[index] = 0;
            }
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        autoSetMultiaccountQuantity(multiplier, index) {
            const balance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.baseAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const balanceFree = balance ? balance.free : 0;
            this.multiaccountOrderFormData.quantities[index] = (balanceFree * multiplier).floor(this.quantityPrecision);
            this.multiaccountOrderFormData.totals[index] = (this.multiaccountOrderFormData.prices[index] * this.multiaccountOrderFormData.quantities[index]).floor(this.pricePrecision);
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        autoSetMultiaccountTotal(multiplier, index) {
            const balance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const balanceFree = balance ? balance.free : 0;
            this.multiaccountOrderFormData.totals[index] = (balanceFree * multiplier).floor(this.pricePrecision);
            if (this.multiaccountOrderFormData.prices[index] !== 0) {
                this.multiaccountOrderFormData.quantities[index] = (this.multiaccountOrderFormData.totals[index] / this.multiaccountOrderFormData.prices[index]).floor(this.quantityPrecision);
            } else {
                this.multiaccountOrderFormData.quantities[index] = 0;
            }
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        isMultiaccountQuantityButtonsDisabled(index) {
            const totalBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const quantityBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.baseAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const totalBalanceFree = totalBalance ? totalBalance.free : 0;
            const quantityBalanceFree = quantityBalance ? quantityBalance.free : 0;
            if (this.activeSide.value === this.OrdersSides.BUY.value) {
                return totalBalanceFree === 0;
            }
            return quantityBalanceFree === 0;
        },
        isMultiaccountTotalButtonsDisabled(index) {
            const totalBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const quantityBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.baseAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const totalBalanceFree = totalBalance ? totalBalance.free : 0;
            const quantityBalanceFree = quantityBalance ? quantityBalance.free : 0;
            if (this.activeSide.value === this.OrdersSides.BUY.value) {
                return totalBalanceFree === 0;
            }
            return quantityBalanceFree === 0;
        },
        getMultiaccountDisabledQuantityButtonsIndexes(index) {
            const totalBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const quantityBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.baseAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const totalBalanceFree = totalBalance ? totalBalance.free : 0;
            const quantityBalanceFree = quantityBalance ? quantityBalance.free : 0;
            const result = [];
            const balance = this.activeSide.value === this.OrdersSides.BUY.value ? totalBalanceFree : quantityBalanceFree;
            const minValue = this.activeSide.value === this.OrdersSides.BUY.value ? this.totalMinValue : this.quantityMinValue;
            if (balance * 0.25 < minValue) {
                result.push(1);
            }
            if (balance * 0.5 < minValue) {
                result.push(2);
            }
            if (balance * 0.75 < minValue) {
                result.push(3);
            }
            if (balance < minValue) {
                result.push(4);
            }
            return result;
        },
        getMultiaccountDisabledTotalButtonsIndexes(index) {
            const totalBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const quantityBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.baseAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const totalBalanceFree = totalBalance ? totalBalance.free : 0;
            const quantityBalanceFree = quantityBalance ? quantityBalance.free : 0;
            const result = [];
            const balance = this.activeSide.value === this.OrdersSides.BUY.value ? totalBalanceFree : quantityBalanceFree;
            const minValue = this.activeSide.value === this.OrdersSides.BUY.value ? this.totalMinValue : this.quantityMinValue;
            if (balance * 0.25 < minValue) {
                result.push(1);
            }
            if (balance * 0.5 < minValue) {
                result.push(2);
            }
            if (balance * 0.75 < minValue) {
                result.push(3);
            }
            if (balance < minValue) {
                result.push(4);
            }
            return result;
        },
        openMultiaccountOrderModal() {
            this.$store.commit(SET_UI({ quantity: this.quantity, price: this.price, total: this.total, stopPrice: this.triggerPrice }));
            this.$modal.show(this.uniqueId);
        },
        setTotal(num) {
            this.total = Number(num.toFixed(this.pricePrecision));
        },
        setPrice(num) {
            this.price = Number(num.toFixed(this.pricePrecision));
        },
        setQuantity(num) {
            this.quantity = Number(num.toFixed(this.quantityPrecision));
        },
        setTriggerPrice(num) {
            this.triggerPrice = num;
        },
        setQuantityMinSize() {
            this.total = this.totalMinValue;
            if (this.total === 0) {
                this.total = 1 / 10 ** this.pricePrecision;
            }
            if (this.quantity === 0) {
                this.quantity = 1 / 10 ** this.quantityPrecision;
            }
            this.$refs.TotalInput.cleanFocusButtons();
        },
        setTotalMinSize() {
            this.total = this.totalMinValue;
            if (this.total === 0) {
                this.total = 1 / 10 ** this.pricePrecision;
            }
            if (this.quantity === 0) {
                this.quantity = 1 / 10 ** this.quantityPrecision;
            }
            this.$refs.QuantityInput.cleanFocusButtons();
        },
        autoSetQuantity(ratio) {
            if (this.activeSide.value === this.OrdersSides.BUY.value) {
                this.total = Number(this.totalBalanceFree * ratio).floor(this.pricePrecision);
            } else {
                this.quantity = Number(ratio * this.quantityBalanceFree).floor(this.quantityPrecision);
            }
            this.$refs.TotalInput.cleanFocusButtons();
        },
        autoSetTotal(ratio) {
            if (this.activeSide.value === this.OrdersSides.BUY.value) {
                this.total = Number(this.totalBalanceFree * ratio).floor(this.pricePrecision);
            } else {
                this.quantity = Number(ratio * this.quantityBalanceFree).floor(this.quantityPrecision);
            }
            this.$refs.QuantityInput.cleanFocusButtons();
        },
        autoSetPrice(type) {
            switch (type) {
                case 'BID':
                    this.price = this.bidPrice?.ceil(this.pricePrecision) || 0;
                    break;
                case 'ASK':
                    this.price = this.askPrice?.ceil(this.pricePrecision) || 0;
                    break;
                case 'MARKET':
                    this.price = this.lastMarketPrice?.ceil(this.pricePrecision) || 0;
                    break;
                default:
                    throw new Error('Unsupported auto set price type');
            }
        },
        autoSetStopPrice(type) {
            switch (type) {
                case 'BID':
                    this.triggerPrice = this.bidPrice?.ceil(this.pricePrecision) || 0;
                    break;
                case 'ASK':
                    this.triggerPrice = this.askPrice?.ceil(this.pricePrecision) || 0;
                    break;
                case 'MARKET':
                    this.triggerPrice = this.lastMarketPrice?.ceil(this.pricePrecision) || 0;
                    break;
                default:
                    throw new Error('Unsupported auto set price type');
            }
        },

        async placeOrder() {
            setTimeout(() => {
                this.showTooltip = true;
            }, 100);

            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.disabledSubmitAction = true;
                this.$store.commit(SET_LOADING_ON(undefined));

                let requestData;
                if (this.quantityType === 'base') {
                    requestData = {
                        accountId: this.activeAccountId,
                        assetPairId: this.assetPairId,
                        marketPrice: Number(this.lastMarketPrice),
                        placementId: this.placementId,
                        side: this.activeSide.value,
                        quantity: Number(this.quantity),
                        price: Number(this.price),
                        timeInForce: this.currentExpiry,
                        triggerPrice: Number(this.triggerPrice),
                        type: 'LIMIT_STOP',
                    };
                } else {
                    requestData = {
                        accountId: this.activeAccountId,
                        assetPairId: this.assetPairId,
                        marketPrice: Number(this.lastMarketPrice),
                        placementId: this.placementId,
                        side: this.activeSide.value,
                        quoteQuantity: Number(this.total),
                        price: Number(this.price),
                        timeInForce: this.currentExpiry,
                        triggerPrice: Number(this.triggerPrice),
                        type: 'LIMIT_STOP',
                    };
                }
                try {
                    const { data: order } = await TradingApi.privateOrdersPlaceOrder(new PlaceOrderRequest(requestData));
                    await this.$store.dispatch('Orders/History/updateOrdersBySocket', order.serialize());
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during place order');
                    }
                } finally {
                    this.disabledSubmitAction = false;
                    this.clearForm();
                    this.$v.$reset();
                    this.$refs.TotalInput.cleanFocusButtons();
                    this.$refs.QuantityInput.cleanFocusButtons();
                    this.$refs.PriceInput.cleanFocusButtons();
                    this.$refs.StopPriceInput.cleanFocusButtons();
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            }
        },
        async performMultiaccountOrder() {
            setTimeout(() => {
                this.showTooltip = true;
            }, 100);

            this.$v.$touch();
            const isMultiOrderValid = this.validateMultiOrder();
            if (!this.$v.$invalid && isMultiOrderValid) {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    let requestData;
                    if (this.quantityType === 'base') {
                        requestData = {
                            accountId: this.activeAccountId,
                            assetPairId: this.assetPairId,
                            marketPrice: Number(this.lastMarketPrice),
                            placementId: this.placementId,
                            side: this.activeSide.value,
                            quantity: Number(this.quantity),
                            price: Number(this.price),
                            timeInForce: this.currentExpiry,
                            triggerPrice: Number(this.triggerPrice),
                            type: 'LIMIT_STOP',
                        };
                    } else {
                        requestData = {
                            accountId: this.activeAccountId,
                            assetPairId: this.assetPairId,
                            marketPrice: Number(this.lastMarketPrice),
                            placementId: this.placementId,
                            side: this.activeSide.value,
                            quoteQuantity: Number(this.total),
                            price: Number(this.price),
                            timeInForce: this.currentExpiry,
                            triggerPrice: Number(this.triggerPrice),
                            type: 'LIMIT_STOP',
                        };
                    }
                    try {
                        await TradingApi.privateOrdersPlaceOrder(new PlaceOrderRequest(requestData));
                    } catch (error) {
                        if (error instanceof ApiError) {
                            await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during place order');
                        }
                    }
                    this.accountsList.forEach((account, index) => {
                        if (this.multiaccountOrderFormData.selectedAccountsIndexes[index]) {
                            let extraRequestData;
                            if (this.quantityType === 'base') {
                                extraRequestData = {
                                    accountId: account.id,
                                    assetPairId: this.assetPairId,
                                    marketPrice: Number(this.lastMarketPrice),
                                    placementId: this.placementId,
                                    side: this.activeSide.value,
                                    quantity: Number(this.multiaccountOrderFormData.quantities[index]),
                                    price: Number(this.multiaccountOrderFormData.prices[index]),
                                    timeInForce: this.currentExpiry,
                                    triggerPrice: Number(this.multiaccountOrderFormData.stopPrices[index]),
                                    type: 'LIMIT_STOP',
                                };
                            } else {
                                extraRequestData = {
                                    accountId: account.id,
                                    assetPairId: this.assetPairId,
                                    marketPrice: Number(this.lastMarketPrice),
                                    placementId: this.placementId,
                                    side: this.activeSide.value,
                                    quoteQuantity: Number(this.multiaccountOrderFormData.totals[index]),
                                    price: Number(this.multiaccountOrderFormData.prices[index]),
                                    timeInForce: this.currentExpiry,
                                    triggerPrice: Number(this.multiaccountOrderFormData.stopPrices[index]),
                                    type: 'LIMIT_STOP',
                                };
                            }
                            TradingApi.privateOrdersPlaceOrder(new PlaceOrderRequest(extraRequestData))
                                .catch((error) => {
                                    if (error instanceof ApiError) {
                                        this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during place order');
                                    }
                                });
                        }
                    });
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                    this.$modal.hide(this.uniqueId);
                    this.$v.$reset();
                }
            }
        },
        clearForm() {
            this.quantity = 0;
            this.total = 0;
            this.triggerPrice = 0;
            this.price = 0;
        },
        handleAccountCheckbox(index) {
            const tempArray = [...this.multiaccountOrderFormData.selectedAccountsIndexes];
            tempArray[index] = !tempArray[index];
            this.multiaccountOrderFormData.selectedAccountsIndexes = tempArray;
            this.$forceUpdate();
        },
        handleEditCheckbox(index) {
            this.multiaccountOrderFormData.selectedAccountsIsEditing[index] = !this.multiaccountOrderFormData.selectedAccountsIsEditing[index];
            this.$forceUpdate();
        },
    },
    created() {
        document.addEventListener('click', () => {
            this.showTooltip = false;
        });

        this.accountsList.forEach((account, index) => {
            this.multiaccountOrderFormData.selectedAccountsSumTypes[index] = false;
            this.multiaccountOrderFormData.selectedAccountsIsEditing[index] = false;
        });
    },
    async mounted() {
        this.uniqueComponentId = nanoid(16);
        if (this.presetedQuantity) {
            this.quantity = this.presetedQuantity;
        }
        if (this.isModal) {
            const multiaccountOrderStateUi = this.$store.state.MultiaccountOrder.ui;
            this.price = multiaccountOrderStateUi.price;
            this.total = multiaccountOrderStateUi.total;
            this.quantity = multiaccountOrderStateUi.quantity;
            this.triggerPrice = multiaccountOrderStateUi.stopPrice;
            this.calculateAccountsFormData();
        }
    },
    watch: {
        baseAssetSymbol() {
            this.clearForm();
        },
        quoteAssetSymbol() {
            this.clearForm();
        },
        async placementId() {
            this.clearForm();
        },
        quantity() {
            if (this.price === 0) {
                this.price = this.lastMarketPrice.ceil(this.pricePrecision);
            }

            if (this.isModal) {
                this.calculateAccountsFormData();
            }
            this.$emit('set-quantity', this.quantity);
            this.$v.quantity.$reset();
        },
        price() {
            if (this.isModal) {
                this.calculateAccountsFormData();
            }
            this.$v.price.$reset();
        },
        triggerPrice() {
            this.$v.triggerPrice.$reset();
        },
        total() {
            if (this.price === 0) {
                this.price = this.lastMarketPrice.ceil(this.pricePrecision);
            }

            if (this.isModal) {
                this.calculateAccountsFormData();
            }
            this.$v.total.$reset();
        },
        async activeSide() {
            if (this.isModal) {
                this.calculateAccountsFormData();
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.mainContainer {
    height: calc(100% - 50px);
}
.row {
    display: flex;
    flex-direction: row;
}
.alignCenter {
    align-items: center;
}
.rowGap {
    row-gap: var(--m-xxl);
}
.firstColumnPadding {
    padding: 0 var(--m-xs) 0 var(--m-m);
}
.secondColumnPadding {
    padding: 0 var(--m-m) 0 var(--m-xs);
}
.paddingX {
    padding: 0 var(--m-m);
}
.borderRight {
    border-right: 1px solid var(--cl-gray-light);
}
.accountsListHeaderText {
    font-weight: var(--fw-extrabold);
    font-size: var(--fs-m);
    line-height: var(--fs-m);
    color: var(--cl-black);
    padding: 0 var(--m-m);
}
.prS {
    padding-right: var(--m-s);
}
.justifyContentBetween {
    justify-content: space-between;
}
.justifyContentEnd {
    justify-content: flex-end;
}
.w100 {
    width: 100%;
}
.h100 {
    height: 100%;
}
.pbM {
    padding-bottom: var(--m-m);
}
.w25 {
    width: 25%;
}
.w20 {
    width: 20%;
}
.w30 {
    width: 30%;
}
.w50 {
    width: 50%;
}
.w33 {
    width: 33%;
}
.w66 {
    width: 66%;
}
.column {
    display: flex;
    flex-direction: column;
}
.mainAccountsGrid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    width: 100%;
    grid-row-gap: var(--m-l);
}
.additionalGridCell {
    grid-column-start: 1;
    grid-column-end: 4;
    padding: var(--m-xs);
    border: 1px solid var(--cl-gray-light);
    border-radius: 4px;
}
.additionalGridCellBackground {
    background-color: var(--cl-violet-light);
}
.balancesGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.multiaccountButton {
    margin-right: var(--m-xs);
    padding: 0 var(--m-s);
}
.modalContainer {
    padding: 0 var(--m-xl);
    border-top: 1px solid var(--cl-gray-light);
    position: relative;
    left: -20px;
    width: calc(100% + 36px) !important;
}
.mtMedium {
    margin-top: var(--m-m);
}
.mrLarge {
    margin-right: var(--m-xl);
}
.mtExtraLarge {
    margin-top: var(--m-xxxl);
}
.buttonsContainer {
    padding: 0 var(--m-m);
    margin-bottom: var(--m-m);
}
.mlSmall {
    margin-left: var(--m-xs);
}
.mbSmall {
    margin-bottom: var(--m-xs);
}
.mrSmall {
    margin-right: var(--m-xs);
}
.accountsListContainer {
    padding: var(--m-l) var(--m-m);
    align-items: center;
}
.toggleBarsContainer {
    justify-content: flex-end;
    align-items: center;
}
.cancelButton {
    border: 1px solid var(--cl-violet);
    margin-right: var(--m-s);
    width: 25%;
}
.prXxxl {
    padding-right: var(--m-xxxl);
}
.editText {
    font-style: normal;
    font-weight: var(--fw-semibold);
    font-size: var(--fs-m);
    line-height: var(--fs-m);
    color: var(--cl-gray);
}
.tag {
    display: flex;
    align-items: center;
    background-color: var(--cl-gray-light);
    padding: var(--m-xs) var(--m-s);
    border-radius: 100px;
    column-gap: var(--m-s);
    width: max-content;
    cursor: pointer;
    font-size: var(--fs-s);
    font-weight: var(--fw-semibold);
}
.smallGap {
    column-gap: var(--m-s);
}
</style>

<style>
.stop-limit-form {
    & .vm--modal {
        border-radius: 15px !important;
        background: var(--cl-white) !important;
        max-width: 300px !important;
    }
}
</style>
