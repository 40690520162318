
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { required, minValue, maxValue } from 'vuelidate/dist/validators.min';

import SpotAssetPair from 'Lib/entities/publicPresenter/SpotAssetPair';
import MoneyInput from 'Common/controls/MoneyInput.vue';
import Balance from 'Lib/entities/privatePresenter/Balance';
import Asset from 'Lib/entities/publicPresenter/Asset';
import { subscribe, unsubscribeChannel, ISubscription } from 'Store/v2/PublicSocketData';
import SettingsApi from 'Apis/Settings';
import ApiError from 'Lib/entities/ApiError';
import FavoriteAssetPair from 'Lib/entities/userSettings/FavoriteAssetPair';
import { getFavoriteAssetPairsList } from 'Store/v2/AssetPairs';
import Icon from 'UI/Icon.vue';
import AttributesValues from 'Lib/entities/publicPresenter/AttributesValues';
import { OTC_PLACEMENT } from 'Const/otc';
import { Placement } from 'Models/placements';
import { assetsTypes } from 'Store/v2/Assets';
import OTCApi from 'Apis/OTC';
import PlaceOrderRequest from 'Lib/entities/omnibusSpotOrderRegistrator/PlaceOrderRequest';
import OrderRequestParams from 'Lib/entities/omnibusSpotOrderRegistrator/OrderRequestParams';
import TradingApi from 'Apis/Trading';
import SpotOrderRequest from 'Lib/entities/privatePresenter/SpotOrderRequest';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import { composedPath } from 'Lib/utils/eventPathChecker';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import numberFormater from 'Mixins/numberFormater';

import ConfirmModal from './ConfirmModal.vue';

type Mode = 'sell' | 'buy';

type MarketOrderType = {
    BUY: 'base' | 'quote';
    SELL: 'base' | 'quote';
}

const OrderExpiries = [{
    label: 'Request for quote',
    value: 'IOC',
    orderTypes: ['REQUEST_FOR_QUOTE'],
    isCancelable: true,
},
{
    label: 'Fill Or Kill',
    value: 'FOK',
    orderTypes: ['LIMIT_FOK'],
    isCancelable: false,
},
];

type Price = {
    buy: string,
    sell: string,
    ts: number,
};

type Data = {
    mode: Mode;
    value: number;
    fokValue: number;
    fokSlippage: number;
    price: Price;
    subscriptionIndex: ISubscription | null;
    currentExpiryLabel: string;
    dropdownActiveIndex: number;
    activeBlock: 'buy' | 'sell' | undefined;
    quantityButtons: { title: string; callback: () => void; }[];
}

type Methods = {
    onModeChange: () => void;
    setPrice: (price: Price) => void;
    setSubscriptionIndex: (index: ISubscription) => void;
    formDate: (ts: number) => void;
    toggleFavorite: () => void;
    removeFromFavorite: () => void;
    addToFavorite: () => void;
    subscribe: () => void;
    onOperation: () => void;
    setValue: (v: number) => void;
    setExpiry: (value: any) => void;
    setFokValue: (v: number) => void;
    setSlippage: (v: number) => void;
    setActiveBlock: (v: 'buy' | 'sell') => void;
    subscribeOnClearValidation: () => void;
    outsideClickListener: (data: any) => void;
    setQuantity: (data: 'min' | number) => void;
};

type Computed = {
    activeAccountId: string;
    balance: Balance | undefined;
    asset: Asset;
    isFavorite: boolean;
    publicSocket: any;
    minmax: AttributesValues | undefined;
    minmaxPrice: AttributesValues | undefined;
    isFoK: boolean;
    confirmModalId: string;
    confirmData: any;
    quoteAssetPrecision: number;
    baseAssetPrecision: number;
    getExpiries: any[];
    isDemoAccount: boolean;
    currentUser: InternalUserResponse | undefined;
    isKycVerified: boolean;
};

type Props = {
    assetPair: SpotAssetPair;
};

const addLeadingZero = (num: number) => {
    return num > 9 ? num : `0${num}`;
};

export default Vue.extend<Data, Methods, Computed, Props>({
    name: 'OrderCard',
    props: {
        assetPair: Object,
    },
    mixins: [numberFormater],
    components: { MoneyInput, Icon, ConfirmModal /* Dropdown */ },
    data() {
        return {
            mode: 'buy',
            value: 0,
            fokValue: 0,
            fokSlippage: 0,
            activeBlock: undefined,
            price: {
                buy: '',
                sell: '',
                ts: Date.now(),
            },
            subscriptionIndex: null,
            needConfirm: false,
            currentExpiryLabel: OrderExpiries[0].label,
            dropdownActiveIndex: 0,
            quantityButtons: [
                {
                    title: 'MIN',
                    callback: () => (this as any).setQuantity('min'),
                },
                {
                    title: '25%',
                    callback: () => (this as any).setQuantity(0.25),
                },
                {
                    title: '50%',
                    callback: () => (this as any).setQuantity(0.5),
                },
                {
                    title: '75%',
                    callback: () => (this as any).setQuantity(0.75),
                },
                {
                    title: 'MAX',
                    callback: () => (this as any).setQuantity(1),
                },
            ],
        };
    },
    validations() {
        return {
            value: {
                required,
                minValue: minValue(this.minmax?.min || 0),
                maxValue: maxValue(this.minmax?.max || Infinity),
            },
            fokValue: {
                required,
                minValue: minValue(this.minmaxPrice?.min || 0),
                maxValue: maxValue(this.minmaxPrice?.max || Infinity),
            },
            fokSlippage: {
                required,
                minValue: minValue(0),
                maxValue: maxValue(20),
            },
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
        }),
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isDemoAccount() {
            return this.activeAccountId === 'DEMOACC';
        },
        getExpiries() {
            return OrderExpiries;
        },
        isFoK() {
            return true;
        },
        asset() {
            return this.$store.state.OTC.activeAsset!;
        },
        balance() {
            const balances: Balance[] = this.$store.getters['Balances/GET_ACTIVE_ACCOUNT_BALANCES_BY_PLACEMENT_NAME'](OTC_PLACEMENT);
            if (this.mode === 'sell') {
                return balances.find(({ assetSymbol }) => assetSymbol === this.assetPair.baseAssetSymbol);
            }
            return balances.find(({ assetSymbol }) => assetSymbol === this.assetPair.quoteAssetSymbol);
        },
        isFavorite() {
            return !!this.$store.state.AssetPairs.favoriteTools
                .find(({ symbol, placementName }) => symbol === this.assetPair.symbol && placementName === OTC_PLACEMENT);
        },
        publicSocket() {
            return this.$store.state.PublicSocketData.centrifuge;
        },
        minmax() {
            const placement: Placement = this.$store.state.Placements.placements.find((p) => p.name === OTC_PLACEMENT);
            if (placement) {
                const { orderQuantityTypes: { LIMIT_FOK } } = placement;
                const { BUY, SELL } = LIMIT_FOK as unknown as MarketOrderType || {} as MarketOrderType;

                const assetType = this.mode === 'buy' ? BUY : SELL;

                const data = this.assetPair.placementAttributes?.limit?.[this.mode];
                return data?.[`${assetType}Asset`];
            }
            return undefined;
        },
        minmaxPrice() {
            const placement: Placement = this.$store.state.Placements.placements.find((p) => p.name === OTC_PLACEMENT);
            if (placement) {
                const data = this.assetPair.placementAttributes?.limit?.[this.mode];
                return data?.price;
            }
            return undefined;
        },
        confirmModalId() {
            return `confirm:OTC:${this.assetPair.symbol}`;
        },
        quoteAssetPrecision() {
            switch (this.asset?.type) {
                case assetsTypes.FIAT:
                case assetsTypes.STABLE_COIN:
                    return 2;
                case assetsTypes.CRYPTO_SPOT:
                    return 4;
                default:
                    return 4;
            }
        },
        baseAssetPrecision() {
            const baseAsset = this.$store.state.OTC.assets?.find((a) => a.symbol === this.assetPair.baseAssetSymbol);
            switch (baseAsset?.type) {
                case assetsTypes.FIAT:
                case assetsTypes.STABLE_COIN:
                    return 2;
                case assetsTypes.CRYPTO_SPOT:
                    return 4;
                default:
                    return 4;
            }
        },
        confirmData() {
            return {
                mode: this.mode,
                baseAsset: this.assetPair.baseAssetSymbol,
                quoteAsset: this.assetPair.quoteAssetSymbol,
                priceToFixed: this.quoteAssetPrecision,
                quantityToFixed: this.mode === 'sell' ? this.quoteAssetPrecision : this.baseAssetPrecision,
                symbol: this.assetPair.symbol,
                currency: this.mode === 'sell' ? this.assetPair.baseAssetSymbol : this.assetPair.quoteAssetSymbol,
                value: Number(this.value),
                price: Number(this.price[this.mode]),
                quantityPrecision: this.mode === 'sell' ? this.assetPair.placementPrecisionQuantity : this.assetPair.placementPrecisionPrice,
            };
        },
    },
    methods: {
        setQuantity(value) {
            if (this.fokValue === 0) {
                this.fokValue = Number(this.price[this.mode === 'sell' ? 'sell' : 'buy']);
            }
            if (value === 'min') {
                this.value = (this as any).truncateNumber(this.minmax?.min ?? 0, this.assetPair.placementPrecisionQuantity);
            } else {
                this.value = (this as any).truncateNumber(Math.min(
                    ((this.balance?.free ?? 0) * value) / (this.mode === 'sell' ? 1 : this.fokValue),
                    (this.minmax?.max ?? Infinity),
                ), this.assetPair.placementPrecisionQuantity);
            }
        },
        setActiveBlock(value) {
            if (value === 'buy') {
                if (this.activeBlock === 'buy') {
                    this.activeBlock = undefined;
                    this.fokValue = 0;
                    return;
                }
                this.activeBlock = 'buy';
                this.mode = 'buy';
                this.fokValue = Number(this.price.buy);
                return;
            }
            if (this.activeBlock === 'sell') {
                this.activeBlock = undefined;
                this.fokValue = 0;
                return;
            }
            this.activeBlock = 'sell';
            this.mode = 'sell';
            this.fokValue = Number(this.price.sell);
        },
        setValue(v) {
            this.value = (this as any).truncateNumber(Number(v), this.assetPair.placementPrecisionQuantity);
        },
        setFokValue(v) {
            this.fokValue = Number(v);
        },
        setSlippage(v) {
            this.fokSlippage = Number(v);
        },
        setExpiry({ item, index }) {
            this.currentExpiryLabel = item;
            this.dropdownActiveIndex = index;
        },
        onModeChange() {
            if (this.mode === 'sell') {
                this.mode = 'buy';
            } else {
                this.mode = 'sell';
            }
        },
        setPrice(price: Price) {
            this.price = price;
        },
        setSubscriptionIndex(index: ISubscription) {
            this.subscriptionIndex = index;
        },
        formDate(timestamp) {
            const date = new Date(timestamp);
            const hour = addLeadingZero(date.getHours());
            const minutes = addLeadingZero(date.getMinutes());
            const seconds = addLeadingZero(date.getSeconds());
            return `${hour}:${minutes}:${seconds}`;
        },
        async toggleFavorite() {
            if (this.isFavorite) {
                await this.removeFromFavorite();
                return;
            }
            await this.addToFavorite();
        },
        async addToFavorite() {
            try {
                await SettingsApi.addFavorite(new FavoriteAssetPair({
                    placementName: OTC_PLACEMENT,
                    symbol: this.assetPair.symbol,
                }));
                await this.$store.dispatch(getFavoriteAssetPairsList(undefined));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during adding tool to favorites');
                }
            }
        },
        async removeFromFavorite() {
            try {
                await SettingsApi.deleteFavorite(new FavoriteAssetPair({
                    placementName: OTC_PLACEMENT,
                    symbol: this.assetPair.symbol,
                }));
                await this.$store.dispatch(getFavoriteAssetPairsList(undefined));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during removing tool from favorites');
                }
            }
        },
        async subscribe() {
            const index = await this.$store.dispatch(subscribe({
                channel: `prices:OTC1-${this.assetPair.baseAssetSymbol}/${this.assetPair.quoteAssetSymbol}`,
                callbacks: {
                    subscribe: () => {
                        //
                    },
                    publish: ({ data }) => {
                        this.setPrice(data);
                    },
                },
            }));
            this.setSubscriptionIndex(index);
        },
        async onOperation() {
            if (this.isDemoAccount || !this.isKycVerified) {
                return;
            }

            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                // Will never work until rfq return to otc
                if (!this.isFoK) {
                    if ((this.balance?.free || 0) < this.value) {
                        return;
                    }
                    this.$modal.show(this.confirmModalId);
                    return;
                }
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    const { data: { orderId } } = await OTCApi.place(new PlaceOrderRequest({
                        accountId: this.activeAccountId,
                        orderRequestParams: new OrderRequestParams({
                            baseQuantity: this.value.toFixed(this.assetPair.placementPrecisionQuantity),
                            instrumentSymbol: this.assetPair.symbol,
                            price: this.fokValue.toFixed(this.assetPair.placementPrecisionPrice),
                            side: this.mode,
                            slippage: String(this.fokSlippage),
                            timeInForce: 'fok',
                        }),
                        placementName: OTC_PLACEMENT,
                        type: 'limit',
                    }));
                    const { data: order } = await TradingApi.privateSpotGetOrder(new SpotOrderRequest({ id: orderId! }));
                    await this.$store.dispatch('Orders/History/updateOrdersBySocket', order.serialize());
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        outsideClickListener(e) {
            const path = composedPath(e.target);
            if (!path.some((node) => (
                node.id
                && (
                    node.id === `otcPrice${this.assetPair.symbol}`
                    || node.id === `otcQuantity${this.assetPair.symbol}`
                    || node.id === `otcSell${this.assetPair.symbol}`
                    || node.id === `otcBuy${this.assetPair.symbol}`
                )))) {
                this.$v.$reset();
            }
        },
        subscribeOnClearValidation() {
            document.addEventListener('click', this.outsideClickListener);
        },
    },
    mounted() {
        if (this.publicSocket) {
            this.subscribe();
        }
        if (this.assetPair.symbol) {
            document.removeEventListener('click', this.subscribeOnClearValidation);
            this.subscribeOnClearValidation();
        }
    },
    async beforeDestroy() {
        if (this.subscriptionIndex !== null) {
            await this.$store.dispatch(unsubscribeChannel(this.subscriptionIndex));
            this.subscriptionIndex = null;
        }
        document.removeEventListener('click', this.subscribeOnClearValidation);
    },
    watch: {
        publicSocket(value) {
            if (value) {
                this.subscribe();
            }
        },
        assetPair(value) {
            if (value.symbol) {
                this.subscribeOnClearValidation();
            }
        },
    },
});

