
import Vue from 'vue';

import { timeConstants } from 'Config/timeConstants';
import PortfolioApi from 'Apis/Portfolio';
import ChartPayload from 'Lib/entities/portfolioBalanceHistory/ChartPayload';
import { generateStatistic } from 'Lib/models/portfolio';

interface Data {
    balancesStatisticsSource: any[],
}

export default Vue.extend<Data, any, any>({
    data() {
        return {
            balancesStatisticsSource: [],
            isLoading: false,
        };
    },
    methods: {
        generateLabels(fromTimestamp, toTimestamp, { withNow, hasAdditionalLabel } = {} as { withNow?: boolean, hasAdditionalLabel?: any }) {
            const fromDate = new Date((new Date(fromTimestamp)).setHours(0, 0, 0, 0));
            const UTCFromDate = new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()));

            let currentLabelTimestamp = UTCFromDate.getTime();

            const labels: number[] = [];

            while (currentLabelTimestamp < toTimestamp) {
                labels.push(currentLabelTimestamp);
                currentLabelTimestamp += timeConstants.DAY;
            }

            if (withNow) {
                labels.push(Date.now());
            }

            if (hasAdditionalLabel) {
                labels.push(currentLabelTimestamp);
            }

            return labels;
        },
        async getData(range, module) {
            const balances = this.$store.getters['Balances/GET_AGGREGATED_BALANCES'];
            const { start: from, end: to } = range;

            const { policies } = this.$store.getters['Accounts/activeAccount'];
            if (policies.includes('portfolio')) {
                try {
                    this.isLoading = true;
                    const { data } = await PortfolioApi.getChartPoints(new ChartPayload({
                        UTCFromTs: from.valueOf(),
                        UTCToTs: to.valueOf(),
                        ...(module
                            ? { accountId: this.$store.getters['Accounts/activeAccountID'] }
                            : this.$store.getters['Portfolio/requestAccountsData']
                        ),
                        assetSymbols: balances.map(({ assetSymbol }) => assetSymbol),
                        quotationAssetSymbol: this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL'],
                        timeGroup: 'Day',
                    }));
                    const assetSymbolsBalances = Object.fromEntries(balances.map((balance) => [balance.assetSymbol, balance]));
                    if (typeof data !== 'number') {
                        this.balancesStatisticsSource = [];
                        const placementsStatistics = data;
                        Object
                            .keys(placementsStatistics)
                            .forEach((assetSymbol) => {
                                const stats = generateStatistic(placementsStatistics[assetSymbol], assetSymbolsBalances[assetSymbol], this.labels);
                                if (stats) {
                                    this.balancesStatisticsSource.push(stats);
                                }
                            });
                    }
                } catch {
                    // api error
                } finally {
                    this.isLoading = false;
                }
            }
        },
    },
});
