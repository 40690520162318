<template>
    <tr
        :class="[s.pointer, s.container, { [s.notAllowed]: !isCurrentPlacementAvailable, [s.selected]: isPlacementActive, }]"
        @click="setActiveTerminalPlacement"
    >
        <td :class="[theme.table.alignLeft, { [s.disabled]: !isCurrentPlacementAvailable }]">
            <p :class="s.row">
                <PlacementIcon
                    :placement="placementName"
                    :class="s.iconMargin"
                />
                <span :class="[theme.table.placementContent, { [theme.table.active]: isPlacementActive }]">
                    {{ placement.id === $store.getters['Placements/platformPlacementId'] ? $store.getters['Placements/platformPlacementName'] : placementName }}
                    <span :class="s.placementLabel">
                        {{ isCurrentPlacementFutures ? 'FUTURES' : 'SPOT' }}
                    </span>
                    <Icon
                        v-if="isPlacementActive"
                        icon="check"
                        small
                        :class="s.violet"
                    />
                </span>
            </p>
        </td>
        <td :class="theme.table.alignLeft">
            <div
                v-if="!isCurrentPlacementAvailable"
                :class="[s.badge, { [s.light]: !isThemeDark }]"
            >
                <Icon
                    icon="wrench"
                    :class="[s.clock, { [s.light]: !isThemeDark }]"
                />
                <span :class="[s.maintenanceText, { [s.light]: !isThemeDark }]">
                    Maintenance
                </span>
            </div>
            <div
                v-else-if="isCurrentPlacementPending && activeAccountId !== 'DEMOACC'"
                :class="s.badge"
            >
                <Icon icon="clock" :class="s.linkIcon" />
                <span :class="s.maintenanceText">
                    Pending
                </span>
            </div>
            <div
                v-else-if="!isCurrentPlacementLinked && activeAccountId !== 'DEMOACC'"
                :class="s.violetBadge"
                @click.stop="linkPlacement"
            >
                <Icon icon="link" :class="s.linkIcon" />
                <span :class="s.maintenanceText">
                    Connect
                </span>
            </div>
            <div
                v-else-if="isCurrentPlacementPending && activeAccountId !== 'DEMOACC'"
                :class="[s.row, s.pointer]"
            >
                <Icon icon="clock" :class="s.linkIcon" />
                <span :class="s.link">
                    Pending
                </span>
            </div>
        </td>
        <td
            :class="[theme.table.alignCenter, theme.table.simpleContent, { [s.disabled]: !isCurrentPlacementAvailable }]"
        >
            <AssetValue is-aligned-center :value="toFixedFormat(Number(placement.volume24h), 2)" :asset="quotationAssetSymbol" :need-to-round="false" />
        </td>
        <td
            :class="[theme.table.alignRight, theme.table.simpleContent, { [s.disabled]: !isCurrentPlacementAvailable }]"
        >
            {{ placement.coinsNumber }} / {{ placement.pairsNumber }}
        </td>
    </tr>
</template>

<script>
import { mapGetters } from 'vuex';

import numberFormater from 'Mixins/numberFormater';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import { updateSpotAssetPairsList } from 'Store/v2/AssetPairs';
import AssetValue from 'UI/AssetValue.vue';
import theme from 'Theme';
import Icon from 'UI/Icon.vue';
import { SET_PLACEMENT_NAME } from 'Store/v2/LinkPlacement';

const DevPlacementsName = ['HITBTC', 'KUCOIN', 'BITTREX'];

export default {
    name: 'ChoosePlacement.PlacementRow',
    mixins: [
        numberFormater,
    ],
    components: {
        AssetValue,
        Icon,
    },
    props: {
        placement: {
            type: Object,
            require: true,
        },
    },
    data() {
        return {
            theme,
        };
    },
    computed: {
        ...mapGetters({
            activeTerminalPlacementId: 'Placements/activeTerminalPlacementId',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            activeAccountId: 'Accounts/activeAccountID',
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),

        placementId() {
            return this.placement.id;
        },
        placementName() {
            return this.placement.name;
        },

        isPlacementActive() {
            return this.activeTerminalPlacementId === this.placementId;
        },

        placementCompleteLabelName() {
            return DevPlacementsName.some((devPlacementName) => devPlacementName === this.placementName.toUpperCase()) ? 'dev' : 'release';
        },
        volume24hPrecision() {
            const quotationAsset = this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL'];
            return calculatePrecision(quotationAsset);
        },
        isCurrentPlacementAvailable() {
            return this.$store.state.Placements.maintenanceStatuses.get(this.placement.name);
        },
        isCurrentPlacementLinked() {
            return this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](this.placement.name);
        },
        isCurrentPlacementPending() {
            return this.$store.getters['Accounts/isPlacementPending'](this.placement.name);
        },
        isCurrentPlacementFutures() {
            return this.placement.name.toLowerCase().indexOf('futures') !== -1;
        },
    },
    methods: {
        async setActiveTerminalPlacement() {
            if (!this.isPlacementActive && this.isCurrentPlacementAvailable) {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    await this.$store.dispatch('Placements/setActiveTerminalPlacementId', this.placementId);
                    await this.$store.dispatch(updateSpotAssetPairsList({ placementId: this.placementId, isFirstDownload: false }));
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            }
        },
        async linkPlacement() {
            if (this.placement.needExternalKyc) {
                this.$store.commit(SET_PLACEMENT_NAME(this.placement.name));
                this.$modal.show('confirmLinkModal');
            } else {
                await this.$store.dispatch('Accounts/linkPlacement', this.placement.name);
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    & .violetBadge {
        visibility: hidden;
    }
    &:hover {
        outline: 1px solid var(--cl-violet);
        border-radius: 4px;
        & .violetBadge {
            visibility: visible;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: var(--m-xs);
            background-color: var(--cl-violet);
            cursor: pointer;
            padding: var(--m-xs) 0;
        }
    }
}
.row {
    display: flex;
    align-items: center;
}
.iconMargin {
    margin-right: var(--m-s);
}
.pointer {
    cursor: pointer;
}
.selected {
    outline: 1px solid var(--cl-violet);
    background: linear-gradient(to right, var(--cl-white), var(--cl-violet-medium-light)) fixed;
    border-radius: 4px;
}
.disabled {
    opacity: .4;
}
.notAllowed {
    cursor: not-allowed;
}
.violet {
    color: var(--cl-violet);
}
.link {
    font-size: var(--fs-m);
    line-height: var(--fs-m);
    font-weight: var(--fw-semibold);
    color: var(--cl-violet);
    text-decoration: dashed underline;
    opacity: 1;
}
.maintenanceText {
    font-size: var(--fs-s);
    font-weight: var(--fw-bold);
    color: #fff;
    text-transform: uppercase;

    &.light {
        color: var(--cl-gray);
    }
}
.clock {
    color: #fff;
    width: 20px;
    height: 20px;
    margin-left: -4px;

    &.light {
        color: var(--cl-gray);
    }
}
.linkIcon {
    color: #fff;
    width: 20px;
    height: 20px;
    margin-left: -4px;
}
.placementLabel {
    font-size: var(--fs-xs);
    font-weight: var(--fw-bold);
    color: var(--cl-violet);
}
.badge {
    padding: 0 var(--m-s);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: var(--m-xs);
    background-color: var(--cl-gray);

    &.light {
        background-color: var(--main-bg-color);
    }
}
.violetBadge {
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: var(--m-xs);
    background-color: var(--cl-violet);
    cursor: pointer;
    padding: var(--m-xs) 0;
}
</style>
