// This file was autogenerated. Please do not change.
export interface ITotalBalance {
    accountId: string;
    assetId: number;
    assetSymbol?: string;
    availableQuantity?: string;
    equity?: string;
    imRate?: string;
    initialMarginQuantity?: string;
    maintenanceMarginQuantity?: string;
    marginQuantity?: string;
    mmRate?: string;
    placementGroupTag?: string;
    placementTags?: string[];
    quantity?: string;
}

export default class TotalBalance {
    readonly _accountId: string;

    /**
     * Example: A5Q98WZ7PDX
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _assetId: number;

    /**
     * Example: 17
     */
    get assetId(): number {
        return this._assetId;
    }

    readonly _assetSymbol: string | undefined;

    get assetSymbol(): string | undefined {
        return this._assetSymbol;
    }

    readonly _availableQuantity: string | undefined;

    get availableQuantity(): string | undefined {
        return this._availableQuantity;
    }

    readonly _equity: string | undefined;

    get equity(): string | undefined {
        return this._equity;
    }

    readonly _imRate: string | undefined;

    get imRate(): string | undefined {
        return this._imRate;
    }

    readonly _initialMarginQuantity: string | undefined;

    get initialMarginQuantity(): string | undefined {
        return this._initialMarginQuantity;
    }

    readonly _maintenanceMarginQuantity: string | undefined;

    get maintenanceMarginQuantity(): string | undefined {
        return this._maintenanceMarginQuantity;
    }

    readonly _marginQuantity: string | undefined;

    get marginQuantity(): string | undefined {
        return this._marginQuantity;
    }

    readonly _mmRate: string | undefined;

    get mmRate(): string | undefined {
        return this._mmRate;
    }

    readonly _placementGroupTag: string | undefined;

    get placementGroupTag(): string | undefined {
        return this._placementGroupTag;
    }

    readonly _placementTags: string[] | undefined;

    get placementTags(): string[] | undefined {
        return this._placementTags;
    }

    readonly _quantity: string | undefined;

    get quantity(): string | undefined {
        return this._quantity;
    }

    constructor(props: ITotalBalance) {
        this._accountId = props.accountId.trim();
        this._assetId = props.assetId;
        if (typeof props.assetSymbol === 'string') {
            this._assetSymbol = props.assetSymbol.trim();
        }
        if (typeof props.availableQuantity === 'string') {
            this._availableQuantity = props.availableQuantity.trim();
        }
        if (typeof props.equity === 'string') {
            this._equity = props.equity.trim();
        }
        if (typeof props.imRate === 'string') {
            this._imRate = props.imRate.trim();
        }
        if (typeof props.initialMarginQuantity === 'string') {
            this._initialMarginQuantity = props.initialMarginQuantity.trim();
        }
        if (typeof props.maintenanceMarginQuantity === 'string') {
            this._maintenanceMarginQuantity = props.maintenanceMarginQuantity.trim();
        }
        if (typeof props.marginQuantity === 'string') {
            this._marginQuantity = props.marginQuantity.trim();
        }
        if (typeof props.mmRate === 'string') {
            this._mmRate = props.mmRate.trim();
        }
        if (typeof props.placementGroupTag === 'string') {
            this._placementGroupTag = props.placementGroupTag.trim();
        }
        if (props.placementTags) {
            this._placementTags = props.placementTags;
        }
        if (typeof props.quantity === 'string') {
            this._quantity = props.quantity.trim();
        }
    }

    serialize(): ITotalBalance {
        const data: ITotalBalance = {
            accountId: this._accountId,
            assetId: this._assetId,
        };
        if (typeof this._assetSymbol !== 'undefined') {
            data.assetSymbol = this._assetSymbol;
        }
        if (typeof this._availableQuantity !== 'undefined') {
            data.availableQuantity = this._availableQuantity;
        }
        if (typeof this._equity !== 'undefined') {
            data.equity = this._equity;
        }
        if (typeof this._imRate !== 'undefined') {
            data.imRate = this._imRate;
        }
        if (typeof this._initialMarginQuantity !== 'undefined') {
            data.initialMarginQuantity = this._initialMarginQuantity;
        }
        if (typeof this._maintenanceMarginQuantity !== 'undefined') {
            data.maintenanceMarginQuantity = this._maintenanceMarginQuantity;
        }
        if (typeof this._marginQuantity !== 'undefined') {
            data.marginQuantity = this._marginQuantity;
        }
        if (typeof this._mmRate !== 'undefined') {
            data.mmRate = this._mmRate;
        }
        if (typeof this._placementGroupTag !== 'undefined') {
            data.placementGroupTag = this._placementGroupTag;
        }
        if (typeof this._placementTags !== 'undefined') {
            data.placementTags = this._placementTags;
        }
        if (typeof this._quantity !== 'undefined') {
            data.quantity = this._quantity;
        }
        return data;
    }

    toJSON(): ITotalBalance {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            assetId: typeof this._assetId === 'number',
            assetSymbol: !this._assetSymbol ? true : typeof this._assetSymbol === 'string',
            availableQuantity: !this._availableQuantity ? true : typeof this._availableQuantity === 'string',
            equity: !this._equity ? true : typeof this._equity === 'string',
            imRate: !this._imRate ? true : typeof this._imRate === 'string',
            initialMarginQuantity: !this._initialMarginQuantity ? true : typeof this._initialMarginQuantity === 'string',
            maintenanceMarginQuantity: !this._maintenanceMarginQuantity ? true : typeof this._maintenanceMarginQuantity === 'string',
            marginQuantity: !this._marginQuantity ? true : typeof this._marginQuantity === 'string',
            mmRate: !this._mmRate ? true : typeof this._mmRate === 'string',
            placementGroupTag: !this._placementGroupTag ? true : typeof this._placementGroupTag === 'string',
            placementTags: !this._placementTags ? true : this._placementTags.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            quantity: !this._quantity ? true : typeof this._quantity === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
